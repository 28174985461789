const parse = (search: string): { [id: string]: string } => {
  const result = {};

  if (search == null || search === '?') {
    return result;
  }

  const trimQuestionMark = search.startsWith('?') ? search.substr(1) : search;
  const splitted = trimQuestionMark.split('&');

  if (splitted.length === 0) {
    return result;
  }

  splitted.forEach((pair) => {
    const pairSplitted = pair.split('=');

    result[pairSplitted[0]] = decodeURIComponent(pairSplitted[1]);
  });

  return result;
};

export default {
  parse,
};
