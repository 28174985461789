import React, { Component } from 'react';
import './OwnedInventories.scss';
import { connect } from 'react-redux';
import { Container, Row, Col, Alert } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { ReduxState } from '../../reducers/types';
import chainsureActions from '../../actions/chainsure.action';
import OwnedChainsureListItem from './OwnedChainsure/ListItem/OwnedChainsureListItem';
import OwnedChainsureDetailModal from './OwnedChainsure/DetailModal/OwnedChainsureDetailModal';
import ChainsureListItemLoading from '../Inventories/Chainsure/ListItem/ChainsureListItemLoading';

const mapStateToProps = ({ owned: { isLoading, chainsures, chainsureId, canLoadMore } }: ReduxState) => ({
  isLoading,
  chainsures,
  chainsureId,
  canLoadMore,
});
type Props = ReturnType<typeof mapStateToProps> & {
  getOwned: typeof chainsureActions.getOwned;
  selectOwned: typeof chainsureActions.selectOwned;
} & RouteComponentProps<{
    chainsureId: string;
  }> &
  WithNamespaces;

class OwnedInventories extends Component<Props> {
  componentDidMount() {
    const { isLoading, chainsures, getOwned, match, selectOwned } = this.props;

    if (!isLoading && chainsures == null) {
      getOwned();
    }

    const { chainsureId } = match.params;

    if (chainsureId) {
      selectOwned(chainsureId);
    }

    window.addEventListener('scroll', this.onScroll);
  }

  componentDidUpdate(prevProps: Props) {
    const { match, chainsureId, selectOwned, getOwned, lng, isLoading, chainsures } = this.props;

    if (match.params.chainsureId !== chainsureId) {
      selectOwned(match.params.chainsureId);
    }

    if (lng !== prevProps.lng) {
      // refresh due to language changes
      getOwned(true);

      return;
    }

    if (!isLoading && !chainsures && prevProps.chainsures) {
      // refresh
      getOwned(true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    if (window.innerHeight + window.pageYOffset + 100 >= (document.body.children[1] as HTMLElement).offsetHeight) {
      this.loadMore();
    }
  };

  loadMore = () => {
    const { getOwned, isLoading, chainsures, canLoadMore } = this.props;

    if (isLoading || !canLoadMore) return;

    getOwned(false, chainsures && chainsures.length > 0 ? chainsures[chainsures.length - 1] : null);
  };

  closeChainsureModal = () => {
    const { history } = this.props;

    history.push('/joined');
  };

  renderList = () => {
    const { chainsures, isLoading, t } = this.props;

    if (!isLoading && chainsures && chainsures.length === 0) {
      return (
        <Alert isOpen color="primary" fade={false}>
          {t('OWNED_EMPTY')}
        </Alert>
      );
    }

    return (
      <Row>
        {chainsures &&
          chainsures.map((c) => (
            <Col key={c.id} md={6} lg={4} sm={6}>
              <OwnedChainsureListItem chainsure={c} />
            </Col>
          ))}

        {isLoading &&
          Array.from(
            {
              length: 3,
            },
            (_, i) => (
              <Col key={`loading_${i}`} md={6} lg={4} sm={6}>
                <ChainsureListItemLoading className="owned-item" />
              </Col>
            )
          )}
      </Row>
    );
  };

  render() {
    const { location } = this.props;

    return (
      <Container className="container-sm-fluid">
        <OwnedChainsureDetailModal onClose={this.closeChainsureModal} location={location} />
        {this.renderList()}
      </Container>
    );
  }
}

export default withNamespaces()(
  connect(mapStateToProps, {
    getOwned: chainsureActions.getOwned,
    selectOwned: chainsureActions.selectOwned,
  })(OwnedInventories)
);
