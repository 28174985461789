import localforage from 'localforage';

function get<T>(key: string): Promise<T> {
  return localforage.getItem<T>(`draft_${key}`);
}

function set<T>(key: string, draft: T) {
  return localforage.setItem(`draft_${key}`, draft);
}

function remove(key: string) {
  return localforage.removeItem(`draft_${key}`);
}

export default {
  get,
  set,
  remove,
};
