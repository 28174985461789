import React, { PureComponent } from 'react';
import './Quoteditable.scss';
import { Button } from 'reactstrap';
import FontAwesomeIcon from '../Icon/FontAwesomeIcon';
import TextField from '../TextField/TextField';
import draftService from '../../utils/draftService';

type Props = {
  className?: string;
  disabled?: boolean;
  draftKey?: string | null;
  saveRequired?: boolean;
  content: string;
  label?: string;
  onChange?: (content: string) => any;
};

type State = {
  isEditing: boolean;
  isSaving: boolean;
  editContent: string;
};

class Quoteditable extends PureComponent<Props, State> {
  state: State = {
    isEditing: false,
    isSaving: false,
    editContent: '',
  };

  shouldSaveDraft = true;

  inputRef: HTMLInputElement | null = null;

  constructor(props: Props, context: any) {
    super(props, context);

    this.state.editContent = props.content;
  }

  async componentDidMount() {
    const { draftKey } = this.props;

    if (draftKey) {
      const draft = await draftService.get<State>(`Quoteditable_${draftKey}`);

      if (draft) {
        setImmediate(() => {
          this.setState({
            ...draft,
          });
        });
      }
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { content } = this.props;
    const { isEditing, editContent } = this.state;

    if (!editContent && content !== prevProps.content) {
      setImmediate(() => {
        this.setState({
          editContent: content,
        });
      });
    }

    if (isEditing && !prevState.isEditing) {
      if (this.inputRef) {
        this.inputRef.focus();

        this.inputRef.setSelectionRange(0, content.length);
      }
    }
  }

  componentWillUnmount() {
    const { draftKey } = this.props;

    if (draftKey && this.shouldSaveDraft) {
      const { editContent, isEditing } = this.state;

      draftService.set<Partial<State>>(`Quoteditable_${draftKey}`, {
        editContent,
        isEditing,
      });
    }
  }

  discardDraft = async () => {
    const { draftKey } = this.props;

    this.shouldSaveDraft = false;

    await draftService.remove(`Quoteditable_${draftKey}`);
  };

  toggleEdit = () => {
    const { content } = this.props;

    this.setState((state) => ({
      isEditing: !state.isEditing,
      editContent: state.isEditing ? content : state.editContent,
    }));
  };

  getEditingContent = () => {
    const { editContent, isEditing } = this.state;
    const { content } = this.props;

    return isEditing ? editContent : content;
  };

  save = () => {
    const { onChange } = this.props;
    const { editContent } = this.state;

    if (!editContent || !editContent.trim()) return;

    this.setState({
      isSaving: true,
    });

    if (onChange) {
      Promise.resolve(onChange(editContent.trim()))
        .then(() => {
          this.setState({
            isSaving: false,
            isEditing: false,
          });
        })
        .catch(() => {});
    }
  };

  renderEditForm = () => {
    const { label, saveRequired } = this.props;
    const { editContent, isSaving } = this.state;

    return (
      <>
        <TextField
          type="textarea"
          name="comment"
          disabled={isSaving}
          label={label}
          value={editContent}
          onChange={(e) => {
            this.setState({
              editContent: e.currentTarget.value,
            });
          }}
          inputRef={(ref) => {
            this.inputRef = ref;
          }}
          inputClassname="rounded"
          maxLength={250}
        />
        <div className="d-flex justify-content-center align-items-center mt-2">
          {saveRequired && (
            <Button
              color="primary"
              type="button"
              disabled={isSaving}
              className="btn-icon-only btn-sm mr-1"
              onClick={this.save}
            >
              <FontAwesomeIcon icon="check" size="sm" />
            </Button>
          )}
          <Button
            color="danger"
            type="button"
            disabled={isSaving}
            className={`btn-icon-only btn-sm ${saveRequired ? 'ml-1' : ''}`}
            onClick={this.toggleEdit}
          >
            <FontAwesomeIcon icon="times" size="sm" />
          </Button>
        </div>
      </>
    );
  };

  renderContent = () => {
    const { content, disabled } = this.props;

    return (
      <>
        <div className="quote-container">
          <FontAwesomeIcon icon="quote-left" className="icon q-open" />
          <FontAwesomeIcon icon="quote-right" className="icon q-close" />
          <div className="comment">{content}</div>
        </div>
        {!disabled && (
          <div className="d-flex justify-content-center align-items-center">
            <Button
              color="primary"
              type="button"
              className="btn-icon-only btn-sm"
              onClick={this.toggleEdit}
            >
              <FontAwesomeIcon icon="pen" size="sm" />
            </Button>
          </div>
        )}
      </>
    );
  };

  render() {
    const { isEditing } = this.state;
    const { className } = this.props;

    return (
      <div className={`quotediable ${className || ''}`}>
        {isEditing ? this.renderEditForm() : this.renderContent()}
      </div>
    );
  }
}

export default Quoteditable;
