import { ThunkAction, Action } from './types';
import { Chainsure, ChainsureLink } from '../types';
import httpClient from '../utils/httpClient';
import datetimeFormat from '../utils/datetime/datetimeFormat';

const historiesInit = (chainsure: Chainsure, type: 'damage' | 'chainsure' | 'owned'): Action => ({
  type: 'CHAINSURE_HISTORIES_INIT',
  payload: {
    chainsure,
    type,
  },
});

const loadMore = (
  type: 'damage' | 'chainsure' | 'owned',
  chainsureId: string,
  lastItem: ChainsureLink
): ThunkAction => async (dispatch) => {
  dispatch({
    type: 'CHAINSURE_HISTORIES_LOADING',
    payload: {
      chainsureId,
    },
  });

  let url = '';

  switch (type) {
    case 'damage':
      url = `/damages/c/${chainsureId}/histories`;
      break;
    case 'chainsure':
      url = `/inventories/c/${chainsureId}/histories`;
      break;
    case 'owned':
      url = `/inventories/owned/c/${chainsureId}/histories`;
      break;
    default:
      return;
  }

  const size = 5;

  url += `?size=${size}&date=${datetimeFormat.timestampToDate(lastItem.date).toISOString()}`;

  const { data: histories } = await httpClient.app.get<ChainsureLink[]>(url);

  dispatch({
    type: 'CHAINSURE_HISTORIES_LOADED',
    payload: {
      chainsureId,
      histories,
      canLoadMore: histories.length === size,
    },
  });
};

export default {
  historiesInit,
  loadMore,
};
