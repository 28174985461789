import React, { Component } from 'react';

export default function asyncComponent(getComponent: () => Promise<any>) {
  class AsyncComponent extends Component<any, any> {
    static loaded: any = null;

    componentDidMount() {
      if (!AsyncComponent.loaded) {
        getComponent().then((component) => {
          AsyncComponent.loaded = component;

          this.forceUpdate();
        });
      }
    }

    render() {
      if (AsyncComponent.loaded) {
        const { staticContext, ...rest } = this.props;

        return <AsyncComponent.loaded {...rest} />;
      }

      return null;
    }
  }

  return AsyncComponent;
}
