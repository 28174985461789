import React, { PureComponent, ReactNode } from 'react';
import './ChainsureInfoModal.scss';
import { Chainsure } from '../../../types';
import ProductInfoBasic from '../../ProductInfo/Basic/ProductInfoBasic';
import ProductInfoPrice from '../../ProductInfo/Price/ProductInfoPrice';
import chainsureAction from '../../../actions/chainsure.action';
import mediaService from '../../../utils/mediaService';

type Props = {
  chainsure: Chainsure;
  hideBuyAgain?: boolean;
};

class ChainsureInfoModal extends PureComponent<Props> {
  renderBasicInfo = () => {
    const {
      chainsure: { product, amount, lastLink },
    } = this.props;

    const photoURL =
      !lastLink || !lastLink.mediaIds || lastLink.mediaIds.length === 0
        ? product.photoURL
        : mediaService.getJoinedMediaImgLink(lastLink.mediaIds, product.photoURL);

    return (
      <ProductInfoBasic
        {...{
          ...product,
          price: amount,
          photoURL,
        }}
      />
    );
  };

  renderChainDetail = () => {
    const { chainsure, hideBuyAgain } = this.props;
    const { currency, amount, startDate, endDate, price, isImmediate } = chainsure;

    let dateRangeLbl: string | null = null;
    let buyAgainPrice: ReactNode | null = null;

    if (chainsure.product.type === 'free') {
      return null;
    }

    const status = chainsureAction.getUseStatus(chainsure);

    switch (status) {
      case 'used': {
        dateRangeLbl = 'CHAINSURE_LAST_USE_ON';
        break;
      }
      case 'using': {
        dateRangeLbl = 'CHAINSURE_USING';
        break;
      }
      case 'willuse': {
        dateRangeLbl = 'CHAINSURE_USE_ON';
        break;
      }
      default:
        break;
    }

    if (!hideBuyAgain && chainsure.status === 'released') {
      buyAgainPrice = <ProductInfoPrice price={price} currency={currency} titleKey="CHAINSURE_BUY_AGAIN_FOR" />;
    }

    return (
      <>
        <ProductInfoPrice
          price={amount}
          currency={currency}
          titleKey={dateRangeLbl}
          dateRange={{
            start: startDate,
            end: endDate,
          }}
          isImmediate={isImmediate && status === 'willuse'}
        />
        {buyAgainPrice}
      </>
    );
  };

  render() {
    const { chainsure } = this.props;

    if (!chainsure) return null;

    return (
      <>
        {this.renderBasicInfo()}
        {this.renderChainDetail()}
      </>
    );
  }
}

export default ChainsureInfoModal;
