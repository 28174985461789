import { CancelToken } from 'axios';
import config from './config';
import httpClient from './httpClient';

type CloudinaryMedia = {
  type: string;
  id: string;
  thumbnailLink: string;
  fullLink: string;
};

const rootUrl = `https://res.cloudinary.com/${config.cloudinary.cloudName}`;
const joiningSize = 600;

const transformAll = [
  null,
  [`w_${joiningSize},h_${joiningSize},c_fill`],
  [`w_${joiningSize / 2},h_${joiningSize},c_fill`, `w_${joiningSize / 2},h_${joiningSize},c_fill,x_${joiningSize / 2}`],
  [
    `w_${joiningSize / 2},h_${joiningSize},c_fill`,
    `w_${joiningSize / 2},h_${joiningSize / 2},c_fill,x_${joiningSize / 2},y_-${joiningSize / 4}`,
    `w_${joiningSize / 2},h_${joiningSize / 2},c_fill,x_${joiningSize / 4},y_${joiningSize / 4}`,
  ],
  [
    `w_${joiningSize / 2},h_${joiningSize / 2},c_fill`,
    `w_${joiningSize / 2},h_${joiningSize / 2},c_fill,y_${joiningSize / 2}`,
    `w_${joiningSize / 2},h_${joiningSize / 2},c_fill,x_${joiningSize / 2},y_-${joiningSize / 4}`,
    `w_${joiningSize / 2},h_${joiningSize / 2},c_fill,x_${joiningSize / 4},y_${joiningSize / 4}`,
  ],
];

const uploadFile = (
  file: Blob,
  onUploadProgress?: (data: { loaded: number; total: number }) => any,
  cancelToken?: CancelToken
) =>
  new Promise<string>((resolve) => {
    const formData = new FormData();

    formData.append('api_key', config.cloudinary.apiKey);
    formData.append('file', file);
    formData.append('upload_preset', config.cloudinary.unsignUploadPreset);

    httpClient.cloudinary
      .post<{
        public_id: string;
        resource_type: string;
      }>('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
        cancelToken,
      })
      .then(({ data: { public_id, resource_type } }) => {
        resolve(`${resource_type}|${public_id}`);
      })
      .catch((error) => {
        console.log(error);
        resolve('');
      });
  });

const upload = async (files: File[]) => {
  const uploadTasks: Promise<string>[] = [];

  for (let i = 0; i < files.length; i += 1) {
    const task = uploadFile(files[i]);

    uploadTasks.push(task);
  }

  const mediaIds = await Promise.all(uploadTasks);

  return mediaIds.filter((p) => p !== '');
};

const getThumbnailLink = (mediaId: string) => {
  const [type, id] = mediaId.split('|');

  if (type === 'image') {
    return `${rootUrl}/image/upload/c_thumb,w_300,ar_1:1,g_face/${id}`;
  }

  return `${rootUrl}/video/upload/c_thumb,w_300,ar_1:1,g_face/${id}.jpg`;
};

const getFullLink = (mediaId: string) => {
  const [type, id] = mediaId.split('|');

  if (type === 'image') {
    return `${rootUrl}/image/upload/${id}`;
  }

  return `${rootUrl}/video/upload/${id}.mp4`;
};

const getMedia = (mediaId: string): CloudinaryMedia => {
  const [type, id] = mediaId.split('|');

  return {
    type,
    id,
    thumbnailLink: getThumbnailLink(mediaId),
    fullLink: getFullLink(mediaId),
  };
};

const getJoinedMediaImgLink = (inputMediasIds: string[], fallback: string) => {
  if (!inputMediasIds || inputMediasIds.length === 0) {
    return fallback;
  }

  let medias = inputMediasIds.map<CloudinaryMedia>((x) => getMedia(x));

  const firstVideo = medias.find((x) => x.type === 'video');

  if (firstVideo) {
    medias = [firstVideo, ...medias.filter((x) => x.type === 'image')];
  }

  medias = medias.slice(0, 4);

  const videoExists = medias[0].type === 'video';

  if (videoExists) {
    medias[0].id += '.jpg';
  }

  for (let i = 1; i < medias.length; i++) {
    medias[i].id = medias[i].id.replace(/\//g, ':');
  }

  let result = `${rootUrl}/${videoExists ? 'video' : 'image'}/upload/`;
  const transforms = transformAll[medias.length];

  for (let i = medias.length - 1; i >= 0; i--) {
    if (i !== 0) {
      medias[i].id = `l_${medias[i].id.replace(/\//g, ':')}`;
    }

    result += transforms ? `${transforms[medias.length - 1 - i]}/${medias[i].id}${i === 0 ? '' : ','}` : '';
  }

  return result;
};

export default {
  uploadFile,
  upload,
  getThumbnailLink,
  getFullLink,
  getMedia,
  getJoinedMediaImgLink,
};
