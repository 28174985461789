import { GiftState } from './types';
import { Action } from '../actions/types';

const INITIAL_STATE: GiftState = {};

const reducer = (state: GiftState = INITIAL_STATE, action: Action): GiftState => {
  switch (action.type) {
    case 'GIFT_SELECT': {
      const { giftCode, chainsureId } = action.payload;

      return {
        ...state,
        giftCode,
        chainsureId,
        chainsure: null,
        isRequesting: false,
        isSuccess: false,
        error: null,
      };
    }

    case 'GIFT_DETAIL_LOADED': {
      const { chainsure } = action.payload;

      return {
        ...state,
        chainsure,
        isRequesting: false,
        error: null,
      };
    }
    case 'GIFT_ACCEPT': {
      return {
        ...state,
        isRequesting: true,
      };
    }
    case 'GIFT_SUCCESS': {
      return {
        ...state,
        isRequesting: false,
        isSuccess: true,
        error: null,
      };
    }
    case 'GIFT_ERROR': {
      const { error } = action.payload;

      return {
        ...state,
        isRequesting: false,
        error: error.code,
      };
    }
    case 'AUTH_STATE_CHANGED': {
      const { user } = action.payload;

      if (!user) {
        return {
          ...INITIAL_STATE,
        };
      }

      return state;
    }

    default:
      return state;
  }
};

export default reducer;
