import { AuthState } from './types';
import { Action } from '../actions/types';

const INITIAL_STATE: AuthState = {
  isAuth: false,
  isSignUpByEmail: false,
  init: true,
};

const reducer = (state: AuthState = INITIAL_STATE, action: Action): AuthState => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { user, addition } = action.payload;

      if (state.isSignUpByEmail) {
        return {
          ...state,
          init: false,
          isSignUpByEmail: false,
        };
      }

      if (user) {
        const { uid, displayName, photoURL, email, phoneNumber, providerData } = user;

        const providerId = providerData && providerData.length > 0 ? providerData[0]?.providerId : null;

        return {
          init: false,
          isAuth: true,
          isSignUpByEmail: false,
          uid,
          displayName,
          photoURL,
          email,
          phoneNumber,
          providerData,
          providerId,
          ...addition,
        };
      }

      return {
        ...INITIAL_STATE,
        init: false,
      };
    }

    case 'AUTH_PREPARE_SIGNUP_EMAIL': {
      return {
        ...state,
        isSignUpByEmail: true,
      };
    }

    case 'AUTH_DONE_SIGNUP_EMAIL': {
      return {
        ...state,
        isSignUpByEmail: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;
