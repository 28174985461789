import React, { PureComponent } from 'react';
import { Button, Tooltip } from 'reactstrap';
import { connect } from 'react-redux';
import TextField from '../../../../../components/TextField/TextField';
import copyToClipboard from '../../../../../utils/copyToClipboard';
import config from '../../../../../utils/config';
import FontAwesomeIcon from '../../../../../components/Icon/FontAwesomeIcon';
import { i18nTranslator } from '../../../../../utils/i18n';
import facebook from '../../../../../utils/facebook';
import twitter from '../../../../../utils/twitter';
import { ReduxState } from '../../../../../reducers/types';

const mapStateToProps = ({ chainsures: { inActionChainsureId, giftCode } }: ReduxState) => ({
  giftCode,
  chainsureId: inActionChainsureId,
});

type Props = ReturnType<typeof mapStateToProps> & {
  toggleTagUser: () => any;
};

type State = {
  copied: boolean;
};

class ChainsureShareGift extends PureComponent<Props, State> {
  state: State = {
    copied: false,
  };

  componentDidMount() {
    facebook.init();
  }

  get giftLink() {
    const { chainsureId, giftCode } = this.props;

    return `${config.host.url}/gift/c/${chainsureId || ''}/g/${giftCode || ''}`;
  }

  copyLinkToClipboard = () => {
    copyToClipboard(this.giftLink);

    this.setState({
      copied: true,
    });
  };

  shareOnSocial = (provide: 'facebook' | 'twitter') => {
    switch (provide) {
      case 'facebook':
        facebook.openDialog(this.giftLink);
        break;
      case 'twitter':
        twitter.openDialog(this.giftLink);
        break;
      default:
        break;
    }
  };

  render() {
    const { toggleTagUser } = this.props;
    const { copied } = this.state;

    return (
      <div className="w-100 d-flex flex-column">
        <TextField
          type="text"
          placeholder=""
          name="sharelink"
          value={this.giftLink}
          disabled
          inputClassname="disable-keep-normal-input"
          formGroupClassname="flex-grow-1 mb-0"
        />
        <Tooltip
          isOpen={copied}
          target="sharelink"
          placement="bottom"
          offset={5}
          toggle={() => {
            this.setState({
              copied: false,
            });
          }}
        >
          {i18nTranslator('CHAINSURE_MAKE_GIFT_COPIED')}
        </Tooltip>
        <Button color="primary" type="button" className="mt-2 btn-icon-left" onClick={this.copyLinkToClipboard}>
          <div className="btn-icon">
            <FontAwesomeIcon icon="copy" size="lg" />
          </div>
          <span>{i18nTranslator('CHAINSURE_MAKE_GIFT_COPY')}</span>
        </Button>
        <Button
          color="facebook"
          type="button"
          className="mt-2 btn-icon-left"
          onClick={() => this.shareOnSocial('facebook')}
        >
          <div className="btn-icon">
            <FontAwesomeIcon icon={['fab', 'facebook-f']} size="lg" />
          </div>
          <span>{i18nTranslator('CHAINSURE_MAKE_GIFT_SHARE_ON').replace(/\[PROVIDER\]/gi, 'Facebook')}</span>
        </Button>
        <Button
          color="twitter"
          type="button"
          className="mt-2 btn-icon-left"
          onClick={() => this.shareOnSocial('twitter')}
        >
          <div className="btn-icon">
            <FontAwesomeIcon icon={['fab', 'twitter']} size="lg" />
          </div>
          <span>{i18nTranslator('CHAINSURE_MAKE_GIFT_SHARE_ON').replace(/\[PROVIDER\]/gi, 'Twitter')}</span>
        </Button>
        <Button color="primary" type="button" className="mt-2 btn-icon-left" onClick={toggleTagUser}>
          <div className="btn-icon">
            <FontAwesomeIcon icon="user-tag" size="lg" />
          </div>
          <span>{i18nTranslator('CHAINSURE_MAKE_GIFT_SEND')}</span>
        </Button>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ChainsureShareGift);
