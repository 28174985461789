import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faBox,
  faBoxOpen,
  faUserFriends,
  faAngleLeft,
  faAngleRight,
  faSortDown,
  faUser,
  faTimes,
  faCheck,
  faUserClock,
  faCheckCircle,
  faCalendarAlt,
  faDollarSign,
  faGift,
  faExclamationTriangle,
  faAward,
  faClock,
  faPlus,
  faTrash,
  faCopy,
  faCamera,
  faPhone,
  faEnvelope,
  faLock,
  faLink,
  faCubes,
  faCoins,
  faQuestionCircle,
  faImage,
  faVideo,
  faShareAlt,
  faEllipsisV,
  faEuroSign,
  faUserCheck,
  faUserTag,
  faQuoteLeft,
  faQuoteRight,
  faPen,
  faArrowRight,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faFacebook,
  faTwitter,
  faGoogle,
} from '@fortawesome/free-brands-svg-icons';
import {
  faClock as farClock,
  faWindowMaximize,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faBars,
  faAngleLeft,
  faAngleRight,
  faSortDown,
  faUser,
  faDollarSign,
  faCopy,
  faGift,
  faAward,
  faClock,
  faTimes,
  faEuroSign,
  faUserCheck,
  faCalendarAlt,
  faFacebookF,
  faFacebook,
  faUserClock,
  faTwitter,
  faTrash,
  faExclamationTriangle,
  faGoogle,
  faPhone,
  faEnvelope,
  faPlus,
  faCamera,
  faLock,
  faLink,
  faCubes,
  faCoins,
  faQuoteLeft,
  faQuoteRight,
  faEllipsisV,
  faShareAlt,
  farClock,
  faWindowMaximize,
  faQuestionCircle,
  faExclamationCircle,
  faUserTag,
  faArrowRight,
  faImage,
  faVideo,
  faBox,
  faPen,
  faBoxOpen,
  faUserFriends,
  faCheck,
  faCheckCircle
);

export default FontAwesomeIcon;
