import React, { Component, SyntheticEvent, ReactNode } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Container, Row, Form, Col, Button } from 'reactstrap';
import '../auth.scss';
import TextField from '../../../components/TextField/TextField';
import validate from '../../../utils/validations/validate';
import isEmpty from '../../../utils/validations/isEmpty';
import authActions from '../../../actions/auth.actions';
import Hud from '../../../components/Hud/Hud';
import queryString from '../../../utils/queryString';
import contentFromPattern from '../../../utils/contentFromPattern';

type State = {
  code: string | null;
  email: string | null;
  password: string;
  confirmPassword: string;
  isRequesting: boolean;
  isSuccess: boolean;
  requestError: string | null;
  errors: { [field: string]: string };
};

const ResetPasswordValidateConfig = {
  password: {
    require: true,
    length: {
      min: 6,
    },
  },
  confirmPassword: {
    require: true,
    equalOther: 'password',
  },
};

type Props = RouteComponentProps<{}> & WithNamespaces;

class ResetPassword extends Component<Props, State> {
  state: State = {
    isSuccess: false,
    code: null,
    email: null,
    password: '',
    confirmPassword: '',
    errors: validate({}, ResetPasswordValidateConfig) || {},
    isRequesting: false,
    requestError: null,
  };

  async componentDidMount() {
    const { location, history } = this.props;

    const qss = queryString.parse(location.search) as {
      mode: string;
      oobCode: string;
    };

    if (qss.mode !== 'resetPassword' && !qss.oobCode) {
      history.replace('/login');
      return;
    }

    try {
      const email = await authActions.verifyResetPasswordCode(qss.oobCode);

      this.setState({
        code: qss.oobCode,
        email,
      });
    } catch (error) {
      this.setState({
        requestError: error.code,
      });
    }
  }

  onChangeField = (event: SyntheticEvent<HTMLInputElement>) => {
    this.setState(
      {
        [event.currentTarget.name]: event.currentTarget.value,
      } as any,
      () => {
        this.setState({
          errors: validate(this.state, ResetPasswordValidateConfig) || {},
        });
      }
    );
  };

  onSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { password, code } = this.state;

    this.setState({
      isRequesting: true,
    });

    try {
      await authActions.resetPassword(code || '', password);
      this.setState({
        isRequesting: false,
        isSuccess: true,
      });
    } catch (error) {
      this.setState({
        isRequesting: false,
        requestError: error.code,
      });
    }
  };

  tryAgain = () => {
    const { history } = this.props;

    history.replace('/login');
  };

  render() {
    const { t } = this.props;
    const { email, password, confirmPassword, errors, isRequesting, requestError, isSuccess } = this.state;

    const resetPasswordErrorMessages = {
      password: {
        require: t('USER_PASSWORD_REQUIRED'),
        length: t('USER_PASSWORD_LENGTH'),
      },
      confirmPassword: {
        require: t('USER_CONFIRM_PASSWORD_REQUIRED'),
        equalOther: t('USER_CONFIRM_PASSWORD_NOT_MATCH'),
      },
    };

    let message: string | null = null;

    if (requestError != null) {
      switch (requestError) {
        case 'auth/expired-action-code':
        case 'auth/invalid-action-code':
          message = t('USER_RESET_PASSWORD_ERR_INVALID_CODE');
          break;
        default:
          message = t('USER_RESET_PASSWORD_ERR_UNKNOWN');
          break;
      }
    } else if (isSuccess) {
      message = t('USER_RESET_PASSWORD_SUCCESS');
    }

    let desc: ReactNode = null;
    if (email) {
      desc = contentFromPattern(
        t('USER_RESET_PASSWORD_DESC'),
        [
          {
            regex: /\[EMAIL\]/g,
            build: () => (
              <span className="font-weight-medium text-primary" key="email">
                {email}
              </span>
            ),
          },
        ],
        0
      );
    }

    return (
      <Container tag="main" role="main">
        <Hud
          isOpen={message != null || isRequesting || email == null || isSuccess}
          type={isRequesting || (email == null && message == null) ? 'loading' : 'alert'}
          alertBtnTitle={isSuccess ? t('MENU_LOG_IN') : t('ALERT_OK')}
          alertTitle={isSuccess ? t('ALERT_SUCCESS') : t('ALERT_ERROR')}
          alertDesc={message}
          toggle={this.tryAgain}
        />

        {email && (
          <Row>
            <Col
              xs={12}
              sm={{
                size: 8,
                offset: 2,
              }}
              lg={{
                size: 6,
                offset: 3,
              }}
              className=" d-flex flex-column align-items-center auth"
            >
              <h5 className="mt-md-3 font-weight-bold text-primary">{t('USER_RESET_PASSWORD_TITLE')}</h5>
              <p>{desc}</p>
              <Form className="w-100" onSubmit={this.onSubmit}>
                <TextField
                  type="password"
                  name="password"
                  placeholder={t('USER_PASSWORD')}
                  value={password}
                  onChange={this.onChangeField}
                  disabled={isRequesting}
                  invalid={errors.password != null}
                  invalidMessage={errors.password && resetPasswordErrorMessages.password[errors.password]}
                />
                <TextField
                  type="password"
                  name="confirmPassword"
                  placeholder={t('USER_CONFIRM_PASSWORD')}
                  value={confirmPassword}
                  onChange={this.onChangeField}
                  disabled={isRequesting}
                  invalid={errors.confirmPassword != null}
                  invalidMessage={
                    errors.confirmPassword && resetPasswordErrorMessages.confirmPassword[errors.confirmPassword]
                  }
                />
                <Button type="submit" color="primary" className="w-100" disabled={isRequesting || !isEmpty(errors)}>
                  {t('USER_RESET_PASSWORD_BTN')}
                </Button>
              </Form>
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}
export default withNamespaces()(ResetPassword);
