import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Navigation.scss';
import {
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  Button,
} from 'reactstrap';
import { NavLink as RRDNavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import Logo from '../Logo/Logo';
import FontAwesomeIcon from '../Icon/FontAwesomeIcon';
import NavigationItem from './NavigationItem/NavigationItem';
import { ReduxState, AuthState } from '../../reducers/types';
import authActions from '../../actions/auth.actions';
import UserPhoto from '../UserPhoto/UserPhoto';
import queryString from '../../utils/queryString';

type Props = AuthState & {
  logout: typeof authActions.logout;
} & RouteComponentProps<{}> &
  WithNamespaces;

type State = {
  collapseMenuIsOpen: boolean;
};

class Navigation extends Component<Props, State> {
  state: State = {
    collapseMenuIsOpen: false,
  };

  componentDidUpdate(prevProps: Props) {
    const { history, isAuth, location, init } = this.props;

    if (prevProps.init && !init) return;

    if (prevProps.isAuth !== isAuth) {
      if (prevProps.isAuth) {
        // log out
        history.push('/');
      } else {
        // log in
        const qss = queryString.parse(location.search);

        if (qss && qss.next) {
          history.replace(qss.next);
        } else {
          history.replace('/dashboard');
        }
      }
    }
  }

  toggleDropdownMenu = () => {
    this.setState(({ collapseMenuIsOpen }) => ({
      collapseMenuIsOpen: !collapseMenuIsOpen,
    }));
  };

  closeDropdownMenu = () => {
    const { collapseMenuIsOpen } = this.state;

    if (collapseMenuIsOpen) {
      this.setState({
        collapseMenuIsOpen: false,
      });
    }
  };

  renderUserGroup = () => {
    const { isAuth, photoURL, displayName, logout, init, t } = this.props;

    if (init) {
      return <div className="user user-init justify-content-between align-items-center d-md-flex d-none" />;
    }

    if (isAuth) {
      return (
        <UncontrolledDropdown className="d-md-flex d-none user-dropdown">
          <DropdownToggle
            color="primary"
            className="user authenticated justify-content-between align-items-center d-md-flex d-none"
          >
            <UserPhoto photoURL={photoURL} />
            <span className="display-name cs-text-white">{displayName}</span>
            <span>
              <FontAwesomeIcon className="cs-text-white" icon="sort-down" />
            </span>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem tag={RRDNavLink} to="/dashboard">
              {t('MENU_DASHBOARD')}
            </DropdownItem>
            <DropdownItem tag={RRDNavLink} to="/offers">
              {t('MENU_OFFERS')}
            </DropdownItem>
            <DropdownItem tag={RRDNavLink} to="/inventories">
              {t('MENU_INVENTORIES')}
            </DropdownItem>
            <DropdownItem tag={RRDNavLink} to="/payouts">
              {t('MENU_PAYOUTS')}
            </DropdownItem>
            <DropdownItem tag={RRDNavLink} to="/joined">
              {t('MENU_JOINED')}
            </DropdownItem>
            <DropdownItem tag={RRDNavLink} to="/settings">
              {t('MENU_SETTINGS')}
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={logout}>{t('MENU_LOG_OUT')}</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }

    return (
      <div className="user justify-content-between align-items-center d-md-flex d-none">
        <RRDNavLink to="/login" exact>
          {t('MENU_LOG_IN')}
        </RRDNavLink>
        <span>/</span>
        <RRDNavLink to="/signup" exact>
          {t('MENU_SIGN_UP')}
        </RRDNavLink>
      </div>
    );
  };

  renderDropdownMenuToggler = () => {
    const { isAuth, photoURL } = this.props;

    if (isAuth) {
      return (
        <div className="user authenticated toggler">
          <UserPhoto photoURL={photoURL} />
        </div>
      );
    }

    return <FontAwesomeIcon icon="bars" />;
  };

  renderAdditionMenuItems = () => {
    const { isAuth, logout, t } = this.props;

    if (isAuth) {
      return (
        <>
          <NavigationItem className="d-md-none" to="/dashboard">
            {t('MENU_DASHBOARD')}
          </NavigationItem>
          <NavigationItem className="d-md-none" to="/offers">
            {t('MENU_OFFERS')}
          </NavigationItem>
          <NavigationItem className="d-md-none" to="/inventories">
            {t('MENU_INVENTORIES')}
          </NavigationItem>
          <NavigationItem className="d-md-none" to="/payouts">
            {t('MENU_PAYOUTS')}
          </NavigationItem>
          <NavigationItem className="d-md-none" to="/joined">
            {t('MENU_JOINED')}
          </NavigationItem>
          <NavigationItem className="d-md-none" to="/settings">
            {t('MENU_SETTINGS')}
          </NavigationItem>
          <NavItem className="d-md-none nav-devider" />
          <NavItem className="d-md-none">
            <Button color="link" onClick={logout}>
              {t('MENU_LOG_OUT')}
            </Button>
          </NavItem>
          <NavItem className="d-md-none nav-devider" />
        </>
      );
    }

    return (
      <>
        <NavigationItem to="/login" className="d-md-none">
          {t('MENU_LOG_IN')}
        </NavigationItem>
        <NavigationItem to="/signup" className="d-md-none">
          {t('MENU_SIGN_UP')}
        </NavigationItem>
        <NavItem className="d-md-none nav-devider" />
      </>
    );
  };

  render() {
    const { t, isAuth } = this.props;
    const { collapseMenuIsOpen } = this.state;

    return (
      <Navbar className="cs-navbar" expand="md">
        <Container>
          <NavbarBrand to={isAuth ? '/dashboard' : '/'} tag={RRDNavLink}>
            <Logo />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleDropdownMenu} className={collapseMenuIsOpen ? '' : 'collapsed'}>
            {this.renderDropdownMenuToggler()}
          </NavbarToggler>
          <Collapse isOpen={collapseMenuIsOpen} navbar>
            <Nav className="ml-auto" navbar onClick={this.closeDropdownMenu}>
              {this.renderAdditionMenuItems()}
              <NavigationItem to="/" exact>
                {t('MENU_HOME')}
              </NavigationItem>
              <NavigationItem to="/app">{t('MENU_FEATURES')}</NavigationItem>
              <NavigationItem to="/about">{t('MENU_ABOUT')}</NavigationItem>
              <NavigationItem to="/help">{t('MENU_HELP')}</NavigationItem>
            </Nav>
          </Collapse>
          {this.renderUserGroup()}
        </Container>
      </Navbar>
    );
  }
}

const mapStateToProps = ({ auth }: ReduxState) => ({
  ...auth,
});

export default withRouter(
  withNamespaces()(
    connect(mapStateToProps, {
      logout: authActions.logout,
    })(Navigation)
  )
);
