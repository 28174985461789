import React, { ReactNode } from 'react';
import './ProductInfoListItem.scss';
import { Row, Col } from 'reactstrap';

type Props = {
  className?: string;
  indicatorClass?: string;
  horizontal?: boolean;
};

const ProductInfoListItemLoading = (props: Props) => {
  const { className, horizontal, indicatorClass } = props;

  let content: ReactNode = null;

  if (horizontal) {
    content = (
      <>
        <Col md={6}>
          <Row noGutters>
            <div className="photo col-md-3 col-4" />
            <Col md={9} xs={8} className="pl-2">
              <div className="text-sm-left text-right product-title text-truncate" />
              <div className="text-sm-left text-right product-desc text-truncate" />
            </Col>
          </Row>
        </Col>
        <Col md={6} className="mt-3 mt-md-0 bottom-container" />
      </>
    );
  } else {
    content = (
      <>
        <div className="photo col-4 col-sm-12" />
        <div className="col-8 col-sm-12 pl-2 pl-sm-0 pt-0 pt-sm-2">
          <div className="text-sm-left text-right product-title text-truncate" />
          <div className="text-sm-left text-right product-desc text-truncate" />
        </div>
        <div className="col-12 d-flex justify-content-between mt-2  bottom-container" />
      </>
    );
  }

  return (
    <Row
      className={`product-list-item ${className || ''} loading ${
        horizontal ? 'horizontal' : ''
      }`}
      noGutters
    >
      <Col>
        {indicatorClass && (
          <Row
            noGutters
            className={`product-indicator ${indicatorClass || ''}`}
          >
            <Col />
          </Row>
        )}
        <Row noGutters className="content">
          {content}
        </Row>
      </Col>
    </Row>
  );
};

export default ProductInfoListItemLoading;
