import React, { PureComponent, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Form, Row, Col } from 'reactstrap';
import Hud, { HudType } from '../../../../components/Hud/Hud';
import { ReduxState } from '../../../../reducers/types';
import chainsureAction from '../../../../actions/chainsure.action';
import TextField from '../../../../components/TextField/TextField';
import validate from '../../../../utils/validations/validate';
import isEmpty from '../../../../utils/validations/isEmpty';
import PhotoUploader from '../../../../components/Photo/PhotoUploader/PhotoUploader';
import { i18nTranslator } from '../../../../utils/i18n';
import ProductInfoActionContainer from '../../../../components/ProductInfo/ActionContainer/ProductInfoActionContainer';
import Quoteditable from '../../../../components/Quoteditable/Quoteditable';
import draftService from '../../../../utils/draftService';
import datetimeFormat from '../../../../utils/datetime/datetimeFormat';

const mapStateToProps = ({
  chainsures: { action, damageReportStatus, damageReportError, chainsureId, chainsure },
}: ReduxState) => ({
  action,
  damageReportStatus,
  damageReportError,
  chainsureId,
  chainsure,
});

type Props = {
  toggleDamage: typeof chainsureAction.toggleDamage;
  reportDamage: typeof chainsureAction.reportDamage;
  selectChainsure: typeof chainsureAction.selectChainsure;
} & ReturnType<typeof mapStateToProps> &
  Pick<RouteComponentProps<{}>, 'history'>;

type State = {
  email: string;
  description: string;
  mediaRequired: boolean;
  errors: { [field: string]: string };
};

const DamageValidateConfig = {
  email: {
    require: true,
    email: true,
  },
};

class ChainsureDamageReport extends PureComponent<Props, State> {
  state: State = {
    mediaRequired: false,
    email: '',
    description: '',
    errors: {
      init: '',
    },
  };

  files: File[] = [];

  photoUploaderRef: PhotoUploader | null = null;

  quoteditableRef: Quoteditable | null = null;

  shouldSaveDraft = true;

  async componentDidMount() {
    this.getRedeemNote();

    const { chainsureId, action } = this.props;

    if (chainsureId && action === 'damage') {
      await this.tryToRestoreDraft();
    }
  }

  async componentDidUpdate(prevProps: Props, prevState: State) {
    const { chainsure, action, chainsureId } = this.props;

    if (chainsureId && !prevProps.chainsureId) {
      this.shouldSaveDraft = true;
    }

    if (!chainsure) {
      if (prevProps.chainsure && prevProps.action === 'damage') {
        await this.saveDraft(prevProps.chainsureId, prevState);
      }

      return;
    }

    if (chainsure && prevProps.chainsure !== chainsure) {
      setImmediate(() => {
        this.setState({
          description: '',
        });
      });

      this.getRedeemNote();
    }

    if (action === 'damage' && prevProps.action !== 'damage') {
      await this.tryToRestoreDraft();
    } else if (action !== 'damage' && prevProps.action === 'damage') {
      await this.saveDraft(prevProps.chainsureId, prevState);
    }
  }

  async componentWillUnmount() {
    const { chainsureId, action } = this.props;

    if (chainsureId && action === 'damage') {
      await this.saveDraft(chainsureId, this.state);
    }
  }

  get isFree() {
    const { chainsure } = this.props;

    return chainsure != null && chainsure.product.type === 'free';
  }

  tryToRestoreDraft = async () => {
    const { chainsureId } = this.props;
    const draft = await draftService.get<State>(`ChainsureDamageReport_cId_${chainsureId}`);

    if (draft) {
      setImmediate(() => {
        this.setState(
          {
            ...draft,
          },
          this.validateInput
        );
      });
    }
  };

  saveDraft = async (chainsureId: string | null | undefined, state: State) => {
    if (!this.shouldSaveDraft || !chainsureId) {
      return;
    }

    const { description, email } = state;

    await draftService.set<Partial<State>>(`ChainsureDamageReport_cId_${chainsureId}`, {
      description,
      email,
    });
  };

  getRedeemNote = () => {
    const { description } = this.state;
    const { chainsureId, chainsure } = this.props;

    if (description || !chainsureId || !chainsure) return;

    const userState = chainsureAction.getUseStatus(chainsure);

    if (
      (userState === 'used' && chainsure.status !== 'released') ||
      (userState === 'using' && datetimeFormat.timestampToDate(chainsure.startDate) < new Date())
    ) {
      chainsureAction
        .getRedeemNote(chainsureId)
        .then((comment) => {
          this.setState(
            {
              description: comment,
            },
            this.validateInput
          );
        })
        .catch(() => {});
    }
  };

  onSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { reportDamage, chainsureId, chainsure } = this.props;
    const { email } = this.state;

    if (!this.photoUploaderRef || !chainsure || !chainsureId) {
      return;
    }

    let mediaIds: string[] = [];

    if (this.files.length > 0) {
      mediaIds = await this.photoUploaderRef.upload();

      if (mediaIds.length === 0) {
        // upload is cancelled
        return;
      }
    }

    const description = this.quoteditableRef?.getEditingContent() || '';

    if (chainsure.product.type === 'insurance' && mediaIds.length === 0) {
      this.setState({
        mediaRequired: true,
      });

      return;
    }

    reportDamage(chainsureId, email, description, mediaIds);
  };

  discardDraft = async () => {
    const { chainsureId } = this.props;

    this.shouldSaveDraft = false;

    await draftService.remove(`ChainsureDamageReport_cId_${chainsureId}`);

    if (this.quoteditableRef) {
      await this.quoteditableRef.discardDraft();
    }

    if (this.photoUploaderRef) {
      await this.photoUploaderRef.discardDraft();
    }
  };

  onClose = async () => {
    const { toggleDamage, history, damageReportStatus, chainsureId, selectChainsure } = this.props;

    if (damageReportStatus === 'success') {
      await this.discardDraft();

      history.replace(`/payouts/c/${chainsureId}`);

      selectChainsure(null);
    } else {
      toggleDamage();
    }
  };

  onChangeField = (event: SyntheticEvent<HTMLInputElement>) => {
    this.setState(
      {
        [event.currentTarget.name]: event.currentTarget.value,
      } as any,
      this.validateInput
    );
  };

  validateInput = () => {
    const { chainsure } = this.props;

    if (!chainsure) {
      return;
    }

    this.setState((state) => {
      const newState: any = {
        errors: validate(state, DamageValidateConfig) || {},
      };

      if (newState.errors.email && chainsure.amount === 0) {
        delete newState.errors.email;
      }

      return newState;
    });
  };

  renderIssueMessage = () => {
    const { mediaRequired } = this.state;

    let message: string | null = null;

    if (mediaRequired) {
      message = i18nTranslator('DAMAGE_INSURANCE_MEDIA');
    }

    if (!message) return null;

    return (
      <Row form>
        <div className="text-center invalid-feedback dark d-block ml-0 mb-2">{message}</div>
      </Row>
    );
  };

  render() {
    const { damageReportStatus, action, chainsure, chainsureId } = this.props;
    const { email, description, errors } = this.state;
    const { isFree } = this;

    if (action !== 'damage') return null;

    const damageErrorMessages = {
      email: {
        require: i18nTranslator('DAMAGE_EMAIL_REQUIRED'),
        email: i18nTranslator('DAMAGE_EMAIL_INVALID'),
      },
    };

    let hudType: HudType | null = null;
    let alertDesc: string | null = null;
    let alertTitle: string | null = null;

    if (damageReportStatus) {
      hudType = damageReportStatus === 'requesting' ? 'loading' : 'alert';

      switch (damageReportStatus) {
        case 'success':
          hudType = 'toast';
          alertDesc = i18nTranslator('DAMAGE_SUCCESS');
          break;
        case 'error':
          hudType = 'alert';
          alertTitle = i18nTranslator('ALERT_ERROR');
          alertDesc = i18nTranslator('DAMAGE_ERROR');
          break;
        case 'requesting':
          hudType = 'loading';
          break;
        default:
          break;
      }
    }

    return (
      <>
        <Hud
          type={hudType}
          isOpen={hudType != null}
          alertBtnTitle={i18nTranslator('ALERT_OK')}
          alertDesc={alertDesc}
          alertTitle={alertTitle}
          toastMessage={alertDesc}
          toastTimeout={1000}
          toggle={this.onClose}
        />
        <ProductInfoActionContainer
          icon="box-open"
          title={i18nTranslator('DAMAGE_TITLE')}
          description={i18nTranslator('DAMAGE_DESC')}
          onClose={this.onClose}
        >
          <Form onSubmit={this.onSubmit}>
            {!isFree && (
              <Row form>
                <Col>
                  <TextField
                    type="email"
                    name="email"
                    label={i18nTranslator('DAMAGE_EMAIL')}
                    placeholder={i18nTranslator('DAMAGE_EMAIL_PLACEHOLDER')}
                    value={email}
                    onChange={this.onChangeField}
                    invalid={errors.email != null}
                    invalidMessage={errors.email && damageErrorMessages.email[errors.email]}
                  />
                </Col>
              </Row>
            )}
            <Row form>
              <Col>
                <Quoteditable
                  draftKey={`ChainsureDamageReport_cId_${chainsureId}`}
                  content={description}
                  label={i18nTranslator('DAMAGE_COMMENT')}
                  onChange={(text) => {
                    this.setState(
                      {
                        description: text,
                      },
                      this.validateInput
                    );
                  }}
                  ref={(ref) => {
                    this.quoteditableRef = ref;
                  }}
                />
              </Col>
            </Row>
            <Row form>
              <Col>
                <div className="w-100">
                  <PhotoUploader
                    draftKey={`ChainsureDamageReport_cId_${chainsureId}`}
                    label={i18nTranslator('DAMAGE_PICTURES')}
                    maxFile={chainsure?.product.maxMedia || 0}
                    onSelectedFiles={(files) => {
                      this.files = files;
                    }}
                    ref={(ref) => {
                      this.photoUploaderRef = ref;
                    }}
                  />
                </div>
              </Col>
            </Row>
            {this.renderIssueMessage()}
            <Row form className="p-1">
              <Button color="danger" className="w-100" disabled={!isEmpty(errors)}>
                {i18nTranslator('CHAINSURE_RELEASE_BAD')}
              </Button>
            </Row>
          </Form>
        </ProductInfoActionContainer>
      </>
    );
  }
}
export default connect(mapStateToProps, {
  toggleDamage: chainsureAction.toggleDamage,
  reportDamage: chainsureAction.reportDamage,
  selectChainsure: chainsureAction.selectChainsure,
})(ChainsureDamageReport);
