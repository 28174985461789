

import {  Timestamp } from '../../types'
import configs from './configs'
import padNumber from '../padNumber'
import isValidDate from '../validations/isValidDate'
import { currLanguage } from '../i18n'

const timestampToDate = (dateStr: string | Timestamp) => {
  return typeof dateStr === 'string'
    ? new Date(dateStr)
    : new Date(dateStr._seconds * 1000 + Math.round(dateStr._nanoseconds / 1000000))
}

const dateToLocaleString = (date: Date, format?: 'date' | 'time' | 'full-trim-year' | 'date-trim-year') => {
  const config = configs[currLanguage()]
  let pattern = config.fullPattern

  switch (format) {
    case 'date':
      pattern = config.datePattern
      break
    case 'date-trim-year':
      if (date.getFullYear() === new Date().getFullYear()) {
        pattern = config.dateTrimYearPattern
      } else {
        pattern = config.datePattern
      }
      break
    case 'time':
      pattern = config.timePattern
      break
    case 'full-trim-year':
      if (date.getFullYear() === new Date().getFullYear()) {
        pattern = config.fullTrimYearPattern
      } else {
        pattern = config.fullPattern
      }
      break
    default:
      break
  }
  if (format) {
    if (format === 'date') {
      pattern = config.datePattern
    } else if (format === 'time') {
      pattern = config.timePattern
    }
  }

  return pattern
    .map(token => {
      switch (token) {
        case 'MM': {
          return padNumber(date.getMonth() + 1, 2)
        }
        case 'dd': {
          return padNumber(date.getDate(), 2)
        }
        case 'yyyy': {
          return date.getFullYear()
        }
        case 'HH': {
          return padNumber(date.getHours(), 2)
        }
        case 'mm': {
          return padNumber(date.getMinutes(), 2)
        }
        default:
          return token
      }
    })
    .join('')
}

const parseToLocaleString = (
  dateStr: string | Timestamp,
  only?: 'date' | 'time' | 'full-trim-year' | 'date-trim-year'
) => {
  const date =
    typeof dateStr === 'string'
      ? new Date(dateStr)
      : new Date(dateStr._seconds * 1000 + Math.round(dateStr._nanoseconds / 1000000))

  if (date instanceof Date && !Number.isNaN(date)) {
    return dateToLocaleString(date, only)
  }

  return 'INVALID DATE'
}

const parseToDate = (dateStr: string) => {
  const config = configs[currLanguage()]

  config.fullRegex.lastIndex = 0
  const matches = config.fullRegex.exec(dateStr)

  if (matches == null) return null

  const mapped = {
    year: 0,
    date: 0,
    month: 0,
    hour: 0,
    minute: 0
  }
  for (let i = 0; i < config.fullRegexMap.length; i++) {
    const mapTo = config.fullRegexMap[i]

    mapped[mapTo] = parseInt(matches[i + 1], 10)
  }

  mapped.month -= 1

  if (!isValidDate(mapped.year, mapped.month, mapped.date, mapped.hour, mapped.minute)) {
    return null
  }

  return new Date(mapped.year, mapped.month, mapped.date, mapped.hour, mapped.minute)
}

const parseDurationToString = (duration: number) => {
  const second = duration % 60
  let min = Math.floor(duration / 60)
  let hour = 0

  if (min > 60) {
    hour = Math.floor(min / 60)
    min %= 60
  }

  let result = padNumber(second, 2)

  result = `${padNumber(min, 2)}:${result}`

  if (hour !== 0) {
    result = `${padNumber(hour, 2)}:${result}`
  }

  return result
}

export default {
  config: () => configs[currLanguage()],
  dateToLocaleString,
  parseToLocaleString,
  parseToDate,
  parseDurationToString,
  timestampToDate
}
