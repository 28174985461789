let hasBlobConstructor = false;
try {
  hasBlobConstructor = Boolean(new Blob());
} catch (e) {}

let hasArrayBufferViewSupport = false;
try {
  hasArrayBufferViewSupport = new Blob([new Uint8Array(100)]).size === 100;
} catch (e) {}

const hasToBlobSupport = typeof HTMLCanvasElement !== 'undefined' ? !!HTMLCanvasElement.prototype.toBlob : false;

const hasBlobSupport =
  hasToBlobSupport ||
  (typeof Uint8Array !== 'undefined' && typeof ArrayBuffer !== 'undefined' && typeof atob !== 'undefined');

const hasReaderSupport = typeof FileReader !== 'undefined' || typeof URL !== 'undefined';

const isSupported = () =>
  typeof HTMLCanvasElement !== 'undefined' && hasBlobSupport && hasBlobConstructor && hasReaderSupport;

const toBlob = (canvas: HTMLCanvasElement, type: string) => {
  const dataURI = canvas.toDataURL(type);
  const dataURIParts = dataURI.split(',');
  let byteString;
  if (dataURIParts[0].indexOf('base64') >= 0) {
    // Convert base64 to raw binary data held in a string:
    byteString = atob(dataURIParts[1]);
  } else {
    // Convert base64/URLEncoded data component to raw binary data:
    byteString = decodeURIComponent(dataURIParts[1]);
  }
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const intArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i += 1) {
    intArray[i] = byteString.charCodeAt(i);
  }

  const mimeString = dataURIParts[0].split(':')[1].split(';')[0];
  let blob: Blob | null = null;

  if (hasBlobConstructor) {
    blob = new Blob([hasArrayBufferViewSupport ? intArray : arrayBuffer], {
      type: mimeString,
    });
  } else {
    // const bb = new BlobBuilder()
    // bb.append(arrayBuffer)
    // blob = bb.getBlob(mimeString)
  }

  return blob;
};

const loadImage = (image: HTMLImageElement, file, callback?: () => void) => {
  if (typeof URL === 'undefined') {
    const reader = new FileReader();
    reader.onload = (evt) => {
      if (evt.target && evt.target.result) {
        image.src = evt.target.result.toString();
        if (callback) {
          callback();
        }
      }
    };
    reader.readAsDataURL(file);
  } else {
    image.src = URL.createObjectURL(file);
    image.addEventListener('load', () => {
      callback && callback();
    });
  }
};

const getSize = (file: Blob) =>
  new Promise<{ width: number; height: number }>((resolve) => {
    const image = document.createElement('img');

    loadImage(image, file, () => {
      resolve({
        width: image.width,
        height: image.height,
      });
    });
  });

const scale = (file: Blob, maxSize: number) =>
  new Promise<Blob>((resolve, reject) => {
    if (!hasToBlobSupport) {
      resolve(file);

      return;
    }

    const image = document.createElement('img');

    image.onload = () => {
      const { width, height } = image;

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');

      ctx && ctx.drawImage(image, 0, 0, width, height);

      if (hasToBlobSupport) {
        canvas.toBlob(
          (blob) => {
            canvas.remove();
            image.remove();

            if (blob) {
              if (blob.size > maxSize) {
                scale(blob, maxSize).then(resolve).catch(reject);
                return;
              }

              resolve(blob);
            }
          },
          'image/jpeg',
          0.77
        );
      }
    };
    loadImage(image, file);
  });

const resize = (
  file: File,
  maxDimensions: { width: number; height: number } | (() => { width: number; height: number })
) =>
  new Promise<Blob>((resolve, reject) => {
    const preferDimensions: { width: number; height: number } =
      typeof maxDimensions === 'function' ? maxDimensions() : maxDimensions;

    if (!isSupported() || !file.type.match(/image.*/)) {
      reject();
      return;
    }

    if (file.type.match(/image\/gif/)) {
      // Not attempting, could be an animated gif
      reject();
      // TODO: use https://github.com/antimatter15/whammy to convert gif to webm
      return;
    }

    const image = document.createElement('img');

    image.onload = () => {
      let { width, height } = image;
      let isTooLarge = false;

      if (width >= height && width > preferDimensions.width) {
        // width is the largest dimension, and it's too big.
        height *= preferDimensions.width / width;
        ({ width } = preferDimensions);
        isTooLarge = true;
      } else if (height > preferDimensions.height) {
        // either width wasn't over-size or height is the largest dimension
        // and the height is over-size
        width *= preferDimensions.height / height;
        ({ height } = preferDimensions);
        isTooLarge = true;
      }

      if (!isTooLarge) {
        // early exit; no need to resize
        resolve(file);
        return;
      }

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');
      ctx && ctx.drawImage(image, 0, 0, width, height);

      if (hasToBlobSupport) {
        canvas.toBlob((blob) => {
          image.remove();
          canvas.remove();
          blob && resolve(blob);
        }, file.type);
      } else {
        const blob = toBlob(canvas, file.type);
        image.remove();
        canvas.remove();
        blob && resolve(blob);
      }
    };

    loadImage(image, file);
  });

export default {
  resize,
  scale,
  getSize,
  toBlob,
};
