import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Card, CardBody, Modal } from 'reactstrap';
import chainsureAction from '../../../../actions/chainsure.action';
import GiftAvailable from '../../../../components/ChainsureInfo/GiftAvailable/GiftAvailable';
import ChainsureInfoHistories from '../../../../components/ChainsureInfo/Histories/ChainsureInfoHistories';
import ChainsureInfoModal from '../../../../components/ChainsureInfo/Modal/ChainsureInfoModal';
import Hud, { HudType } from '../../../../components/Hud/Hud';
import ModalBodyWrapper from '../../../../components/Modal/ModalBodyWrapper/ModalBodyWrapper';
import ModalClose from '../../../../components/Modal/ModalClose/ModalClose';
import UserPhoto from '../../../../components/UserPhoto/UserPhoto';
import { ReduxState } from '../../../../reducers/types';
import datetimeFormat from '../../../../utils/datetime/datetimeFormat';
import { i18nTranslator } from '../../../../utils/i18n';
import productTypeSelect from '../../../../utils/productTypeSelect';
import queryString from '../../../../utils/queryString';
import OwnedChainsureRequest from '../Request/OwnedChainsureRequest';
import './OwnedChainsureDetailModal.scss';

const mapStateToProps = ({ owned: { chainsure, chainsureError, chainsureId, action } }: ReduxState) => ({
  chainsure,
  chainsureError,
  chainsureId,
  action,
});

type Props = { onClose: () => void } & ReturnType<typeof mapStateToProps> & {
    selectOwned: typeof chainsureAction.selectOwned;
    getOwnedChainsureDetail: typeof chainsureAction.getOwnedChainsureDetail;
    toggleOwnedRequest: typeof chainsureAction.toggleOwnedRequest;
  } & Pick<
    RouteComponentProps<{
      chainsureId: string;
    }>,
    'location'
  >;

type State = {};

class OwnedChainsureDetailModal extends PureComponent<Props, State> {
  refreshTimeOut: any = null;

  componentDidMount() {
    const { chainsure, chainsureId, getOwnedChainsureDetail, location } = this.props;

    if (location.search) {
      const qss = queryString.parse(location.search);

      if (qss.paymentId && qss.token && qss.PayerID) {
        return;
      }
    }

    if (chainsureId) {
      if (!chainsure) {
        getOwnedChainsureDetail(chainsureId);
      }
    }

    this.setupTimeoutForUpdateStatus();
  }

  componentDidUpdate(prevProps: Props) {
    const { chainsure, chainsureId, getOwnedChainsureDetail } = this.props;

    if (prevProps.chainsureId !== chainsureId) {
      if (chainsureId && !chainsure) {
        getOwnedChainsureDetail(chainsureId);

        return;
      }
    }

    this.setupTimeoutForUpdateStatus();
  }

  componentWillUnmount() {
    clearTimeout(this.refreshTimeOut);
  }

  setupTimeoutForUpdateStatus = () => {
    const { chainsure } = this.props;
    const now = new Date();

    clearTimeout(this.refreshTimeOut);

    if (!chainsure) return;

    const status = chainsureAction.getUseStatus(chainsure);

    switch (status) {
      case 'willuse':
        this.refreshTimeOut = setTimeout(() => {
          this.forceUpdate();

          if (!chainsure.histories || chainsure.histories.length === 0) {
            // case gift has just been unwrapped
            const { getOwnedChainsureDetail, chainsureId } = this.props;

            chainsureId && getOwnedChainsureDetail(chainsureId);
          }
        }, +datetimeFormat.timestampToDate(chainsure.startDate) - +now);
        break;
      case 'using':
        this.refreshTimeOut = setTimeout(
          () => this.forceUpdate(),
          +datetimeFormat.timestampToDate(chainsure.endDate) - +now
        );
        break;
      default:
        break;
    }
  };

  renderOwner = () => {
    const { chainsure } = this.props;

    if (!chainsure) {
      return null;
    }

    const { user } = chainsure;

    return (
      <ModalBodyWrapper>
        <Card className="border-primary">
          <CardBody>
            <div className="font-weight-bold">{i18nTranslator('CHAINSURE_OWNER')}</div>
            <div className="owner-info w-100 d-flex align-items-center mt-1">
              <div className="owner-pic-container">
                <UserPhoto className="owner-pic" photoURL={user.photoURL} />
              </div>
              <div className="owner-details text-truncate">{user.displayName}</div>
            </div>
          </CardBody>
        </Card>
      </ModalBodyWrapper>
    );
  };

  toggleOwnedRequest = () => {
    const { toggleOwnedRequest } = this.props;

    toggleOwnedRequest();
  };

  renderStateDesc = () => {
    const { chainsure } = this.props;

    if (!chainsure) return null;

    const useStatus = chainsureAction.getUseStatus(chainsure);
    let stateDescKey: string = '';

    switch (useStatus) {
      case 'used': {
        if (chainsure.status === 'pay_waiting') {
          // state JOINED_REDEEMED
          stateDescKey = productTypeSelect(
            chainsure.product.type,
            {
              money: 'JOINED_STATE_DESC_REDEEMED_MONEY',
              good_mood: 'JOINED_STATE_DESC_REDEEMED_GOOD_MOOD',
              insurance: 'JOINED_STATE_DESC_REDEEMED_INSURANCE',
            },
            chainsure.isImmediate
          );
        } else if (chainsure.status === 'paid') {
          // state JOINED_PAID
          stateDescKey = productTypeSelect(
            chainsure.product.type,
            {
              money: 'JOINED_STATE_DESC_PAID_MONEY',
              good_mood: 'JOINED_STATE_DESC_PAID_GOOD_MOOD',
              insurance: 'JOINED_STATE_DESC_PAID_INSURANCE',
            },
            chainsure.isImmediate
          );
        } else if (chainsure.status === 'released') {
          // state JOINED_RELEASED
          stateDescKey = productTypeSelect(
            chainsure.product.type,
            {
              free: 'JOINED_STATE_DESC_RELEASED_FREE',
              money: 'JOINED_STATE_DESC_RELEASED_MONEY',
              money_immediate: 'JOINED_STATE_DESC_RELEASED_MONEY_IMMEDIATE',
              good_mood: 'JOINED_STATE_DESC_RELEASED_GOOD_MOOD',
              insurance: 'JOINED_STATE_DESC_RELEASED_INSURANCE',
            },
            chainsure.isImmediate
          );
        } else if (chainsure.product.type === 'free' || (chainsure.product.type === 'money' && chainsure.isImmediate)) {
          // state JOINED_ACCEPTED (Free/Money Immediate)
          stateDescKey = productTypeSelect(
            chainsure.product.type,
            {
              free: 'JOINED_STATE_DESC_ACCEPTED_FREE',
              money_immediate: 'JOINED_STATE_DESC_ACCEPTED_MONEY_IMMEDIATE',
            },
            chainsure.isImmediate
          );
        } else {
          // state JOINED_FINISHED
          stateDescKey = productTypeSelect(chainsure.product.type, {
            money: 'JOINED_STATE_DESC_FINISHED_MONEY',
            good_mood: 'JOINED_STATE_DESC_FINISHED_GOOD_MOOD',
            insurance: 'JOINED_STATE_DESC_FINISHED_INSURANCE',
          });
        }
        break;
      }
      case 'using': {
        // state JOINED_STARTED
        stateDescKey = productTypeSelect(chainsure.product.type, {
          money: 'JOINED_STATE_DESC_STARTED_MONEY',
          good_mood: 'JOINED_STATE_DESC_STARTED_GOOD_MOOD',
          insurance: 'JOINED_STATE_DESC_STARTED_INSURANCE',
        });

        break;
      }
      case 'willuse': {
        if (chainsure.giftSent) {
          // State JOINED_OFFERED
          stateDescKey = productTypeSelect(
            chainsure.product.type,
            {
              free: 'JOINED_STATE_DESC_OFFERED_FREE',
              money: 'JOINED_STATE_DESC_OFFERED_MONEY',
              money_immediate: 'JOINED_STATE_DESC_OFFERED_MONEY_IMMEDIATE',
              good_mood: 'JOINED_STATE_DESC_OFFERED_GOOD_MOOD',
              insurance: 'JOINED_STATE_DESC_OFFERED_INSURANCE',
            },
            chainsure.isImmediate
          );
        } else if (!chainsure.giftAcceptedFrom) {
          // State BOUGHT
          stateDescKey = productTypeSelect(
            chainsure.product.type,
            {
              free: 'JOINED_STATE_DESC_BOUGHT_FREE',
              money: 'JOINED_STATE_DESC_BOUGHT_MONEY',
              money_immediate: 'JOINED_STATE_DESC_BOUGHT_MONEY_IMMEDIATE',
              good_mood: 'JOINED_STATE_DESC_BOUGHT_GOOD_MOOD',
              insurance: 'JOINED_STATE_DESC_BOUGHT_INSURANCE',
            },
            chainsure.isImmediate
          );
        } else {
          // State JOINED_ACCEPTED (Money, Good Mood, Insrance)
          stateDescKey = productTypeSelect(
            chainsure.product.type,
            {
              money: 'JOINED_STATE_DESC_ACCEPTED_MONEY',
              good_mood: 'JOINED_STATE_DESC_ACCEPTED_GOOD_MOOD',
              insurance: 'JOINED_STATE_DESC_ACCEPTED_INSURANCE',
            },
            chainsure.isImmediate
          );
        }

        break;
      }
      default:
        break;
    }

    if (
      chainsure.product.type === 'insurance' &&
      (useStatus === 'willuse' || (useStatus === 'used' && chainsure.status === 'released'))
    ) {
      // already show in action
      return null;
    }

    return (
      <ModalBodyWrapper wrapInCard>
        <div className="state-desc">{i18nTranslator(stateDescKey)}</div>
      </ModalBodyWrapper>
    );
  };

  renderAction = () => {
    const { chainsure, action } = this.props;

    if (chainsure == null || chainsure.product.type !== 'insurance') {
      return null;
    }

    const { status, requested } = chainsure;
    const useStatus = chainsureAction.getUseStatus(chainsure);

    if (useStatus !== 'willuse' && (useStatus !== 'used' || status !== 'released')) {
      return null;
    }

    const stateDescKey = chainsure.requested ? 'STATE_DESC_REQUESTED_INSURANCE' : 'STATE_DESC_AVAILABLE_INSURANCE';

    return (
      <>
        <OwnedChainsureRequest />
        {!action && (
          <ModalBodyWrapper wrapInCard>
            <div className="state-desc">{i18nTranslator(stateDescKey)}</div>

            <Button type="button" color="primary" className="w-100" onClick={this.toggleOwnedRequest}>
              {i18nTranslator(requested ? 'CHAINSURE_ACTION_UPDATE_REQUEST' : 'CHAINSURE_ACTION_REQUEST')}
            </Button>
          </ModalBodyWrapper>
        )}
      </>
    );
  };

  onClose = () => {
    const { onClose } = this.props;

    onClose();
  };

  successErrorAlertClose = () => {
    const { chainsureId, selectOwned, getOwnedChainsureDetail, chainsureError, onClose } = this.props;

    if (chainsureError) {
      onClose();

      return;
    }

    selectOwned(chainsureId);
    chainsureId && getOwnedChainsureDetail(chainsureId);
  };

  renderTopComponent = () => {
    const { chainsureId, chainsure } = this.props;

    if (chainsureId == null || chainsure == null) {
      return null;
    }

    const { status } = chainsure;
    const useStatus = chainsureAction.getUseStatus(chainsure);

    if (useStatus !== 'willuse' && (useStatus !== 'used' || status !== 'released')) {
      return null;
    }

    return (
      <GiftAvailable
        requestSent={chainsure.requested}
        className="on-modal"
        tooltipPlacement="right"
        chainsureId={chainsure.id}
      />
    );
  };

  render() {
    const { chainsureId, chainsure, chainsureError } = this.props;

    const showingDetail = chainsureId != null && chainsure != null;

    let hudType: HudType | null = null;
    let hudAlertBtnTitle: string | null = null;
    let hudAlertTitle: string | null = null;
    let hudAlertDesc: string | null = null;

    if (chainsureId != null && chainsure == null && !chainsureError) {
      hudType = 'loading';
    }

    if (chainsureError) {
      hudType = 'alert';
      hudAlertBtnTitle = i18nTranslator('ALERT_OK');
      hudAlertTitle = i18nTranslator('ALERT_ERROR');

      switch (chainsureError.code) {
        case 'WRONG_PERMISSION':
        case 'CHAINSURE_NOT_FOUND':
          hudAlertDesc = i18nTranslator('CHAINSURE_ACTION_ERROR_WRONG_PERMISSION');
          break;
        default:
          hudAlertDesc = chainsureError.code;
          break;
      }
    }

    return (
      <>
        <Hud
          type={hudType}
          isOpen={hudType != null}
          alertBtnTitle={hudAlertBtnTitle}
          alertType="error"
          alertTitle={hudAlertTitle}
          alertDesc={hudAlertDesc}
          toastMessage={hudAlertDesc}
          toastTimeout={1000}
          toggle={this.successErrorAlertClose}
        />
        {showingDetail && (
          <Modal
            isOpen={chainsureId != null}
            toggle={this.onClose}
            centered
            modalClassName="modal-product-detail modal-owned-detail"
          >
            <ModalClose onClick={this.onClose} />
            {this.renderTopComponent()}
            {chainsure && <ChainsureInfoModal chainsure={chainsure} hideBuyAgain />}
            {this.renderOwner()}
            {this.renderStateDesc()}
            {this.renderAction()}
            <ChainsureInfoHistories />
          </Modal>
        )}
      </>
    );
  }
}
export default connect(mapStateToProps, {
  selectOwned: chainsureAction.selectOwned,
  getOwnedChainsureDetail: chainsureAction.getOwnedChainsureDetail,
  toggleOwnedRequest: chainsureAction.toggleOwnedRequest,
})(OwnedChainsureDetailModal);
