import axios from 'axios';
import config from './config';
import { currLanguage } from './i18n';

let appAuthToken: string | null = null;

const setAppAuthToken = (token: string | null) => {
  appAuthToken = token;
};

const app = axios.create({
  baseURL: config.host.rootApi,
  headers: {
    crossDomain: true,
    'Content-Type': 'application/json',
    'Accept-Language': navigator.languages.join(','),
  },
  transformRequest: [
    (data, headers: { [id: string]: string }) => {
      if (!headers.Authorization && appAuthToken) {
        headers.Authorization = `Bearer ${appAuthToken || ''}`;
      }

      headers['X-Language'] = currLanguage();

      if (headers['Content-Type'] !== 'application/json') {
        return data;
      }

      return JSON.stringify(data);
    },
  ],
});

app.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      debugger;
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

const pages = axios.create({
  baseURL: config.host.rootPages,
  headers: {
    common: {
      crossDomain: true,
    },
  },
});

const freeCurrenciesConvert = axios.create({
  baseURL: config.freeCurrencyConverter.rootApi,
});

const cloudinary = axios.create({
  baseURL: config.cloudinary.rootApi,
});

export default {
  setAppAuthToken,
  app,
  pages,
  freeCurrenciesConvert,
  cloudinary,
};
