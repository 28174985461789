import React, { Component } from 'react';
import classesNames from './LoadingIndicator.module.scss';
import loadingIcon from '../../assets/loading.svg';

type Props = { className?: string; size?: number };

class LoadingIndicator extends Component<Props> {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { className, size } = this.props;

    return (
      <img
        src={loadingIcon}
        style={{
          width: size || 50,
          height: size || 50,
          display: 'block',
          margin: 'auto',
        }}
        alt=""
        className={`${classesNames['loading-indicator']} ${className || ''}`}
      />
    );
  }
}

export default LoadingIndicator;
