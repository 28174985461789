import React, { PureComponent, SyntheticEvent } from 'react';
import { Row, Col, Button, Form, FormGroup } from 'reactstrap';
import DateTimeInput from '../../DateTimeInput/DateTimeInput';
import { i18nTranslator } from '../../../utils/i18n';
import PhotoUploader from '../../Photo/PhotoUploader/PhotoUploader';
import ProductInfoActionContainer from '../ActionContainer/ProductInfoActionContainer';
import { ProductType } from '../../../types';
import Quoteditable from '../../Quoteditable/Quoteditable';
import draftService from '../../../utils/draftService';

type Props = {
  productId: string;
  chainsureId?: string | null;
  titleKey: string;
  descKey: string;
  productType: ProductType;
  minTime?: number;
  maxMedia: number;
  initComment?: string | null;
  onSubmit: (startDate: Date, endDate: Date, comment: string, mediaIds: string[], isImmediate: boolean) => void;
  btnContent?: React.ReactNode | null;
  btnClassName?: string;
  onClose?: () => any;
};

type State = {
  startDate: Date;
  endDate: Date;
  isImmediate: boolean;
  invalidDate: boolean;
  comment: string;
  mediaRequired: boolean;
};

class ProductBuyingForm extends PureComponent<Props, State> {
  static BuildDraftKey = (productId?: string, chainsureId?: string | null) => {
    if (chainsureId) {
      return `ProductBuyingForm_pId_${undefined}_cId_${chainsureId}`;
    }

    return `ProductBuyingForm_pId_${productId}_cId_${undefined}`;
  };

  files: File[] = [];

  photoUploaderRef: PhotoUploader | null = null;

  quoteditableRef: Quoteditable | null = null;

  shouldSaveDraft = true;

  constructor(props: Props, context: any) {
    super(props, context);

    const now = new Date().getTime();
    const nextHour = new Date(now + (3600000 - (now % 360000)));
    const startDate = new Date(nextHour.getFullYear(), nextHour.getMonth(), nextHour.getDate(), nextHour.getHours(), 0);
    const endDate = new Date(startDate.getTime() + 2 * 3600000);

    this.state = {
      startDate,
      endDate,
      isImmediate: props.productType === 'money',
      invalidDate: false,
      comment: props.initComment || '',
      mediaRequired: false,
    };
  }

  async componentDidMount() {
    const draft = await draftService.get<State>(this.baseDraftKey);

    if (draft) {
      const { startDate } = draft;

      if (startDate < new Date()) {
        delete draft.startDate;
        delete draft.endDate;
      }

      setImmediate(() => {
        this.setState(draft);
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { initComment } = this.props;
    const { comment } = this.state;

    if (initComment && !prevProps.initComment && !comment) {
      setImmediate(() => {
        this.setState({
          comment: initComment,
        });
      });
    }
  }

  async componentWillUnmount() {
    const { isImmediate, startDate, endDate, comment } = this.state;

    if (this.shouldSaveDraft) {
      await draftService.set<Partial<State>>(this.baseDraftKey, {
        isImmediate,
        startDate,
        endDate,
        comment,
      });
    }
  }

  get baseDraftKey() {
    const { productId, chainsureId } = this.props;

    return ProductBuyingForm.BuildDraftKey(productId, chainsureId);
  }

  discardDraft = async () => {
    this.shouldSaveDraft = false;

    await draftService.remove(this.baseDraftKey);

    if (this.quoteditableRef) {
      await this.quoteditableRef.discardDraft();
    }

    if (this.photoUploaderRef) {
      await this.photoUploaderRef.discardDraft();
    }
  };

  onSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { onSubmit, productType, minTime } = this.props;
    const { startDate, endDate, isImmediate } = this.state;

    if (productType === 'free' && this.files.length === 0) {
      this.setState({
        mediaRequired: true,
      });

      return;
    }

    if (productType !== 'free') {
      if (!isImmediate && (startDate >= endDate || startDate < new Date())) {
        this.setState({
          invalidDate: true,
        });

        return;
      }

      if (productType === 'insurance') {
        if (this.files.length === 0) {
          this.setState({
            mediaRequired: true,
          });

          return;
        }

        if (endDate.getTime() - startDate.getTime() < (minTime || 1) * 60000) {
          this.setState({
            invalidDate: true,
          });

          return;
        }
      }
    }

    let mediaIds: string[] = [];

    if (this.photoUploaderRef && this.files.length > 0) {
      mediaIds = await this.photoUploaderRef.upload();

      if (mediaIds.length === 0) {
        // upload is cancelled
        return;
      }
    }

    const comment = (this.quoteditableRef && this.quoteditableRef.getEditingContent()) || '';

    onSubmit(startDate, endDate, comment, mediaIds, isImmediate);
  };

  renderIssueMessage = () => {
    const { invalidDate, mediaRequired } = this.state;

    let message: string | null = null;

    if (invalidDate) {
      message = i18nTranslator('BUYING_FORM_INVALID');
    } else if (mediaRequired) {
      message = i18nTranslator('BUYING_FORM_INVALID_ZERO_MEDIA');
    }

    if (!message) return null;

    return (
      <Row form>
        <div className="text-center invalid-feedback dark d-block ml-0 mb-2">{message}</div>
      </Row>
    );
  };

  renderForm = () => {
    const { productType } = this.props;
    const { startDate, endDate, isImmediate } = this.state;

    if (productType === 'free') {
      return null;
    }

    return (
      <>
        {productType === 'money' && (
          <Row>
            <Col>
              <FormGroup className="custom-control custom-checkbox text-center">
                <input
                  className="custom-control-input"
                  type="checkbox"
                  id="immediateStartEnd"
                  name="immediateStartEnd"
                  checked={isImmediate}
                  onChange={(event) => {
                    this.setState({
                      isImmediate: event.currentTarget.checked,
                    });
                  }}
                />
                <label className="custom-control-label text-primary" htmlFor="immediateStartEnd">
                  {i18nTranslator('BUYING_FORM_IMMEDIATE_TIME')}
                </label>
              </FormGroup>
            </Col>
          </Row>
        )}

        {!isImmediate && (
          <>
            <Row form>
              <Col>
                <DateTimeInput
                  name="startDate"
                  label={i18nTranslator('BUYING_FORM_START')}
                  value={startDate}
                  onChange={(date) => {
                    this.setState({
                      startDate: date,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row form>
              <Col>
                <DateTimeInput
                  name="endDate"
                  label={i18nTranslator('BUYING_FORM_END')}
                  value={endDate}
                  onChange={(date) => {
                    this.setState({
                      endDate: date,
                    });
                  }}
                />
              </Col>
            </Row>
          </>
        )}
      </>
    );
  };

  render() {
    const { btnContent, btnClassName, titleKey, descKey, onClose, maxMedia } = this.props;
    const { comment } = this.state;

    return (
      <ProductInfoActionContainer
        icon="box"
        title={i18nTranslator(titleKey)}
        description={i18nTranslator(descKey)}
        onClose={onClose}
      >
        <Form onSubmit={this.onSubmit}>
          {this.renderForm()}
          <Row form>
            <Col>
              <Quoteditable
                draftKey={this.baseDraftKey}
                content={comment}
                label={i18nTranslator('BUYING_FORM_COMMENT')}
                onChange={(text) => {
                  this.setState({
                    comment: text,
                  });
                }}
                ref={(ref) => {
                  this.quoteditableRef = ref;
                }}
              />
            </Col>
          </Row>
          <Row form>
            <Col>
              <div className="w-100">
                <PhotoUploader
                  draftKey={this.baseDraftKey}
                  label={i18nTranslator('BUYING_FORM_MEDIA_DESC')}
                  maxFile={maxMedia}
                  onSelectedFiles={(files) => {
                    this.files = files;
                  }}
                  ref={(ref) => {
                    this.photoUploaderRef = ref;
                  }}
                />
              </div>
            </Col>
          </Row>
          {this.renderIssueMessage()}
          <Row form className="p-1">
            <Button color="primary" className={`w-100 ${btnClassName}`}>
              {btnContent}
            </Button>
          </Row>
        </Form>
      </ProductInfoActionContainer>
    );
  }
}

export default ProductBuyingForm;
