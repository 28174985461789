import React, { PureComponent, SyntheticEvent } from 'react';
import './PhotoUploader.scss';
import Axios, { Canceler } from 'axios';
import FontAwesomeIcon from '../../Icon/FontAwesomeIcon';
import PhotoUploadItem from './PhotoItem/PhotoUploadItem';
import Hud from '../../Hud/Hud';
import mediaService from '../../../utils/mediaService';
import { i18nTranslator } from '../../../utils/i18n';
import draftService from '../../../utils/draftService';

type UploadPhoto = {
  status?: string | null;
  photoId?: string | null;
  photoUrl?: string | null;
  photoData?: any;
  type?: string;
  name: string;
  file: File;
};

type Props = {
  draftKey?: string | null;
  maxFile: number;
  label: string;
  onSelectedFiles: (files: File[]) => void;
};

type State = {
  photos: UploadPhoto[];
  isUploading: boolean;
  isDragOver: boolean;
  uploadFilesSize: number;
  totalFilesSize: number;
  justSelectedInvalidMedia?: boolean;
};

class PhotoUploader extends PureComponent<Props, State> {
  state: State = {
    photos: [],
    uploadFilesSize: 0,
    totalFilesSize: 0,
    isUploading: false,
    isDragOver: false,
  };

  shouldSaveDraft = true;

  dragZoneRef = React.createRef<HTMLDivElement>();

  inputFileRef: HTMLInputElement | null = null;

  uploadIsCancelled = false;

  uploadCanceler: Canceler | null = null;

  pretouchedImages: { [name: string]: Blob } = {};

  async componentDidMount() {
    if (this.dragZoneRef.current) {
      this.dragZoneRef.current.addEventListener(
        'dragover',
        this.dropZoneOnDragOver
      );
      this.dragZoneRef.current.addEventListener(
        'dragenter',
        this.dropZoneOnDragOver
      );

      const dropZone = this.dragZoneRef.current.querySelector('.drop-zone');
      dropZone &&
        dropZone.addEventListener('dragleave', this.dropZoneOnDragLeave);

      this.dragZoneRef.current.addEventListener('drop', this.dropZoneOnDrop);
    }

    const { draftKey } = this.props;

    if (draftKey) {
      const draft = await draftService.get<State>(`PhotoUploader_${draftKey}`);

      if (draft) {
        setImmediate(() => {
          this.setState({
            ...draft,
          });
        });
      }
    }
  }

  componentDidUpdate() {
    const { onSelectedFiles } = this.props;
    const { photos } = this.state;

    onSelectedFiles(photos.map((p) => p.file));
  }

  async componentWillUnmount() {
    if (this.dragZoneRef.current) {
      this.dragZoneRef.current.removeEventListener(
        'dragover',
        this.dropZoneOnDragOver
      );
      this.dragZoneRef.current.removeEventListener(
        'dragenter',
        this.dropZoneOnDragOver
      );

      const dropZone = this.dragZoneRef.current.querySelector('.drop-zone');
      dropZone &&
        dropZone.removeEventListener('dragleave', this.dropZoneOnDragLeave);

      this.dragZoneRef.current.removeEventListener('drop', this.dropZoneOnDrop);
    }

    const { draftKey } = this.props;

    if (draftKey && this.shouldSaveDraft) {
      const { photos } = this.state;

      await draftService.set<Partial<State>>(`PhotoUploader_${draftKey}`, {
        photos,
      });
    }
  }

  discardDraft = async () => {
    const { draftKey } = this.props;

    this.shouldSaveDraft = false;

    await draftService.remove(`PhotoUploader_${draftKey}`);
  };

  dropZoneOnDragOver = (e: DragEvent) => {
    e.preventDefault();

    this.setState({
      isDragOver: true,
    });
  };

  dropZoneOnDragLeave = (e: Event) => {
    e.preventDefault();

    this.setState({
      isDragOver: false,
    });
  };

  dropZoneOnDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!e.dataTransfer) {
      return;
    }

    this.onFilesSelected(e.dataTransfer.files);

    this.setState({
      isDragOver: false,
    });
  };

  onPreTouch = (blob: Blob, name: string) => {
    this.pretouchedImages[name] = blob;
  };

  cancelUploading = () => {
    if (this.uploadCanceler) {
      this.uploadCanceler('cancel_upload');
    }

    this.uploadIsCancelled = true;
  };

  upload = async () => {
    this.uploadIsCancelled = false;

    const { photos } = this.state;

    if (photos.length === 0) return [];

    const totalFilesSize = photos.reduce((sum, { file }) => sum + file.size, 0);

    this.setState({
      isUploading: true,
      totalFilesSize,
    });

    const mediaIds: string[] = [];
    const cancelToken = new Axios.CancelToken((c) => {
      this.uploadCanceler = c;
    });

    for (let i = 0; i < photos.length; i += 1) {
      let file: Blob = photos[i].file;

      if (this.pretouchedImages[photos[i].name]) {
        file = this.pretouchedImages[photos[i].name];
      }

      const mediaId = await mediaService.uploadFile(
        file,
        ({ loaded }) => {
          this.setState(({ totalFilesSize: currentTotallFilesSize }) => ({
            uploadFilesSize: Math.min(
              totalFilesSize,
              currentTotallFilesSize + loaded
            ),
          }));
        },
        cancelToken
      );

      if (mediaId) {
        mediaIds.push(mediaId);
      }
    }

    this.setState({
      isUploading: false,
    });

    return this.uploadIsCancelled ? [] : mediaIds;
  };

  onPhotoUpload = (
    fileName: string,
    status: 'success' | 'error',
    photoId?: string,
    photoUrl?: string,
    photoData?: string,
    type?: 'video' | 'image'
  ) => {
    const { photos } = this.state;

    const idx = photos.findIndex((p) => p.name === fileName);

    if (idx === -1) return;

    const newPhotos = [...photos];
    newPhotos[idx] = {
      ...newPhotos[idx],
      status,
      photoId,
      photoUrl,
      photoData,
      type,
    };

    this.setState({
      photos: newPhotos,
    });
  };

  onFilesSelected = (files: FileList) => {
    this.setState({
      justSelectedInvalidMedia: false,
    });

    const { maxFile } = this.props;
    const { photos } = this.state;

    if (!files || files.length === 0) return;

    const numOfRemainingMedia = maxFile - photos.length;
    const newPhotos: UploadPhoto[] = [];

    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];

      if (photos.find((p) => p.name === file.name)) {
        continue;
      }

      if (!file.type.startsWith('image') && !file.type.startsWith('video')) {
        continue;
      }

      if (file.type.startsWith('video') && file.size > 20971520) {
        this.setState({
          justSelectedInvalidMedia: true,
        });

        continue;
      }

      const newPhoto: UploadPhoto = {
        name: file.name,
        file,
      };

      newPhotos.push(newPhoto);

      if (newPhotos.length === numOfRemainingMedia) {
        break;
      }
    }

    this.setState((state) => ({
      photos: [...state.photos, ...newPhotos],
    }));
  };

  inputOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { files } = event.currentTarget;

    if (!files) {
      return;
    }

    this.onFilesSelected(files);

    if (!this.inputFileRef) {
      return;
    }

    this.inputFileRef.value = '';
    this.inputFileRef.files = null;
  };

  onRemoveItem = (name: string) => {
    const { photos } = this.state;

    delete this.pretouchedImages[name];

    const idx = photos.findIndex((p) => p.name === name);
    if (idx === -1) return;

    const newPhotos = [...photos];
    newPhotos.splice(idx, 1);

    this.setState({
      photos: newPhotos,
    });
  };

  renderPhotos = () => {
    const { photos } = this.state;

    return photos.map<React.ReactNode>((p) => (
      <PhotoUploadItem
        key={p.name}
        {...p}
        onRemove={this.onRemoveItem}
        onPreTouch={this.onPreTouch}
      />
    ));
  };

  render() {
    const { maxFile, label } = this.props;
    const {
      photos,
      isUploading,
      uploadFilesSize,
      totalFilesSize,
      isDragOver,
      justSelectedInvalidMedia,
    } = this.state;

    let loadingMessage: string | null = null;
    let subLoadingMessage: string | null = null;

    if (isUploading) {
      loadingMessage =
        uploadFilesSize !== totalFilesSize
          ? `${((uploadFilesSize * 100) / totalFilesSize).toFixed(0)}%`
          : null;
      subLoadingMessage = loadingMessage
        ? i18nTranslator('MEDIA_UPLOADING')
        : i18nTranslator('MEDIA_PROCESSING');
    }

    const numOfMedia = photos.length;

    return (
      <>
        <Hud
          isOpen={isUploading}
          type="loading"
          loadingMessage={loadingMessage}
          subLoadingMessage={subLoadingMessage}
          loadingOnCancel={this.cancelUploading}
        />
        <div className="justify-content-center text-center text-primary font-weight-bold">
          <div>{label}</div>
          <div>{`(${numOfMedia}/${maxFile})`}</div>
        </div>
        <div ref={this.dragZoneRef}>
          <div className="photo-uploader w-100 d-flex flex-wrap justify-content-center">
            {this.renderPhotos()}
            <div className={numOfMedia === maxFile ? 'd-none' : ''}>
              <input
                type="file"
                name="input-file"
                id="input-file"
                className="input-file"
                accept="video/*,image/*"
                multiple
                onChange={this.inputOnChange}
                ref={(ref) => {
                  this.inputFileRef = ref;
                }}
              />
              <label htmlFor="input-file">
                <FontAwesomeIcon icon="plus" size="2x" />
              </label>
            </div>
            <div
              className={`drop-hint-container ${
                isDragOver ? 'is-dragover' : ''
              } drop-zone`}
            >
              {i18nTranslator('MEDIA_UPLOAD_DROP_ZONE')}
            </div>
          </div>
        </div>
        {justSelectedInvalidMedia && (
          <div>
            <div className="text-center invalid-feedback dark d-block ml-0 mb-2">
              {i18nTranslator('MEDIA_INVALID')}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default PhotoUploader;
