import React, { PureComponent } from 'react';
import './DamageListItem.scss';
import { Damage } from '../../../../types';
import DateRangeLabel from '../../../../components/DateRangeLabel/DateRangeLabel';
import ProductInfoListItem from '../../../../components/ProductInfo/ListItem/ProductInfoListItem';
import chainsureAction from '../../../../actions/chainsure.action';
import productTypeSelect from '../../../../utils/productTypeSelect';
import { WithNamespaces } from 'react-i18next';

type Props = {
  damage: Damage;
} & Pick<WithNamespaces, 't'>;

class DamageListItem extends PureComponent<Props> {
  render() {
    const { damage, t } = this.props;
    const { product } = damage;

    let stateBottomKey = '';

    if (damage.isDeniedInsurance) {
      // state REJECTED REFUNEDED
      stateBottomKey = damage.status === 'pay_waiting' ? 'STATE_REJECTED_INSURANCE' : 'STATE_REFUNEDED_INSURANCE';
    } else {
      // state REDEEMED & PAID
      stateBottomKey =
        damage.status === 'pay_waiting'
          ? productTypeSelect(
              product.type,
              {
                money: 'STATE_REDEEMED_MONEY',
                good_mood: 'STATE_REDEEMED_GOOD_MOOD',
                insurance: 'STATE_REDEEMED_INSURANCE',
              },
              damage.isImmediate
            )
          : productTypeSelect(
              product.type,
              {
                money: 'STATE_PAID_MONEY',
                good_mood: 'STATE_PAID_GOOD_MOOD',
                insurance: 'STATE_PAID_INSURANCE',
              },
              damage.isImmediate
            );
    }

    return (
      <ProductInfoListItem
        linkTo={`/payouts/c/${damage.id}`}
        {...{
          ...product,
          currency: damage.currency,
          price: damage.amount,
        }}
        lastUpdatedTime={damage.createdAt}
        photoURL={chainsureAction.getChainsurePhoto(damage)}
        timeClassName=" align-items-start"
        renderTime={() => (
          <>
            <div className="font-weight-bold ">{t('CHAINSURE_LAST_USE_ON')}</div>
            <DateRangeLabel startDate={damage.startDate} endDate={damage.endDate} />
          </>
        )}
        timeAndPriceContainerClassName="mt-sm-3"
        stateBottomKey={stateBottomKey}
      />
    );
  }
}

export default DamageListItem;
