import React from 'react';
import { NavLink as RRDNavLink } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';

const NavigationItem = (props: {
  to: string;
  exact?: boolean;
  children?: any;
  className?: string;
}) => (
  <NavItem className={props.className}>
    <NavLink tag={RRDNavLink} to={props.to} exact={props.exact}>
      {props.children}
    </NavLink>
  </NavItem>
);

export default NavigationItem;
