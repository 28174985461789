import { combineReducers } from 'redux';
import auth from './auth.reducer';
import products from './products.reducer';
import chainsures from './chainsures.reducer';
import damages from './damages.reducer';
import gift from './gift.reducer';
import dashboard from './dashboard.reducer';
import owned from './owned.reducer';
import histories from './histories.reducer';

export default combineReducers({
  auth,
  products,
  chainsures,
  damages,
  gift,
  dashboard,
  owned,
  histories,
});
