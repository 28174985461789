import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Timestamp, Currency } from '../../../types';
import { i18nTranslator } from '../../../utils/i18n';
import DateRangeLabel from '../../DateRangeLabel/DateRangeLabel';
import Price from '../../Price/Price';
import ModalBodyWrapper from '../../Modal/ModalBodyWrapper/ModalBodyWrapper';

type Props = {
  titleKey: string | null;
  price: number;
  currency: Currency;
  isImmediate?: boolean;
  dateRange?: {
    start: Timestamp;
    end: Timestamp;
  };
};

const ProductInfoPrice = ({ titleKey, price, currency, dateRange, isImmediate }: Props) => {
  let leftPart: any = null;

  if (dateRange) {
    leftPart = (
      <>
        <div className="font-weight-bold">{i18nTranslator(titleKey)}</div>
        <DateRangeLabel
          startDate={dateRange.start}
          endDate={dateRange.end}
          textClassname="product-date"
          labelOnly={isImmediate ? i18nTranslator('CHAINSURE_USE_ON_IMMEDIATE') : null}
        />
      </>
    );
  } else {
    leftPart = i18nTranslator(titleKey);
  }

  return (
    <ModalBodyWrapper>
      <Card className="border-primary">
        <CardBody className="d-flex justify-content-between align-items-center">
          <div>{leftPart}</div>

          <div>
            <Price price={price} currency={currency} />
          </div>
        </CardBody>
      </Card>
    </ModalBodyWrapper>
  );
};

export default ProductInfoPrice;
