import React from 'react';

const buildClickableText = (content: string, textClassName: string, clickableClassname: string) => {
  const urlRegex = /((?:https?|ftp):\/\/)?((?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9][\-_]*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9][\-_]*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(:\d{2,5})?([$/][^$?#\s]*)?([$/?#]\S*)?/gim;

  const children: any[] = [];

  let result = urlRegex.exec(content);

  if (result != null) {
    let index = 0;

    while (result != null) {
      const protocol = result[1] ? '' : 'http://';
      const url = protocol + result[0];
      const urlLength = result[0].length;
      let displayLink = result[0];

      if (urlLength > 50) {
        displayLink = (result[1] || '') + result[2] + (result[3] || '');

        if (displayLink.length > 50) {
          displayLink = `${displayLink.substr(0, 47)}...`;
        } else if (typeof result[4] !== 'undefined' && result[4] != '/') {
          const paths = result[4].split('/').filter((value) => value !== '');

          if (paths.length === 1) {
            displayLink += `/${
              paths[0].length > 9 ? `${paths[0].substr(0, 3)}...${paths[0].substr(paths[0].length - 4, 3)}` : paths[0]
            }`;
          } else if (paths.length > 1) {
            displayLink += `/${paths[0].length > 6 ? `${paths[0].substr(0, 3)}...` : paths[0]}`;

            if (paths.length > 2) {
              displayLink += '/...';
            }

            displayLink += `/${
              paths[paths.length - 1].length > 6
                ? `...${paths[paths.length - 1].substr(paths[paths.length - 1].length - 4, 3)}`
                : paths[paths.length - 1]
            }`;
          }

          if (typeof result[5] !== 'undefined') {
            displayLink += '/...';
          }
        } else {
          displayLink += '/...';
        }
      }

      children.push(<span key={`normal-${children.length}`}>{content.substring(index, result.index)}</span>);
      children.push(
        <a
          className={clickableClassname}
          href={url}
          key={`clickable-${children.length}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {displayLink}
        </a>
      );

      index = result.index + urlLength;

      result = urlRegex.exec(content);
    }

    children.push(<span key={`normal-${children.length}`}>{content.substring(index)}</span>);
  } else {
    children.push(<span key={`normal-${children.length}`}>{content}</span>);
  }

  return <span className={textClassName}>{children}</span>;
};

export default buildClickableText;
