import React, { PureComponent, SyntheticEvent } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Axios, { Canceler } from 'axios';
import { connect } from 'react-redux';
import { Button, Popover, PopoverBody, ListGroup, ListGroupItem, Row, Col, FormGroup } from 'reactstrap';
import { UserSuggest } from '../../../../../types';
import './ChainsureSendGift.scss';
import isEmail from '../../../../../utils/validations/isEmail';
import TextField from '../../../../../components/TextField/TextField';
import UserPhoto from '../../../../../components/UserPhoto/UserPhoto';
import authActions from '../../../../../actions/auth.actions';
import { ReduxState } from '../../../../../reducers/types';
import giftActions from '../../../../../actions/gift.actions';
import Hud, { HudType } from '../../../../../components/Hud/Hud';
import { i18nTranslator } from '../../../../../utils/i18n';
import LoadingIndicator from '../../../../../components/Icon/LoadingIndicator';
import FontAwesomeIcon from '../../../../../components/Icon/FontAwesomeIcon';
import draftService from '../../../../../utils/draftService';

const mapStateToProps = ({
  chainsures: { chainsureId, giftCode, giftSendStatus, giftSendError, giftSent },
  auth: { username },
}: ReduxState) => ({
  giftCode,
  chainsureId,
  giftSendStatus,
  giftSendError,
  giftSent,
  currUsername: username,
});

type Props = RouteComponentProps &
  ReturnType<typeof mapStateToProps> & {
    toggleTagUser: () => void;
  } & {
    sentGiftToUser: typeof giftActions.sentGiftToUser;
    resetSendForm: typeof giftActions.resetSendForm;
  };

type State = {
  tagValue: string;
  comment: string;
  isValid: boolean;
  tagMode: 'email' | 'username';
  isFocusing: boolean;
  isSearching: boolean;
  suggestions: UserSuggest[];
  hideSuggestUntilNext: boolean;
  withoutOptionalMessage: boolean;
};

const INIT_STATE: State = {
  tagMode: 'username',
  tagValue: '',
  comment: '',
  isValid: false,
  isFocusing: false,
  isSearching: false,
  suggestions: [],
  hideSuggestUntilNext: false,
  withoutOptionalMessage: true,
};

class ChainsureSendGift extends PureComponent<Props, State> {
  state: State = INIT_STATE;

  timeoutForValidation: any = null;

  userTagRef: HTMLInputElement | null = null;

  searchCanceler: Canceler | null = null;

  shouldSaveDraft = true;

  async componentDidMount() {
    const { chainsureId, giftCode } = this.props;

    if (chainsureId && giftCode) {
      const draft = await draftService.get<State>(`ChainsureSendGift_cId_${chainsureId}_gc_${giftCode}`);

      if (draft) {
        setImmediate(() => {
          this.setState(
            {
              ...draft,
            },
            this.setTimeoutForValidation
          );
        });
      }
    }
  }

  async componentWillUnmount() {
    const { chainsureId, giftCode } = this.props;

    if (chainsureId && giftCode && this.shouldSaveDraft) {
      const { tagValue, comment, withoutOptionalMessage } = this.state;

      await draftService.set<Partial<State>>(`ChainsureSendGift_cId_${chainsureId}_gc_${giftCode}`, {
        tagValue,
        comment,
        withoutOptionalMessage,
      });
    }
  }

  setTimeoutForValidation = () => {
    clearTimeout(this.timeoutForValidation);

    this.timeoutForValidation = setTimeout(async () => {
      const { currUsername } = this.props;
      const { tagValue } = this.state;

      if (tagValue) {
        if (isEmail(tagValue.trim())) {
          this.setState({
            isValid: true,
            tagMode: 'email',
          });

          return;
        }

        const query = tagValue.trim().replace(/^@+/i, '');

        if (!/[^\w]/g.test(query)) {
          this.setState({
            tagMode: 'username',
            isValid: query !== currUsername,
            isSearching: true,
          });

          if (this.searchCanceler) {
            this.searchCanceler('');
          }

          try {
            const { data: searchResponseData } = await authActions.searchUsername(
              query,
              3,
              new Axios.CancelToken((c) => {
                this.searchCanceler = c;
              })
            );

            this.setState({
              tagMode: 'username',
              suggestions: searchResponseData,
              isSearching: false,
            });
          } catch (error) {
            console.log(error);
          }

          return;
        }
      }

      this.setState({
        tagMode: 'username',
        suggestions: [],
      });
    }, 500);
  };

  tagOnFocus = () => {
    this.setState({
      isFocusing: true,
      hideSuggestUntilNext: false,
    });
  };

  tagOnBlur = () => {
    this.setState({
      isFocusing: false,
    });
  };

  tagOnChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState(
      {
        tagValue: e.currentTarget.value,
        isValid: false,
      },
      this.setTimeoutForValidation
    );
  };

  tagOnSelectUsername = (username: string) => {
    this.setState(
      {
        tagValue: `@${username}`,
        isValid: true,
        hideSuggestUntilNext: true,
      },
      this.setTimeoutForValidation
    );
  };

  renderSuggestions = () => {
    const { suggestions } = this.state;

    return (
      <ListGroup className="suggestions">
        {suggestions.map(({ uid, photoURL, displayName, username }: UserSuggest) => (
          <ListGroupItem
            key={uid}
            className="suggestion"
            tag="button"
            onClick={() => this.tagOnSelectUsername(username)}
          >
            <div>
              <UserPhoto photoURL={photoURL} />
            </div>
            <div className="detail d-flex-column align-items-start justify-content-center ml-2 flex-grow-1">
              <div className="font-weight-bold">{displayName}</div>
              <div className="small">{`@${username}`}</div>
            </div>
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };

  closePopover = () => {
    const { isFocusing } = this.state;

    if (!isFocusing) {
      this.setState({
        hideSuggestUntilNext: true,
      });
    }
  };

  discardDraft = async () => {
    const { chainsureId, giftCode } = this.props;

    this.shouldSaveDraft = false;

    await draftService.remove(`ChainsureSendGift_cId_${chainsureId}_gc_${giftCode}`);
  };

  onSubmit = async () => {
    const { chainsureId, giftCode, sentGiftToUser } = this.props;
    const { tagValue, comment, withoutOptionalMessage } = this.state;

    if (!chainsureId || !giftCode) {
      return;
    }

    sentGiftToUser(chainsureId, giftCode, tagValue, withoutOptionalMessage ? '' : comment);
  };

  closeAlert = async () => {
    const { giftSendStatus, resetSendForm, history } = this.props;

    if (giftSendStatus === 'success') {
      await this.discardDraft();

      this.setState({
        ...INIT_STATE,
      });

      history.push('/inventories');
    }

    resetSendForm();
  };

  render() {
    const { toggleTagUser, giftSendStatus, giftSendError, giftSent } = this.props;
    const {
      tagValue,
      comment,
      isValid,
      tagMode,
      suggestions,
      hideSuggestUntilNext,
      isSearching,
      withoutOptionalMessage,
    } = this.state;

    const showingPopover =
      giftSendStatus !== 'sending' && tagMode === 'username' && !hideSuggestUntilNext && suggestions.length > 0;

    const hudIsOpen = giftSendStatus != null;

    let hudType: HudType | null = null;
    let alertBtnTitle: string | null = null;
    let alertTitle: string | null = null;
    let alertDesc: string | null = null;

    if (hudIsOpen) {
      switch (giftSendStatus) {
        case 'error':
          hudType = 'alert';
          alertBtnTitle = i18nTranslator('ALERT_OK');
          alertTitle = i18nTranslator('ALERT_ERROR');

          switch (giftSendError?.code) {
            case 'WRONG_PERMISSION':
              alertDesc = i18nTranslator('CHAINSURE_ACTION_ERROR_WRONG_PERMISSION');
              break;
            case 'USER_NOT_FOUND':
              alertDesc = i18nTranslator('SEND_GIFT_ERR_USER').replace(
                /\[USERNAME\]/g,
                `@${tagValue.replace(/^@+/g, '')}`
              );
              break;
            default:
              alertDesc = i18nTranslator('SEND_GIFT_ERR');
              break;
          }
          break;
        case 'success':
          hudType = 'toast';
          alertDesc = i18nTranslator('SEND_GIFT_SUCCESS').replace(
            /\[USERNAME\]/g,
            tagMode === 'username' ? `@${tagValue.replace(/^@+/g, '')}` : tagValue
          );
          break;
        case 'sending':
          hudType = 'loading';
          break;
        default:
          break;
      }
    }

    return (
      <div className="w-100 d-flex flex-column gift-sending">
        <Hud
          isOpen={hudIsOpen}
          type={hudType}
          alertBtnTitle={alertBtnTitle}
          alertTitle={alertTitle}
          alertDesc={alertDesc}
          toastMessage={alertDesc}
          toastTimeout={1000}
          toggle={this.closeAlert}
        />
        <div id="tag-value-container">
          <TextField
            type="text"
            label={i18nTranslator('SEND_GIFT_INPUT')}
            placeholder={i18nTranslator('SEND_GIFT_INPUT_PLACEHOLDER')}
            name="tagValue"
            value={tagValue}
            onChange={this.tagOnChange}
            onFocus={this.tagOnFocus}
            onBlur={this.tagOnBlur}
            inputClassname="disable-keep-normal-input"
            inputRef={(ref) => {
              this.userTagRef = ref;
            }}
            autoComplete="off"
            textMuted={giftSent ? i18nTranslator('SEND_GIFT_ALREADY') : null}
          />
          {isSearching && (
            <div className={`users-searching-indicator ${i18nTranslator('SEND_GIFT_INPUT') ? 'with-label' : ''}`}>
              <LoadingIndicator />
            </div>
          )}
        </div>

        <Popover
          trigger="legacy"
          placement="top"
          isOpen={showingPopover}
          target="tagValue"
          className="user-tag-popover"
          toggle={this.closePopover}
        >
          <PopoverBody>{this.renderSuggestions()}</PopoverBody>
        </Popover>
        <Row>
          <Col>
            <FormGroup className="custom-control custom-checkbox text-center">
              <input
                autoComplete="off"
                className="custom-control-input"
                type="checkbox"
                id="withoutOptionalMessage"
                name="withoutOptionalMessage"
                checked={withoutOptionalMessage}
                onChange={(event) => {
                  this.setState({
                    withoutOptionalMessage: event.currentTarget.checked,
                  });
                }}
              />
              <label className="custom-control-label text-primary" htmlFor="withoutOptionalMessage">
                {i18nTranslator('SEND_GIFT_USE_OPTIONAL_MSG')}
              </label>
            </FormGroup>
          </Col>
        </Row>
        {!withoutOptionalMessage && (
          <TextField
            type="textarea"
            name="comment"
            label={i18nTranslator('SEND_GIFT_COMMENT')}
            placeholder={i18nTranslator('SEND_GIFT_COMMENT_PLACEHOLDER')}
            value={comment}
            maxLength={250}
            onChange={(e) => {
              this.setState({
                comment: e.currentTarget.value,
              });
            }}
            inputClassname="rounded"
          />
        )}
        <div className="w-100 d-flex justify-content-between">
          <div className="mr-2">
            <Button color="secondary" type="button" className="btn-icon-only" onClick={toggleTagUser}>
              <FontAwesomeIcon icon="share-alt" size="lg" />
            </Button>
          </div>
          <div className="flex-grow-1">
            <Button
              color="primary"
              type="button"
              className="w-100 overflow-hidden text-truncate"
              onClick={this.onSubmit}
              disabled={!isValid}
            >
              {i18nTranslator('SEND_GIFT_SEND')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(
  connect(mapStateToProps, {
    sentGiftToUser: giftActions.sentGiftToUser,
    resetSendForm: giftActions.resetSendForm,
  })(ChainsureSendGift)
);
