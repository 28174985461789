import firebase from 'firebase/app';
import 'firebase/auth';
import config from './config';

if (!firebase.apps.length) {
  firebase.initializeApp(config.firebaseConfig);
}

const auth = firebase.auth();
auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export { firebase };
