function arrayRemove<T>(
  array: T[] | undefined | null,
  removeFirst: (value: T, index: number) => boolean
): T[] | undefined | null {
  if (!array) return array;

  const idx = array.findIndex((a, i) => removeFirst(a, i));

  if (idx === -1) {
    return [...array];
  }

  const newArray = [...array];
  newArray.splice(idx, 1);

  return newArray;
}

export default arrayRemove;
