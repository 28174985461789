import { ProductsState } from './types';
import { Action } from '../actions/types';
import { Product } from '../types';

const INITIAL_STATE: ProductsState = {
  isLoading: false,
  canLoadMore: true,
  products: null,
};

const reducer = (state: ProductsState = INITIAL_STATE, action: Action): ProductsState => {
  switch (action.type) {
    case 'PRODUCTS_LOADING': {
      const { silent } = action.payload;
      return {
        ...state,
        isLoading: silent ? state.isLoading : true,
      };
    }

    case 'PRODUCTS_LOADED': {
      const { products, canLoadMore, refresh } = action.payload;

      return {
        ...state,
        isLoading: false,
        products: refresh ? products : [...(state.products || []), ...products],
        canLoadMore,
      };
    }

    case 'PRODUCT_SELECTED': {
      const { productId } = action.payload;

      let selectedProduct: Product | null = null;

      if (productId && state.products) {
        selectedProduct = state.products.find((p) => p.id === productId) || null;
      }

      return {
        ...state,
        selectedProductId: productId,
        selectedProduct,
        checkoutDetail: null,
        buyStatus: null,
        buyImmediateGift: null,
      };
    }

    case 'PRODUCT_DETAIL_LOADED': {
      const { product } = action.payload;

      if (state.selectedProductId === product.id) {
        return {
          ...state,
          selectedProduct: product,
        };
      }

      return state;
    }

    case 'PRODUCT_ORDER': {
      const { productId } = action.payload;

      if (state.selectedProductId === productId) {
        return {
          ...state,
          buyStatus: 'buying',
          checkoutDetail: null,
        };
      }

      return state;
    }

    case 'PRODUCT_CHECKOUT': {
      const { productId, checkoutDetail } = action.payload;

      if (state.selectedProductId === productId) {
        return {
          ...state,
          buyStatus: 'buying',
          checkoutDetail,
        };
      }

      return state;
    }

    case 'PRODUCT_CHECKOUT_VERIFYING': {
      const { productId } = action.payload;

      if (state.selectedProductId === productId) {
        return {
          ...state,
          buyStatus: 'verifying',
        };
      }

      return state;
    }

    case 'PRODUCT_CHECKOUT_SUCCESS': {
      const {
        productId,
        payment: { isImmediate },
      } = action.payload;

      if (state.selectedProductId === productId) {
        return {
          ...state,
          buyStatus: 'success',
          buyImmediateGift: isImmediate,
        };
      }

      return state;
    }

    case 'PRODUCT_CHECKOUT_ERROR': {
      const { productId } = action.payload;

      if (state.selectedProductId === productId) {
        return {
          ...state,
          buyStatus: 'error',
        };
      }

      return state;
    }

    case 'AUTH_STATE_CHANGED': {
      const { user } = action.payload;

      if (user) return state;

      return {
        ...INITIAL_STATE,
      };
    }

    default:
      return state;
  }
};

export default reducer;
