import React from 'react';
import { Card, CardBody } from 'reactstrap';
import './ChainsureHistoryItem.scss';
import Logo from '../../Logo/Logo';

type Props = { isEnd: boolean };

const ChainsureHistoryItemLoading = ({ isEnd }: Props) => (
  <div className={`history-item last ${isEnd ? 'end' : 'loading'}`}>
    <div className="history-time-line">
      <div className="history-indicator item-loading">{isEnd && <Logo />}</div>
      <div className="history-vline-container">
        <div className="history-vline item-loading" />
      </div>
    </div>
    {!isEnd && (
      <div className="history-wrapper">
        <div className="product-date" />
        <Card className="history-card">
          <CardBody className="history-content">
            <div className="heading">
              <div className="user-photo" />
              <div className="content" />
            </div>
            <div className="comment" />
          </CardBody>
        </Card>
      </div>
    )}
  </div>
);

export default ChainsureHistoryItemLoading;
