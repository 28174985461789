function arrayReplaceAt<T>(
  array: T[],
  findIndex: (value: T, index: number) => boolean,
  replaceBy: T | ((value: T) => T)
): T[] {
  if (!array) return array;

  const idx = array.findIndex(findIndex);

  if (idx === -1) {
    return [...array];
  }
  const newArray = [...array];

  if (typeof replaceBy === 'object') {
    newArray[idx] = {
      ...newArray[idx],
      ...replaceBy,
    };
  } else if (typeof replaceBy === 'function' && replaceBy instanceof Function) {
    const t: T = replaceBy(newArray[idx]);

    newArray[idx] = {
      ...newArray[idx],
      ...t,
    };
  }

  return newArray;
}

export default arrayReplaceAt;
