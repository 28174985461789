import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Card, CardBody } from 'reactstrap';
import chainsureAction from '../../../actions/chainsure.action';
import { ReduxState } from '../../../reducers/types';
import datetimeFormat from '../../../utils/datetime/datetimeFormat';
import { i18nTranslator } from '../../../utils/i18n';
import Hud, { HudType } from '../../Hud/Hud';
import ModalBodyWrapper from '../../Modal/ModalBodyWrapper/ModalBodyWrapper';
import UserPhoto from '../../UserPhoto/UserPhoto';
import './ChainsureRequests.scss';

const mapStateToProps = ({ chainsures: { chainsure, chainsureId, requestStatus, requestError } }: ReduxState) => ({
  chainsure,
  chainsureId,
  requestStatus,
  requestError,
});

type Props = ReturnType<typeof mapStateToProps> & {
  denyOwnedRequest: typeof chainsureAction.denyOwnedRequest;
  acceptOwnedRequest: typeof chainsureAction.acceptOwnedRequest;
  cleanRequestError: typeof chainsureAction.cleanRequestError;
  selectChainsure: typeof chainsureAction.selectChainsure;
} & RouteComponentProps<any>;

class ChainsureRequests extends PureComponent<Props> {
  acceptOwnedRequest = (requestUid: string) => {
    const { chainsureId, acceptOwnedRequest } = this.props;

    if (!chainsureId) {
      return;
    }

    acceptOwnedRequest(chainsureId, requestUid);
  };

  denyOwnedRequest = (requestUid: string) => {
    const { chainsureId, denyOwnedRequest } = this.props;

    if (!chainsureId) {
      return;
    }

    denyOwnedRequest(chainsureId, requestUid);
  };

  successErrorAlertClose = () => {
    const { requestStatus, cleanRequestError, history, selectChainsure } = this.props;

    if (requestStatus === 'error') {
      cleanRequestError();

      return;
    }

    history.replace('/inventories');

    selectChainsure();
  };

  render() {
    const { chainsure, requestStatus } = this.props;

    if (chainsure == null || !chainsure.requestsDetails || chainsure.requestsDetails.length === 0) {
      return null;
    }

    const { status } = chainsure;
    const useStatus = chainsureAction.getUseStatus(chainsure);

    if (useStatus !== 'willuse' && (useStatus !== 'used' || status !== 'released')) {
      return null;
    }

    const components = chainsure.requestsDetails.map(({ user, date, comment, requestUid, isRemoving, error }) => (
      <div
        key={requestUid}
        className={`request-info w-100 d-flex flex-column ${isRemoving || error ? 'is-removing' : ''}`}
      >
        <div className="date-request">{datetimeFormat.parseToLocaleString(date, 'full-trim-year')}</div>
        <div className="d-flex">
          <div className="request-info-pic-container">
            <UserPhoto className="request-info-pic" photoURL={user.photoURL} />
          </div>
          <div className="request-info-details flex-grow-1 overflow-hidden">
            <div className="request-content font-weight-medium">
              <span className="font-weight-bold mr-1">{user.displayName}</span>
              <span>{comment}</span>
            </div>
            <div className="d-flex mt-2 p-1">
              <div className="mr-1 w-100">
                <Button
                  type="button"
                  color="primary"
                  className="w-100"
                  disabled={isRemoving}
                  onClick={() => this.acceptOwnedRequest(requestUid)}
                >
                  {i18nTranslator('CHAINSURE_ACCEPT_REQUEST')}
                </Button>
              </div>
              <div className="ml-1 w-100">
                <Button
                  type="button"
                  color="danger"
                  className="w-100"
                  disabled={isRemoving}
                  onClick={() => this.denyOwnedRequest(requestUid)}
                >
                  {i18nTranslator('CHAINSURE_DENY_REQUEST')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));

    let hudType: HudType | null = null;
    let hudAlertBtnTitle: string | null = null;
    let hudAlertTitle: string | null = null;
    let hudAlertDesc: string | null = null;

    switch (requestStatus) {
      case 'accepting':
        hudType = 'loading';
        break;
      case 'success':
        hudType = 'toast';
        hudAlertDesc = i18nTranslator('CHAINSURE_MODAL_REQUEST_SUCCESS');
        break;
      case 'error':
        hudType = 'alert';
        hudAlertBtnTitle = i18nTranslator('ALERT_OK');
        hudAlertTitle = i18nTranslator('ALERT_ERROR');
        hudAlertDesc = i18nTranslator('CHAINSURE_MODAL_REQUEST_ERROR');
        break;
      default:
        break;
    }

    return (
      <ModalBodyWrapper>
        <Hud
          type={hudType}
          isOpen={hudType != null}
          alertBtnTitle={hudAlertBtnTitle}
          alertTitle={hudAlertTitle}
          alertDesc={hudAlertDesc}
          toastMessage={hudAlertDesc}
          toastTimeout={1000}
          toggle={this.successErrorAlertClose}
        />
        <Card className="border-primary">
          <CardBody>
            <div className="font-weight-bold">{i18nTranslator('REQUEST_LIST_TITLE')}</div>

            <div className="mt-1 requests-wrapper">{components}</div>
          </CardBody>
        </Card>
      </ModalBodyWrapper>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, {
    denyOwnedRequest: chainsureAction.denyOwnedRequest,
    acceptOwnedRequest: chainsureAction.acceptOwnedRequest,
    cleanRequestError: chainsureAction.cleanRequestError,
    selectChainsure: chainsureAction.selectChainsure,
  })(ChainsureRequests)
);
