import React, { PureComponent, ReactNode } from 'react';
import './OwnedChainsureListItem.scss';
import { Chainsure } from '../../../../types';
import DateRangeLabel from '../../../../components/DateRangeLabel/DateRangeLabel';
import chainsureAction from '../../../../actions/chainsure.action';
import ProductInfoListItem from '../../../../components/ProductInfo/ListItem/ProductInfoListItem';
import UserPhoto from '../../../../components/UserPhoto/UserPhoto';
import GiftAvailable from '../../../../components/ChainsureInfo/GiftAvailable/GiftAvailable';
import productTypeSelect from '../../../../utils/productTypeSelect';
import datetimeFormat from '../../../../utils/datetime/datetimeFormat';
import { i18nTranslator } from '../../../../utils/i18n';
type Props = {
  chainsure: Chainsure;
  linkTo?: string;
  horizontal?: boolean;
  hideBottom?: boolean;
};

class OwnedChainsureListItem extends PureComponent<Props> {
  refreshTimeOut: any = null;

  componentDidMount() {
    this.setupTimeoutForUpdateStatus();
  }

  componentDidUpdate() {
    this.setupTimeoutForUpdateStatus();
  }

  componentWillUnmount() {
    clearTimeout(this.refreshTimeOut);
  }

  setupTimeoutForUpdateStatus = () => {
    const { chainsure } = this.props;
    const now = new Date();

    const status = chainsureAction.getUseStatus(chainsure);

    clearTimeout(this.refreshTimeOut);

    switch (status) {
      case 'willuse':
        this.refreshTimeOut = setTimeout(
          () => this.forceUpdate(),
          +datetimeFormat.timestampToDate(chainsure.startDate) - +now
        );
        break;
      case 'using':
        this.refreshTimeOut = setTimeout(
          () => this.forceUpdate(),
          +datetimeFormat.timestampToDate(chainsure.endDate) - +now
        );
        break;
      default:
        break;
    }
  };

  render() {
    const { chainsure, linkTo, horizontal, hideBottom } = this.props;
    const { product, user, status } = chainsure;

    const useStatus = chainsureAction.getUseStatus(chainsure);

    const bottomComponent = (
      <div className="owner w-100">
        <div className="product-date font-weight-bold">{i18nTranslator('CHAINSURE_OWNER')}</div>
        <div className="d-flex align-items-center mt-1">
          <div className="owner-pic-container">
            <UserPhoto className="owner-pic" photoURL={user.photoURL} />
          </div>
          <div className="owner-details text-truncate">{user.displayName}</div>
        </div>
      </div>
    );

    let topComponent: ReactNode = (
      <GiftAvailable
        requestSent={chainsure.requested}
        className="on-list"
        tooltipPlacement="bottom"
        chainsureId={chainsure.id}
      />
    );

    let timeLabel: string | null = null;
    let stateKey: string = '';

    switch (useStatus) {
      case 'used': {
        timeLabel = i18nTranslator('CHAINSURE_LAST_USE_ON');

        if (chainsure.status === 'pay_waiting') {
          // state JOINED_REDEEMED
          stateKey = productTypeSelect(
            chainsure.product.type,
            {
              money: 'JOINED_STATE_REDEEMED_MONEY',
              good_mood: 'JOINED_STATE_REDEEMED_GOOD_MOOD',
              insurance: 'JOINED_STATE_REDEEMED_INSURANCE',
            },
            chainsure.isImmediate
          );
        } else if (chainsure.status === 'paid') {
          // state JOINED_PAID
          stateKey = productTypeSelect(
            chainsure.product.type,
            {
              money: 'JOINED_STATE_PAID_MONEY',
              good_mood: 'JOINED_STATE_PAID_GOOD_MOOD',
              insurance: 'JOINED_STATE_PAID_INSURANCE',
            },
            chainsure.isImmediate
          );
        } else if (chainsure.status === 'released') {
          topComponent = null;

          // state JOINED_RELEASED
          stateKey = productTypeSelect(
            chainsure.product.type,
            {
              free: 'JOINED_STATE_RELEASED_FREE',
              money: 'JOINED_STATE_RELEASED_MONEY',
              money_immediate: 'JOINED_STATE_RELEASED_MONEY_IMMEDIATE',
              good_mood: 'JOINED_STATE_RELEASED_GOOD_MOOD',
              insurance: 'JOINED_STATE_RELEASED_INSURANCE',
            },
            chainsure.isImmediate
          );
        } else if (chainsure.product.type === 'free' || (chainsure.product.type === 'money' && chainsure.isImmediate)) {
          // state JOINED_ACCEPTED (Free/Money Immediate)
          stateKey = productTypeSelect(
            chainsure.product.type,
            {
              free: 'JOINED_STATE_ACCEPTED_FREE',
              money_immediate: 'JOINED_STATE_ACCEPTED_MONEY_IMMEDIATE',
            },
            chainsure.isImmediate
          );
        } else {
          // state JOINED_FINISHED
          stateKey = productTypeSelect(chainsure.product.type, {
            money: 'JOINED_STATE_FINISHED_MONEY',
            good_mood: 'JOINED_STATE_FINISHED_GOOD_MOOD',
            insurance: 'JOINED_STATE_FINISHED_INSURANCE',
          });
        }
        break;
      }
      case 'using': {
        timeLabel = i18nTranslator('CHAINSURE_USING');
        topComponent = null;

        // state JOINED_STARTED
        stateKey = productTypeSelect(chainsure.product.type, {
          money: 'JOINED_STATE_STARTED_MONEY',
          good_mood: 'JOINED_STATE_STARTED_GOOD_MOOD',
          insurance: 'JOINED_STATE_STARTED_INSURANCE',
        });

        break;
      }
      case 'willuse': {
        timeLabel = i18nTranslator('CHAINSURE_USE_ON');

        if (chainsure.giftSent) {
          // State JOINED_OFFERED
          stateKey = productTypeSelect(
            chainsure.product.type,
            {
              free: 'JOINED_STATE_OFFERED_FREE',
              money: 'JOINED_STATE_OFFERED_MONEY',
              money_immediate: 'JOINED_STATE_OFFERED_MONEY_IMMEDIATE',
              good_mood: 'JOINED_STATE_OFFERED_GOOD_MOOD',
              insurance: 'JOINED_STATE_OFFERED_INSURANCE',
            },
            chainsure.isImmediate
          );
        } else if (!chainsure.giftAcceptedFrom) {
          // State BOUGHT
          stateKey = productTypeSelect(
            chainsure.product.type,
            {
              free: 'JOINED_STATE_BOUGHT_FREE',
              money: 'JOINED_STATE_BOUGHT_MONEY',
              money_immediate: 'JOINED_STATE_BOUGHT_MONEY_IMMEDIATE',
              good_mood: 'JOINED_STATE_BOUGHT_GOOD_MOOD',
              insurance: 'JOINED_STATE_BOUGHT_INSURANCE',
            },
            chainsure.isImmediate
          );
        } else {
          // State JOINED_ACCEPTED (Money, Good Mood, Insrance)
          stateKey = productTypeSelect(
            chainsure.product.type,
            {
              money: 'JOINED_STATE_ACCEPTED_MONEY',
              good_mood: 'JOINED_STATE_ACCEPTED_GOOD_MOOD',
              insurance: 'JOINED_STATE_ACCEPTED_INSURANCE',
            },
            chainsure.isImmediate
          );
        }

        break;
      }
      default:
        break;
    }

    if (
      chainsure.product.type === 'insurance' &&
      (useStatus === 'willuse' || (useStatus === 'used' && status === 'released'))
    ) {
      // state AVAILABLE REQUESTED
      stateKey = chainsure.requested ? 'STATE_REQUESTED_INSURANCE' : 'STATE_AVAILABLE_INSURANCE';
    }

    return (
      <ProductInfoListItem
        horizontal={horizontal}
        linkTo={linkTo || `/joined/c/${chainsure.id}`}
        {...{
          ...product,
          currency: chainsure.currency,
          price: chainsure.amount,
        }}
        lastUpdatedTime={chainsure.createdAt}
        photoURL={chainsureAction.getChainsurePhoto(chainsure)}
        timeClassName={`align-items-start ${horizontal ? 'text-left text-md-right' : ''}`}
        renderTime={() => (
          <>
            <div className="font-weight-bold">{timeLabel}</div>
            <DateRangeLabel
              startDate={chainsure.startDate}
              endDate={chainsure.endDate}
              labelOnly={
                chainsure.isImmediate &&
                chainsure.status === 'inactive' &&
                datetimeFormat.timestampToDate(chainsure.startDate) > new Date()
                  ? i18nTranslator('CHAINSURE_USE_ON_IMMEDIATE')
                  : null
              }
            />
          </>
        )}
        timeAndPriceContainerClassName={horizontal ? null : 'mt-sm-3'}
        renderBottom={hideBottom ? null : bottomComponent}
        renderTop={topComponent}
        stateBottomKey={stateKey}
        stateBottomPlaceholder
      />
    );
  }
}

export default OwnedChainsureListItem;
