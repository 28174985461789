import config from './config';

let isInit = false;

const init = () => {
  if (isInit) return;

  (window as any).fbAsyncInit = function () {
    (window as any).FB.init({
      appId: config.social.facebook.appID,
      autoLogAppEvents: true,
      xfbml: true,
      version: config.social.facebook.version,
    });
  };
  (function (d, s, id) {
    let js;
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode && fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');

  isInit = true;
};

const openDialog = (link: string) => {
  (window as any).FB.ui(
    {
      method: 'share',
      href: link,
    },
    (response) => {
      console.log(response);
    }
  );
};

export default {
  init,
  openDialog,
};
