import { auth } from 'firebase';
import { CancelToken } from 'axios';
import { firebase } from '../utils/firebase';
import { getDispatch } from '../reduxStore';
import { ThunkAction } from './types';
import httpClient from '../utils/httpClient';
import { UserAddition, UserSuggest, ClientUser } from '../types';
import copyToClipboard from '../utils/copyToClipboard';

let currentAccessToken: string | null = null;
let didSetupAuth = false;
let timeoutForRefreshToken: any = null;

const renewAuthToken = async () => {
  const { currentUser } = firebase.auth();

  if (currentUser) {
    return await currentUser.getIdTokenResult(true);
  }

  return Promise.resolve();
};
const verifyResetPasswordCode = (code: string) => auth().verifyPasswordResetCode(code);
const resetPassword = (code: string, password: string) => auth().confirmPasswordReset(code, password);
const requestResetPassword = async (email: string) => {
  await httpClient.app.post('/auth/reset/password', {
    email,
  });
};

const onLoggedIn = async (credential: auth.UserCredential) => {
  const { additionalUserInfo, user } = credential;

  if (!user) {
    return;
  }

  if (additionalUserInfo && additionalUserInfo.isNewUser) {
    const accessToken = await user.getIdToken();
    const { data: addition } = await httpClient.app.post<UserAddition>(
      '/users/postRegister',
      {
        displayName: user.displayName,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    getDispatch()({
      type: 'AUTH_STATE_CHANGED',
      payload: {
        user: {
          uid: user.uid,
          displayName: user.displayName,
          photoURL: user.photoURL,
          email: user.email,
          phoneNumber: user.phoneNumber,
          providerData: user.providerData,
        },
        addition,
      },
    });

    setImmediate(renewAuthToken);
  }
};

const authByWithSocial = async (provider: 'facebook' | 'google' | 'twitter') => {
  let authProvider: firebase.auth.AuthProvider | null = null;

  switch (provider) {
    case 'facebook':
      const fbProvider = new firebase.auth.FacebookAuthProvider();

      fbProvider.addScope('email');
      authProvider = fbProvider;
      break;
    case 'google':
      const ggProvider = new firebase.auth.GoogleAuthProvider();

      ggProvider.addScope('email, profile');
      authProvider = ggProvider;
      break;
    case 'twitter':
      authProvider = new firebase.auth.TwitterAuthProvider();
      break;
    default:
      break;
  }

  if (!authProvider) {
    return;
  }

  const credential = await firebase.auth().signInWithPopup(authProvider);

  await onLoggedIn(credential);
};

const loginByEmail = async (email: string, password: string, remember: boolean) => {
  await firebase
    .auth()
    .setPersistence(remember ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.NONE);

  const credential = await firebase.auth().signInWithEmailAndPassword(email, password);

  await onLoggedIn(credential);
};

const signUpByEmail = async (email: string, password: string, displayName: string, remember: boolean) => {
  getDispatch()({
    type: 'AUTH_PREPARE_SIGNUP_EMAIL',
  });

  try {
    await firebase
      .auth()
      .setPersistence(remember ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.NONE);

    const credential = await firebase.auth().createUserWithEmailAndPassword(email, password);

    const { user } = credential;

    if (!user) {
      return;
    }

    await user.updateProfile({
      displayName,
      photoURL: null,
    });

    getDispatch()({
      type: 'AUTH_STATE_CHANGED',
      payload: {
        user: {
          ...user,
          displayName,
        },
        addition: {
          username: null,
        },
      },
    });

    await onLoggedIn(credential);
  } catch (error) {
    getDispatch()({
      type: 'AUTH_DONE_SIGNUP_EMAIL',
    });

    throw error;
  }
};

const logout = (): ThunkAction => async (dispatch) => {
  try {
    clearTimeout(timeoutForRefreshToken);
    await firebase.auth().signOut();
  } catch (error) {
    console.log(error);

    dispatch({
      type: 'AUTH_STATE_CHANGED',
      payload: {
        user: null,
        addition: null,
      },
    });
  }

  // window.location.reload()
};

const reloadProfile = async () => {
  const user = firebase.auth().currentUser;

  if (user) {
    await user.reload();

    const tokenResult = await renewAuthToken();

    if (!tokenResult) {
      return null;
    }

    const { username } = tokenResult.claims || {};

    const data = {
      user,
      addition: {
        username,
      },
    };

    getDispatch()({
      type: 'AUTH_STATE_CHANGED',
      payload: data,
    });

    return {
      ...data,
      tokenResult,
    };
  }

  return null;
};

const changeUserPhoto = async (mediaId: string) => {
  await httpClient.app.patch<UserAddition>('/settings/profile/photo', {
    mediaId,
  });

  await reloadProfile();
};

const updateProfile = async (displayName: string, username: string, preferLang: string) => {
  await httpClient.app.patch<UserAddition>('/users/profile', {
    displayName,
    username,
    preferLang,
  });

  await reloadProfile();
};

const changePassword = async (currentPassword: string, newPassword: string) => {
  const currentUser = auth().currentUser;

  if (!currentUser || !currentUser.email) {
    return;
  }

  await auth().signInWithEmailAndPassword(currentUser.email, currentPassword);

  await currentUser.updatePassword(newPassword);
};

const searchUsername = (query: string, size: number, cancelToken?: CancelToken) =>
  httpClient.app.get<UserSuggest[]>(`users/suggest?size=${size}&query=${decodeURIComponent(query)}`, {
    cancelToken,
  });

const getUserDetail = () => httpClient.app.get<ClientUser & UserAddition>('users/me');

const copyAccessToken = () => copyToClipboard(`Bearer ${currentAccessToken}`);

const setTimeOutForRefreshToken = (tokenResult: auth.IdTokenResult) => {
  // trigger refresh token at 2 mins earlier than expirationTime
  timeoutForRefreshToken = setTimeout(() => {
    renewAuthToken()
      .then(() => {
        console.log('Refersh token successfully');
      })
      .catch((error) => {
        console.log('Refersh token error', error);
        window.location.reload();
      });
  }, +new Date(tokenResult.expirationTime) - +new Date() - 2 * 60000);
};

const setupAuth = () =>
  new Promise<void>((resolve) => {
    firebase.auth().onIdTokenChanged(async (user) => {
      clearTimeout(timeoutForRefreshToken);

      if (user) {
        const tokenResult = await user.getIdTokenResult();
        httpClient.setAppAuthToken(tokenResult.token);

        currentAccessToken = tokenResult.token;

        setTimeOutForRefreshToken(tokenResult);
      }
    });

    firebase.auth().onAuthStateChanged(async (user) => {
      const dispatch = getDispatch();

      clearTimeout(timeoutForRefreshToken);

      let addition: UserAddition | null = null;

      if (user) {
        let tokenResult = await user.getIdTokenResult();

        httpClient.setAppAuthToken(tokenResult.token);

        currentAccessToken = tokenResult.token;

        addition = {
          username: tokenResult.claims.username,
        };

        if (user.providerData[0] && user.providerData[0].providerId === 'password' && !user.displayName) {
          const reloadedData = await reloadProfile();

          if (reloadedData) {
            ({ tokenResult, user, addition } = reloadedData);
          }
        }

        setTimeOutForRefreshToken(tokenResult);
      } else {
        httpClient.setAppAuthToken(null);
      }

      dispatch({
        type: 'AUTH_STATE_CHANGED',
        payload: {
          user,
          addition,
        },
      });

      if (!didSetupAuth) {
        didSetupAuth = true;

        await reloadProfile();

        resolve();
      }
    });
  });

setupAuth();

export default {
  loginByEmail,
  signUpByEmail,
  logout,
  setupAuth,
  authByWithSocial,
  verifyResetPasswordCode,
  resetPassword,
  requestResetPassword,
  reloadProfile,
  updateProfile,
  changePassword,
  searchUsername,
  copyAccessToken,
  getUserDetail,
  changeUserPhoto,
};
