import { ThunkAction, Action } from './types';
import { Product, CheckoutDetail, PaymentCheckoutResponse } from '../types';
import httpClient from '../utils/httpClient';
import datetimeFormat from '../utils/datetime/datetimeFormat';

const getAll = (silent?: boolean, lastProduct?: Product | null): ThunkAction => async (dispatch) => {
  dispatch({
    type: 'PRODUCTS_LOADING',
    payload: {
      silent,
    },
  });

  const size = 6;

  let endpoint = `/offers?size=${size}`;

  if (lastProduct) {
    const { id, dateAvailable: date } = lastProduct;

    endpoint += `&date=${datetimeFormat.timestampToDate(date).toISOString()}&id=${id}`;
  }
  const { data: products } = await httpClient.app.get<Product[]>(endpoint);

  dispatch({
    type: 'PRODUCTS_LOADED',
    payload: {
      products,
      canLoadMore: products.length === size,
      refresh: !lastProduct,
    },
  });
};

const getBuyingNote = async (productId: string) => {
  const { data } = await httpClient.app.get<{ comment: string }>(`/offers/p/${productId}/buy`);

  return data.comment;
};

const getProductDetail = (productId: string): ThunkAction => async (dispatch) => {
  try {
    const response = await httpClient.app.get<Product>(`/offers/p/${productId}`);

    dispatch({
      type: 'PRODUCT_DETAIL_LOADED',
      payload: {
        product: response.data,
      },
    });
  } catch (error) {}
};

const buyProduct = (
  productId: string,
  startDate: Date,
  endDate: Date,
  comment: string,
  mediaIds: string[],
  isImmediate: boolean
): ThunkAction => async (dispatch) => {
  try {
    dispatch({
      type: 'PRODUCT_ORDER',
      payload: {
        productId,
      },
    });

    const { data: checkoutDetail } = await httpClient.app.post<CheckoutDetail>(`/offers/p/${productId}/buy`, {
      startDate,
      endDate,
      comment,
      mediaIds,
      isImmediate,
    });

    dispatch({
      type: 'PRODUCT_CHECKOUT',
      payload: {
        productId,
        checkoutDetail,
      },
    });
  } catch (error) {
    console.log(error.response);

    dispatch({
      type: 'PRODUCT_CHECKOUT_ERROR',
      payload: {
        productId,
      },
    });
  }
};

const selectProduct = (productId: string | null): Action => ({
  type: 'PRODUCT_SELECTED',
  payload: {
    productId,
  },
});

const verifyProductCheckout = (productId: string, paymentId: string): ThunkAction => async (dispatch) => {
  try {
    const { data: product } = await httpClient.app.get<Product>(`/offers/p/${productId}`);

    dispatch({
      type: 'PRODUCT_DETAIL_LOADED',
      payload: {
        product,
      },
    });

    dispatch({
      type: 'PRODUCT_CHECKOUT_VERIFYING',
      payload: {
        productId,
      },
    });

    const { data: payment } = await httpClient.app.get<PaymentCheckoutResponse>(
      `/offers/p/${productId}/payments/${paymentId}`
    );

    if (payment.status !== 'completed') {
      dispatch({
        type: 'PRODUCT_CHECKOUT_ERROR',
        payload: {
          productId,
        },
      });

      return;
    }

    dispatch({
      type: 'PRODUCT_CHECKOUT_SUCCESS',
      payload: {
        productId,
        payment,
      },
    });
  } catch (error) {
    console.log(error);

    dispatch({
      type: 'PRODUCT_CHECKOUT_ERROR',
      payload: {
        productId,
      },
    });
  }
};

export default {
  getAll,
  selectProduct,
  getProductDetail,
  buyProduct,
  verifyProductCheckout,
  getBuyingNote,
};
