import React from 'react';
import classesNames from './Price.module.scss';
import FontAwesomeIcon from '../Icon/FontAwesomeIcon';
import numberFormat from '../../utils/numberFormat';
import { i18nTranslator } from '../../utils/i18n';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Props = {
  currency: 'USD' | 'EUR';
  price: number;
  size?: 'lg' | null;
  className?: string;
};

const Price = (props: Props) => {
  const { currency, price, size, className } = props;

  let icon: IconProp = 'question-circle';
  switch (currency) {
    case 'EUR':
      icon = 'euro-sign';
      break;
    case 'USD':
      icon = 'dollar-sign';
      break;
    default:
      break;
  }

  const classname = `${classesNames.price} ${classesNames[size || ''] || ''} ${
    className || ''
  } ${classesNames[i18nTranslator('CURRENCY_FORMAT')]}`;

  if (!price) {
    return (
      <div className={classname}>
        <span
          className={`${classesNames.price} ${classesNames.free} price-value`}
        >
          {i18nTranslator('FREE')}
        </span>
      </div>
    );
  }

  return (
    <div className={classname}>
      <div className={classesNames.tag}>
        {/* TODO: Check this size props */}
        <FontAwesomeIcon icon={icon} size="2x" />
      </div>
      <span className="price-value">{numberFormat.toLocaleString(price)}</span>
    </div>
  );
};

export default Price;
