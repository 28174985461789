import React, { Component } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Alert, Col, Container, Row } from 'reactstrap';
import chainsureActions from '../../actions/chainsure.action';
import { ReduxState } from '../../reducers/types';
import { Chainsure } from '../../types';
import ChainsureDetailModal from './Chainsure/DetailModal/ChainsureDetailModal';
import ChainsureListItem from './Chainsure/ListItem/ChainsureListItem';
import ChainsureListItemLoading from './Chainsure/ListItem/ChainsureListItemLoading';

const mapStateToProps = ({ chainsures: { isLoading, chainsures, chainsureId, canLoadMore } }: ReduxState) => ({
  isLoading,
  chainsures,
  chainsureId,
  canLoadMore,
});

type Props = ReturnType<typeof mapStateToProps> & {
  getAll: typeof chainsureActions.getAll;
  selectChainsure: typeof chainsureActions.selectChainsure;
  toggleMakingGift: typeof chainsureActions.toggleMakingGift;
  toggleRelease: typeof chainsureActions.toggleRelease;
} & RouteComponentProps<{
    chainsureId: string;
  }> &
  WithNamespaces;

class Inventories extends Component<Props> {
  componentDidMount() {
    const { isLoading, chainsures, getAll, match, selectChainsure } = this.props;

    if (!isLoading && chainsures == null) {
      getAll();
    }

    const { chainsureId } = match.params;

    if (chainsureId) {
      selectChainsure(chainsureId);
    }

    window.addEventListener('scroll', this.onScroll);
  }

  componentDidUpdate(prevProps: Props) {
    const { match, chainsureId, selectChainsure, getAll, lng } = this.props;

    if (match.params.chainsureId !== chainsureId) {
      selectChainsure(match.params.chainsureId);
    }

    if (lng !== prevProps.lng) {
      getAll(true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    if (window.innerHeight + window.pageYOffset + 100 >= (document.body.children[1] as HTMLElement).offsetHeight) {
      this.loadMore();
    }
  };

  loadMore = () => {
    const { getAll, isLoading, chainsures, canLoadMore } = this.props;

    if (isLoading || !canLoadMore) return;

    getAll(false, chainsures && chainsures.length > 0 ? chainsures[chainsures.length - 1] : null);
  };

  closeChainsureModal = () => {
    const { history } = this.props;

    history.push('/inventories');
  };

  onItemMakeGift = (chainsure: Chainsure) => {
    const { toggleMakingGift } = this.props;
    const { id, gift, giftComment, giftSent } = chainsure;

    toggleMakingGift(id, gift, giftComment, giftSent);
  };

  onItemRelease = (chainsure: Chainsure) => {
    const { toggleRelease } = this.props;

    toggleRelease(chainsure.id, chainsure);
  };

  renderList = () => {
    const { chainsures, t, isLoading } = this.props;

    if (!isLoading && chainsures && chainsures.length === 0) {
      return (
        <Alert isOpen color="primary" fade={false}>
          {t('INVENTORIES_EMPTY')}
        </Alert>
      );
    }

    return (
      <Row>
        {chainsures &&
          chainsures.map((c) => (
            <Col key={c.id} md={6} lg={4} sm={6}>
              <ChainsureListItem t={t} chainsure={c} />
            </Col>
          ))}

        {isLoading &&
          Array.from(
            {
              length: 3,
            },
            (_, i) => (
              <Col key={`loading_${i}`} md={6} lg={4} sm={6}>
                <ChainsureListItemLoading />
              </Col>
            )
          )}
      </Row>
    );
  };

  render() {
    const { location, history } = this.props;

    return (
      <Container className="container-sm-fluid">
        <ChainsureDetailModal onClose={this.closeChainsureModal} location={location} history={history} />
        {this.renderList()}
      </Container>
    );
  }
}

export default withNamespaces()(
  connect(mapStateToProps, {
    getAll: chainsureActions.getAll,
    selectChainsure: chainsureActions.selectChainsure,
    toggleMakingGift: chainsureActions.toggleMakingGift,
    toggleRelease: chainsureActions.toggleRelease,
  })(Inventories)
);
