import React, { PureComponent, SyntheticEvent } from 'react';
import './TextField.scss';
import { FormGroup, InputGroup, InputGroupAddon, Label, Input } from 'reactstrap';
import MaskedInput from 'react-text-mask';
import FontAwesomeIcon from '../Icon/FontAwesomeIcon';

type Props = {
  label?: string | null;
  type: 'email' | 'text' | 'password' | 'textarea';
  name: string;
  value: string;
  placeholder?: string;
  light?: boolean;
  invalid?: boolean;
  invalidMessage?: string;
  textMuted?: string | null;
  formGroupClassname?: string;
  inputClassname?: string;
  disabled?: boolean;
  inputGroup?: 'left' | 'right' | null;
  onChange?: (event: SyntheticEvent<HTMLInputElement>) => void;
  onFocus?: (event: SyntheticEvent<HTMLInputElement>) => void;
  onBlur?: (event: SyntheticEvent<HTMLInputElement>) => void;
  children?: any;
  mask?: any;
  maxLength?: number;
  autoComplete?: string;
  maskDefaultValue?: any;
  inputRef?: (ref: HTMLInputElement) => void;
};

type State = {
  touched: boolean;
};

class TextField extends PureComponent<Props, State> {
  state: State = {
    touched: false,
  };

  inputRef: HTMLInputElement | null = null;

  focus = () => {
    this.inputRef && this.inputRef.focus();
  };

  onFocus = (e) => {
    const { onFocus } = this.props;

    onFocus && onFocus(e);

    setTimeout(() => {
      this.inputRef && this.inputRef.scrollIntoView(true);
    }, 500);
  };

  onBlur = (e) => {
    const { touched } = this.state;
    if (!touched) {
      this.setState({
        touched: true,
      });
    }

    const { onBlur } = this.props;

    onBlur && onBlur(e);
  };

  renderInvalidFeedback = () => {
    const { invalidMessage, light } = this.props;

    if (!invalidMessage) return null;

    const classesNames = ['invalid-feedback'];

    if (light) {
      classesNames.push('invalid-feedback-light');
    }

    return <div className={classesNames.join(' ')}>{invalidMessage}</div>;
  };

  onChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { onChange, maxLength } = this.props;

    let text = e.currentTarget.value;
    let textLength = text.length;

    if (maxLength) {
      if (maxLength < textLength) {
        text = text.substr(0, maxLength).trim();
        textLength = maxLength;

        e.currentTarget.value = text;
      }
    }

    onChange && onChange(e);
  };

  renderInput = (classesNames: Array<string>) => {
    const { type, name, placeholder, value, disabled, mask, maskDefaultValue, inputRef, autoComplete } = this.props;

    if (mask) {
      return (
        <MaskedInput
          className={classesNames.join(' ')}
          type={type}
          name={name}
          id={name}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          mask={mask}
          keepCharPositions
          defaultValue={maskDefaultValue}
        />
      );
    }

    return (
      <Input
        className={classesNames.join(' ')}
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        innerRef={(ref) => {
          this.inputRef = ref;
          inputRef && inputRef(ref);
        }}
        autoComplete={autoComplete}
      />
    );
  };

  renderInputGroup = (classesNames: Array<string>) => {
    const { children, inputGroup, disabled } = this.props;

    let inputGroupClassName: string = '';

    if (inputGroup === 'left') {
      inputGroupClassName = 'appended-left';
    } else if (inputGroup === 'right') {
      inputGroupClassName = 'appended-right';
    }

    if (disabled) {
      inputGroupClassName += ' disabled-input-appended';
    }

    return (
      <InputGroup>
        {inputGroup === 'left' && (
          <InputGroupAddon addonType="append" className={inputGroupClassName}>
            {children}
          </InputGroupAddon>
        )}
        {this.renderInput([...classesNames, inputGroupClassName])}
        {inputGroup === 'right' && (
          <InputGroupAddon addonType="append" className={inputGroupClassName}>
            {children}
          </InputGroupAddon>
        )}
      </InputGroup>
    );
  };

  renderTextMuted = () => {
    const { textMuted } = this.props;

    return (
      textMuted && (
        <small className="form-text text-muted">
          <FontAwesomeIcon icon="exclamation-circle" />
          <span>{textMuted}</span>
        </small>
      )
    );
  };

  renderTextLength = () => {
    const { maxLength, value } = this.props;

    const textLength = value ? value.length : 0;

    if (!textLength || !maxLength || textLength < maxLength - 50) {
      return null;
    }

    return (
      <div className={`text-length ${textLength === maxLength ? 'limited' : ''}`}>{`${textLength}/${maxLength}`}</div>
    );
  };

  render() {
    const { invalid, formGroupClassname, inputClassname, inputGroup, label, name, light } = this.props;
    const { touched } = this.state;

    const classesNames = ['form-control', inputClassname || ''];
    const shouldMarkAsInvalid = touched && invalid != null && invalid;

    if (light) {
      classesNames.push('input-light');
    }

    if (shouldMarkAsInvalid) {
      classesNames.push('is-invalid');
    }

    return (
      <FormGroup className={`${formGroupClassname || ''} text-field-custom`}>
        {label && (
          <Label htmlFor={name} className="text-primary">
            {label}
          </Label>
        )}
        {inputGroup ? this.renderInputGroup(classesNames) : this.renderInput(classesNames)}

        {shouldMarkAsInvalid && this.renderInvalidFeedback()}
        {this.renderTextMuted()}
        {this.renderTextLength()}
      </FormGroup>
    );
  }
}

export default TextField;
