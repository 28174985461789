import React, { ReactNode } from 'react';
import './ProductInfoListItem.scss';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { Product, Timestamp } from '../../../types';
import Photo from '../../Photo/Photo';
import Price from '../../Price/Price';
import { i18nTranslator } from '../../../utils/i18n';
import datetimeFormat from '../../../utils/datetime/datetimeFormat';

type Props = Product & {
  productOnly?: boolean;
  linkTo?: string;
  timeClassName?: string;
  lastUpdatedTime?: string | Timestamp;
  priceClassName?: string;
  timeAndPriceContainerClassName?: string | null;
  indicatorClass?: string;
  indicatorComponent?: React.ReactNode;
  renderTime?: (() => React.ReactNode | string) | null;
  renderBottom?: (() => ReactNode) | React.ReactNode | null;
  stateBottomKey?: string | null;
  stateBottomPlaceholder?: boolean;
  renderTop?: (() => ReactNode) | React.ReactNode | null;
  horizontal?: boolean;
};

const ProductInfoListItem = (props: Props) => {
  const {
    linkTo,
    photoURL,
    title,
    description,
    currency,
    price,
    type,
    renderTime,
    timeAndPriceContainerClassName,
    timeClassName,
    priceClassName,
    renderBottom,
    renderTop,
    horizontal,
    productOnly,
    indicatorClass,
    indicatorComponent,
    stateBottomKey,
    stateBottomPlaceholder,
    lastUpdatedTime,
  } = props;

  let content: ReactNode = null;

  if (horizontal) {
    content = (
      <>
        <Col md={6}>
          <Row noGutters>
            <Photo photoURL={photoURL} className="photo col-md-3 col-4" photoClassName="photo-img" />
            <Col md={9} xs={8} className="pl-2">
              <div className="text-sm-left text-right product-title text-truncate">{title}</div>
              <div className="text-sm-left text-right product-desc text-truncate">{description}</div>
            </Col>
          </Row>
        </Col>
        <Col md={6} className="mt-3 mt-md-0">
          <Row noGutters className={`h-100 flex-row-reverse flex-md-row ${timeAndPriceContainerClassName || ''}`}>
            <Col
              md={5}
              xs={4}
              className={`product-price d-flex justify-content-end align-items-end justify-content-md-center align-items-md-center ${
                priceClassName || ''
              }`}
            >
              <Price currency={currency} price={price} />
            </Col>
            <Col md={7} xs={8}>
              {renderTime && (
                <div
                  className={`product-date d-flex flex-column h-100 justify-content-end align-items-start justify-content-md-center align-items-md-end ${
                    timeClassName || ''
                  }`}
                >
                  {(productOnly || type !== 'free') && renderTime()}
                </div>
              )}
            </Col>
          </Row>
        </Col>
        {!!lastUpdatedTime && (
          <div className="updated-time">
            {`* ${datetimeFormat.parseToLocaleString(lastUpdatedTime, 'full-trim-year')}`}
          </div>
        )}
      </>
    );
  } else {
    content = (
      <>
        <Photo photoURL={photoURL} className="photo col-4 col-sm-12" photoClassName="photo-img" />
        <div className="col-8 col-sm-12 pl-2 pl-sm-0 pt-0 pt-sm-2">
          <div className="text-sm-left text-right product-title text-truncate">{title}</div>
          <div className="text-sm-left text-right product-desc text-truncate">{description}</div>
        </div>
        <div className={`col-12 d-flex justify-content-between mt-2 ${timeAndPriceContainerClassName || ''}`}>
          {renderTime && (
            <div
              className={`product-date ${productOnly ? '' : 'min-height'} d-flex flex-column ${
                productOnly ? 'justify-content-end' : 'justify-content-center'
              } ${timeClassName || ''}`}
            >
              {(productOnly || type !== 'free') && renderTime()}
            </div>
          )}
          <div className={`product-price d-flex align-items-center ${priceClassName || ''}`}>
            <Price currency={currency} price={price} />
          </div>
        </div>
        {renderBottom && (
          <div className="col-12 mt-2">{typeof renderBottom === 'function' ? renderBottom() : renderBottom}</div>
        )}
        {(stateBottomKey || stateBottomPlaceholder) && (
          <div className="col-12 mt-2 state-bottom">{(!!stateBottomKey && i18nTranslator(stateBottomKey)) || ' '}</div>
        )}
        {!!lastUpdatedTime && (
          <div className="updated-time">
            {`* ${datetimeFormat.parseToLocaleString(lastUpdatedTime, 'full-trim-year')}`}
          </div>
        )}
        {renderTop && <>{typeof renderTop === 'function' ? renderTop() : renderTop}</>}
      </>
    );
  }

  return (
    <Link className={`product-list-item row no-gutters ${horizontal ? 'horizontal' : ''}`} to={linkTo || '#'}>
      <Col>
        {indicatorComponent && (
          <Row noGutters className={`product-indicator ${indicatorClass || ''}`}>
            <Col>
              <div className="d-flex flex-row justify-content-between align-items-center">{indicatorComponent}</div>
            </Col>
          </Row>
        )}
        <Row noGutters className="content">
          {content}
        </Row>
      </Col>
    </Link>
  );
};

export default ProductInfoListItem;
