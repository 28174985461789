import React, { Component } from 'react';
import { Button } from 'reactstrap';
import './DateTimePicker.scss';
import DayPicker from 'react-day-picker';
import FontAwesomeIcon from '../../Icon/FontAwesomeIcon';
import datetimeFormat from '../../../utils/datetime/datetimeFormat';

type State = {};

type Props = {
  selectedDate: Date | null;
  onChangeDate: (date: Date) => void;
  className: string;
};

class DateTimePicker extends Component<Props, State> {
  state: State = {};
  dayPicker: DayPicker | null = null;

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.selectedDate &&
      (!prevProps.selectedDate ||
        (prevProps.selectedDate !== this.props.selectedDate &&
          (prevProps.selectedDate.getMonth() !==
            this.props.selectedDate.getMonth() ||
            prevProps.selectedDate.getFullYear() !==
              this.props.selectedDate.getFullYear())))
    ) {
      setImmediate(
        () =>
          this.dayPicker &&
          this.props.selectedDate &&
          this.dayPicker.showMonth(this.props.selectedDate)
      );
    }
  }

  renderNavBarElement = (data: {
    className: string;
    onPreviousClick: () => any;
    onNextClick: () => any;
  }) => {
    const { onPreviousClick, onNextClick, className } = data;

    return (
      <div className={className}>
        <Button
          color="primary"
          type="button"
          className="btn-icon-only"
          onClick={() => onPreviousClick()}
          style={{
            float: 'left',
          }}
        >
          <FontAwesomeIcon icon="angle-left" />
        </Button>

        <Button
          color="primary"
          type="button"
          className="btn-icon-only"
          onClick={() => onNextClick()}
          style={{
            float: 'right',
          }}
        >
          <FontAwesomeIcon icon="angle-right" />
        </Button>
      </div>
    );
  };

  onDayClick = (date: Date) => {
    const { onChangeDate } = this.props;

    onChangeDate(date);
  };

  renderDay = (date: Date) => <span>{date.getDate()}</span>;

  render() {
    const { selectedDate, className } = this.props;

    return (
      <>
        <DayPicker
          className={`DateTimePicker ${className}`}
          navbarElement={this.renderNavBarElement}
          renderDay={this.renderDay}
          fixedWeeks
          months={datetimeFormat.config().months}
          weekdaysShort={datetimeFormat.config().weekdays}
          selectedDays={selectedDate || undefined}
          onDayClick={this.onDayClick}
          initialMonth={selectedDate || new Date()}
          ref={(ref) => (this.dayPicker = ref)}
        />
      </>
    );
  }
}

export default DateTimePicker;
