import React from 'react';
import './Statistic.scss';
import { NavLink } from 'react-router-dom';
import FontAwesomeIcon from '../../../components/Icon/FontAwesomeIcon';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Props = {
  linkTo: string;
  icon: string;
  color: 'primary' | 'secondary' | 'danger';
  topCount: string;
  desc: string;
  addDesc: string;
  renderAdditionContent?: (() => React.ReactNode | null) | React.ReactNode | null;
};

const Statistic = ({ linkTo, icon, color, topCount, desc, addDesc, renderAdditionContent }: Props) => (
  <NavLink to={linkTo} className={`statistic ${color}`}>
    <div className="indicator">
      <FontAwesomeIcon icon={icon as IconProp} className="icon" />
    </div>
    <div className="content">
      <div className="top">{topCount}</div>
      <div className="desc">{desc}</div>
      <div className="addDesc">{addDesc}</div>
      {typeof renderAdditionContent === 'function' ? renderAdditionContent() : renderAdditionContent}
    </div>
  </NavLink>
);

export default Statistic;
