import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { ReduxState } from '../../reducers/types';

const PrivateRoute = ({ component: Component, isAuth, initAuth, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (initAuth) {
        return null;
      }

      if (isAuth) {
        return <Component {...props} />;
      }

      return (
        <Redirect to={`/login/?next=${encodeURIComponent(props.match.url)}`} />
      );
    }}
  />
);

const mapStateToProps = ({ auth: { init, isAuth } }: ReduxState) => ({
  isAuth,
  initAuth: init,
});

export default connect(mapStateToProps)(PrivateRoute);
