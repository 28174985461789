import React, { PureComponent } from 'react';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import './Hud.scss';
import LoadingIndicator from '../Icon/LoadingIndicator';
import FontAwesomeIcon from '../Icon/FontAwesomeIcon';

export type HudType = 'loading' | 'alert' | 'toast';

type Props = {
  type: HudType | null;
  alertType: 'error' | 'success' | null;
  alertTitle?: string | null;
  alertDesc?: string | null;
  alertBtnTitle?: string | null;
  loadingMessage?: string | null;
  subLoadingMessage?: string | null;
  loadingOnCancel?: () => any;
  loadingCancelLabel?: string | null;
  toastMessage?: string | null;
  toastTimeout?: number | null;
  isOpen: boolean;
  children?: any;
  toggle?: () => void;
};

class Hud extends PureComponent<Props> {
  static defaultProps = {
    alertType: 'success',
  };

  componentDidUpdate(prevProps: Props) {
    const { isOpen, type, toggle, toastTimeout } = this.props;

    if (
      type === 'toast' &&
      isOpen &&
      toastTimeout &&
      (!prevProps.isOpen || type !== prevProps.type)
    ) {
      setTimeout(() => {
        toggle && toggle();
      }, toastTimeout);
    }
  }

  renderBody = () => {
    const {
      type,
      children,
      alertTitle,
      alertDesc,
      alertBtnTitle,
      toggle,
      toastMessage,
      loadingMessage,
      subLoadingMessage,
      loadingOnCancel,
      loadingCancelLabel,
    } = this.props;

    switch (type) {
      case 'toast':
        return (
          <ModalBody>
            <FontAwesomeIcon icon="check-circle" size="4x" />
            {toastMessage && (
              <div className="msg font-weight-bold">{toastMessage}</div>
            )}
          </ModalBody>
        );
      case 'loading':
        return (
          <ModalBody>
            <LoadingIndicator />
            {loadingMessage && (
              <div className="msg font-weight-bold">{loadingMessage}</div>
            )}
            {subLoadingMessage && (
              <div className="msg font-weight-bold">{subLoadingMessage}</div>
            )}
            {loadingOnCancel && (
              <Button
                color="danger"
                className="btn-icon-only btn-sm mt-1"
                onClick={(e) => {
                  e.preventDefault();
                  loadingOnCancel();
                }}
              >
                {loadingCancelLabel || ''}
                <FontAwesomeIcon icon="times" size="sm" />
              </Button>
            )}
          </ModalBody>
        );
      case 'alert':
        return (
          <>
            <ModalBody>
              <div className="font-weight-medium">{alertTitle}</div>
              <div>{alertDesc}</div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" className="w-100" onClick={toggle}>
                {alertBtnTitle || 'ok'}
              </Button>
            </ModalFooter>
          </>
        );
      default:
        return children;
    }
  };

  render() {
    const { isOpen, type } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        centered
        fade={false}
        modalClassName={`modal-hud-${type || ''}`}
      >
        {this.renderBody()}
      </Modal>
    );
  }
}

export default Hud;
