import React from 'react';
import './ModalClose.scss';
import { Button } from 'reactstrap';
import FontAwesomeIcon from '../../Icon/FontAwesomeIcon';

type Props = { onClick: () => any };

const ModalClose = ({ onClick }: Props) => (
  <div className="modal-close">
    <Button color="primary" className="btn-icon-only" onClick={onClick}>
      <FontAwesomeIcon icon="times" size="lg" />
    </Button>
  </div>
);

export default ModalClose;
