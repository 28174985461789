import React, { Component, SyntheticEvent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Container, Row, Form, Col, Button } from 'reactstrap';
import '../auth.scss';
import TextField from '../../../components/TextField/TextField';
import validate from '../../../utils/validations/validate';
import isEmpty from '../../../utils/validations/isEmpty';
import authActions from '../../../actions/auth.actions';
import Hud from '../../../components/Hud/Hud';

type State = {
  email: string;
  isLoggingIn: boolean;
  isSuccess: boolean;
  logInError: string | null;
  errors: { [field: string]: string } | null;
};

const ForgotPasswordValidateConfig = {
  email: {
    require: true,
    email: true,
  },
};

type Props = RouteComponentProps<{}> & WithNamespaces;

class ForgotPassword extends Component<Props, State> {
  state: State = {
    email: '',
    isSuccess: false,
    errors: validate({}, ForgotPasswordValidateConfig),
    isLoggingIn: false,
    logInError: null,
  };

  onChangeField = (event: SyntheticEvent<HTMLInputElement>) => {
    this.setState(
      {
        email: event.currentTarget.value,
      },
      () => {
        this.setState({
          errors: validate(this.state, ForgotPasswordValidateConfig),
        });
      }
    );
  };

  onSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { email } = this.state;

    this.setState({
      isLoggingIn: true,
    });

    try {
      debugger;
      await authActions.requestResetPassword(email);

      debugger;
      this.setState({
        isLoggingIn: false,
        isSuccess: true,
        logInError: null,
      });
    } catch (error) {
      debugger;
      console.log(error.code);

      this.setState({
        isLoggingIn: false,
        logInError: error.code,
      });
    }
  };

  onClose = () => {
    const { history } = this.props;

    history.push('/login');
  };

  render() {
    const { t } = this.props;
    const { email, errors, isLoggingIn, logInError, isSuccess } = this.state;

    const forgotPasswordErrorMessages = {
      email: {
        require: t('USER_EMAIL_REQUIRED'),
        email: t('USER_EMAIL_INVALID'),
      },
    };

    let message: string | null = null;
    let alertTitle: string | null = null;

    if (logInError) {
      message = t('USER_FORGOT_PASSWORD_ERR_UNKNOWN');
      alertTitle = t('ALERT_ERROR');
    } else if (isSuccess) {
      message = t('USER_FORGOT_PASSWORD_SUCCESS');
      alertTitle = t('ALERT_SUCCESS');
    }

    return (
      <Container tag="main" role="main">
        <Hud
          isOpen={logInError != null || isLoggingIn || isSuccess}
          type={isLoggingIn ? 'loading' : 'alert'}
          alertBtnTitle={t('ALERT_OK')}
          alertTitle={alertTitle}
          alertDesc={message}
          toggle={this.onClose}
        />

        <Row>
          <Col
            xs={12}
            sm={{
              size: 8,
              offset: 2,
            }}
            lg={{
              size: 6,
              offset: 3,
            }}
            className=" d-flex flex-column align-items-center auth"
          >
            <h5 className="mt-md-3 font-weight-bold text-primary">{t('USER_FORGOT_PASSWORD_TITLE')}</h5>
            <p>{t('USER_FORGOT_PASSWORD_DESC')}</p>
            <Form className="w-100" onSubmit={this.onSubmit}>
              <TextField
                type="email"
                name="email"
                placeholder={t('USER_EMAIL')}
                value={email}
                onChange={this.onChangeField}
                disabled={isLoggingIn}
                invalid={!!errors && errors.email != null}
                invalidMessage={!!errors && !!errors.email && forgotPasswordErrorMessages.email[errors.email]}
              />
              <Button type="submit" color="primary" className="w-100" disabled={isLoggingIn || !isEmpty(errors)}>
                {t('USER_FORGOT_PASSWORD_BTN')}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default withNamespaces()(ForgotPassword);
