import React, { Component, SyntheticEvent } from 'react';
import './DateTimeInput.scss';
import { Button, Row, Col, FormGroup, Label, CustomInput } from 'reactstrap';
import DateTimePicker from './DateTimePicker/DateTimePicker';
import TextField from '../TextField/TextField';
import datetimeFormat from '../../utils/datetime/datetimeFormat';
import FontAwesomeIcon from '../Icon/FontAwesomeIcon';
import padNumber from '../../utils/padNumber';

type Props = {
  name: string;
  label: string;
  value: Date;
  onChange: (date: Date) => void;
};

type State = {
  dateStr: string;
  date: Date | null;
  pickerShowing: boolean;
};

export default class DateTimeInput extends Component<Props, State> {
  state: State = {
    dateStr: '',
    date: new Date(),
    pickerShowing: false,
  };

  constructor(props: Props, context: any) {
    super(props, context);

    if (props.value) {
      this.state.date = props.value;
      this.state.dateStr = datetimeFormat.dateToLocaleString(props.value);
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (nextState !== this.state) return true;

    const { value } = this.props;
    const { date } = this.state;

    if (nextProps.value !== value && nextProps.value !== date) {
      return true;
    }

    return false;
  }

  componentDidUpdate(_: Props, prevState: State) {
    const { date } = this.state;

    if (prevState.date !== date && date != null) {
      const { onChange } = this.props;

      onChange(date);
    }
  }

  onDatePickerChange = (newDate: Date) => {
    this.setState({
      date: newDate,
      dateStr: datetimeFormat.dateToLocaleString(newDate),
    });
  };

  onDateStrChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const dateStr = event.currentTarget.value;
    const newDate = datetimeFormat.parseToDate(dateStr);

    this.setState({
      date: newDate,
      dateStr,
    });
  };

  togglePicker = () => {
    this.setState((state) => ({
      pickerShowing: !state.pickerShowing,
    }));
  };

  renderMinSelectOptions = () =>
    Array.from({
      length: 12,
    }).map((_, i) => (
      <option key={i} value={i * 5}>
        {padNumber(i * 5, 2)}
      </option>
    ));

  renderHourSelectOptions = () =>
    Array.from({
      length: 24,
    }).map((_, i) => (
      <option key={i} value={i}>
        {padNumber(i, 2)}
      </option>
    ));

  onTimeFieldChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { date } = this.state;

    if (!date) return;

    const newDate = new Date(date);

    const { name, value } = event.currentTarget;
    const number = parseInt(value, 10);

    switch (name) {
      case 'selectedHour': {
        newDate.setHours(number);

        break;
      }
      case 'selectedMin': {
        newDate.setMinutes(number);

        break;
      }
      default:
        return;
    }

    this.setState({
      date: newDate,
      dateStr: datetimeFormat.dateToLocaleString(newDate),
    });
  };

  renderTimePicker = () => {
    const { date } = this.state;

    const selectedHour = date ? date.getHours() : 0;
    const selectedMin = date ? date.getMinutes() : 0;

    return (
      <Row form>
        <Col xs={6}>
          <FormGroup>
            <Label for="selectedHour">
              {datetimeFormat.config().timeLabels.hour}
            </Label>
            <CustomInput
              type="select"
              name="selectedHour"
              id="selectedHour"
              className="center uppercase"
              value={selectedHour}
              onChange={this.onTimeFieldChange}
            >
              {this.renderHourSelectOptions()}
            </CustomInput>
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label for="selectedMin">
              {datetimeFormat.config().timeLabels.min}
            </Label>
            <CustomInput
              type="select"
              name="selectedMin"
              id="selectedMin"
              className="center uppercase"
              value={selectedMin}
              onChange={this.onTimeFieldChange}
            >
              {this.renderMinSelectOptions()}
            </CustomInput>
          </FormGroup>
        </Col>
      </Row>
    );
  };

  render() {
    const { date, dateStr, pickerShowing } = this.state;
    const { name, label } = this.props;
    return (
      <>
        <TextField
          label={label}
          type="text"
          name={name}
          placeholder={datetimeFormat.config().maskPlaceholder}
          value={dateStr}
          onChange={this.onDateStrChange}
          formGroupClassname={`date-input-form-group ${
            pickerShowing ? 'picker-showing' : ''
          }`}
          inputGroup="right"
          mask={datetimeFormat.config().maskInput}
        >
          <Button
            color="primary"
            type="button"
            className="btn-icon-only"
            onClick={this.togglePicker}
          >
            <FontAwesomeIcon icon="calendar-alt" />
          </Button>
        </TextField>

        {pickerShowing && (
          <>
            <DateTimePicker
              className="mb-2"
              selectedDate={date}
              onChangeDate={this.onDatePickerChange}
            />
            {this.renderTimePicker()}
          </>
        )}
      </>
    );
  }
}
