import React, { PureComponent } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Modal, ModalBody } from 'reactstrap';
import giftActions from '../../../actions/gift.actions';
import Hud, { HudType } from '../../../components/Hud/Hud';
import FontAwesomeIcon from '../../../components/Icon/FontAwesomeIcon';
import ModalClose from '../../../components/Modal/ModalClose/ModalClose';
import ProductInfoBasic from '../../../components/ProductInfo/Basic/ProductInfoBasic';
import ProductInfoPrice from '../../../components/ProductInfo/Price/ProductInfoPrice';
import Quoteditable from '../../../components/Quoteditable/Quoteditable';
import UserPhoto from '../../../components/UserPhoto/UserPhoto';
import { ReduxState } from '../../../reducers/types';
import './GiftDetailModal.scss';

const mapStateToProps = ({ gift, auth: { isAuth, uid } }: ReduxState) => ({
  ...gift,
  isAuth,
  uid,
});

type Props = ReturnType<typeof mapStateToProps> & {
  onClose: () => void;
} & {
  selectGift: typeof giftActions.selectGift;
  acceptGift: typeof giftActions.acceptGift;
} & Pick<RouteComponentProps<{ chainsureId: string; giftCode: string }>, 'history'> &
  WithNamespaces;

class GiftDetailModal extends PureComponent<Props> {
  renderTop = () => {
    const { chainsure, t } = this.props;

    if (!chainsure) return null;

    const { user } = chainsure;

    return (
      <div className="w-100 justify-content-center align-items-center mb-2 d-flex flex-column">
        <div className="mb-3 mt-1">
          <FontAwesomeIcon icon="gift" size="3x" />
        </div>
        <div className="gift-sender-photo mb-3">
          <UserPhoto photoURL={user.photoURL} />
        </div>
        <div className="gift-sender-name mb-1">{user.displayName}</div>
        <div className="gift-msg mb-3">{t('GIFT_DESCRIPTION')}</div>
        {chainsure.giftComment && (
          <Quoteditable content={chainsure.giftComment} disabled className="w-100 gift-comment" />
        )}
      </div>
    );
  };

  renderGiftDetail = () => {
    const { chainsure } = this.props;

    if (!chainsure) return null;

    const { product, amount, currency, startDate, endDate, isImmediate } = chainsure;

    return (
      <>
        <ProductInfoBasic
          {...{
            ...product,
            price: amount,
          }}
        />
        {product.type !== 'free' && (
          <ProductInfoPrice
            price={amount}
            currency={currency}
            titleKey="CHAINSURE_USE_ON"
            isImmediate={isImmediate}
            dateRange={{
              start: startDate,
              end: endDate,
            }}
          />
        )}
      </>
    );
  };

  onAcceptGift = () => {
    const { isAuth, acceptGift, history, uid, chainsure, chainsureId, giftCode } = this.props;

    if (isAuth && chainsure && chainsureId && giftCode) {
      if (uid !== chainsure.uid) {
        acceptGift(chainsureId, giftCode);
      }
    } else {
      history.push(`/gift/c/${chainsureId}/g/${giftCode}/accept`);
    }
  };

  onClose = () => {
    const { history, isSuccess, onClose, chainsureId, selectGift } = this.props;

    if (isSuccess) {
      selectGift(null, null);

      history.replace(`/inventories/c/${chainsureId}`);
    } else {
      onClose();
    }
  };

  render() {
    const { chainsure, giftCode, chainsureId, isAuth, error, isRequesting, isSuccess, t } = this.props;

    if (!chainsureId && !giftCode) {
      return null;
    }

    const isLoading = (chainsure == null && error == null) || isRequesting;
    const hubIsOpen = isRequesting || error != null || isSuccess || chainsure == null;

    let alertTitle: string | null = null;
    let alertDesc: string | null = null;
    let hudType: HudType | null = null;

    if (isLoading) {
      hudType = 'loading';
    } else if (isSuccess) {
      hudType = 'toast';
      alertDesc = t('GIFT_ACCEPT_SUCCESS');
    } else if (error) {
      hudType = 'alert';
      alertTitle = t('ALERT_ERROR');
      alertDesc = t('GIFT_ACCEPT_ERROR');
    }

    return (
      <>
        <Hud
          isOpen={hubIsOpen}
          type={hudType}
          alertBtnTitle={t('ALERT_OK')}
          alertTitle={alertTitle}
          alertDesc={alertDesc}
          toastMessage={alertDesc}
          toastTimeout={1000}
          toggle={this.onClose}
        />
        {chainsure != null && (
          <Modal
            isOpen={chainsureId != null}
            toggle={this.onClose}
            centered
            modalClassName="modal-product-detail gift on-modal"
          >
            <ModalClose onClick={this.onClose} />
            {this.renderTop()}
            <ModalBody>
              <Button type="button" color="primary" className="w-100 " onClick={this.onAcceptGift}>
                {t(isAuth ? 'GIFT_ACCEPT' : 'GIFT_ACCEPT_LOGIN')}
              </Button>
            </ModalBody>
          </Modal>
        )}
      </>
    );
  }
}

export default withNamespaces()(
  connect(mapStateToProps, {
    selectGift: giftActions.selectGift,
    acceptGift: giftActions.acceptGift,
  })(GiftDetailModal)
);
