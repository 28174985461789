import { OwnedState } from './types';
import { Action } from '../actions/types';
import { Chainsure } from '../types';

const INITIAL_STATE: OwnedState = {
  isLoading: false,
  chainsures: null,
  canLoadMore: true,
};

const reducer = (state: OwnedState = INITIAL_STATE, action: Action): OwnedState => {
  switch (action.type) {
    case 'OWNED_CHAINSURES_LOADING': {
      const { silent } = action.payload;
      return {
        ...state,
        isLoading: silent ? state.isLoading : true,
      };
    }

    case 'OWNED_CHAINSURES_LOADED': {
      const { chainsures, canLoadMore, refresh } = action.payload;

      return {
        ...state,
        isLoading: false,
        canLoadMore,
        chainsures: refresh ? chainsures : [...(state.chainsures || []), ...chainsures],
      };
    }
    case 'OWNED_CHAINSURE_SELECTED': {
      const { chainsureId } = action.payload;

      // always set chainsure to null here, because we need to get the current status of chainsure

      return {
        ...state,
        chainsureId,
        chainsure: null,
        chainsureError: null,
        action: null,
        requestStatus: null,
        requestError: null,
      };
    }

    case 'OWNED_CHAINSURE_DETAIL_LOADED': {
      const { chainsure } = action.payload;

      let newChainsures: Chainsure[] | null = null;

      if (state.chainsures != null && !state.isLoading) {
        const idx = state.chainsures.findIndex((c) => c.id === chainsure.id);

        if (idx !== -1) {
          newChainsures = [...state.chainsures];
          newChainsures[idx] = chainsure;
        }
      }

      if (state.chainsureId === chainsure.id || newChainsures != null) {
        const newState = {
          ...state,
        };

        if (state.chainsureId === chainsure.id) {
          newState.chainsure = chainsure;
        }

        if (newChainsures != null) {
          newState.chainsures = newChainsures;
        }

        return newState;
      }

      return state;
    }

    case 'OWNED_CHAINSURE_REQUEST_ACCEPT_SUCCESS': {
      // should refresh
      return {
        ...INITIAL_STATE,
      };
    }

    case 'OWNED_CHAINSURE_DETAIL_ERROR': {
      const { error, chainsureId } = action.payload;

      if (state.chainsureId === chainsureId) {
        let { chainsures } = state;

        if (error && error.code === 'WRONG_PERMISSION' && chainsures) {
          // Remove WRONG_PERMISSION chainsure from list
          const idx = chainsures.findIndex((x) => x.id === state.chainsureId);

          if (idx !== -1) {
            chainsures = [...chainsures];
            chainsures.splice(idx, 1);
          }
        }

        return {
          ...state,
          chainsureError: error,
          chainsures,
        };
      }

      return state;
    }

    case 'CHAINSURE_DETAIL_ERROR': {
      const { error } = action.payload;

      if (error && error.code === 'WRONG_PERMISSION') {
        // WRONG_PERMISSION on owned list => should refresh

        return {
          ...INITIAL_STATE,
        };
      }

      return state;
    }

    case 'OWNED_CHAINSURE_REQUESTING': {
      return {
        ...state,
        requestStatus: 'requesting',
      };
    }

    case 'OWNED_CHAINSURE_REQUESTED_SUCCESS': {
      return {
        ...state,
        requestStatus: 'success',
      };
    }

    case 'OWNED_CHAINSURE_REQUESTED_ERROR': {
      const { error } = action.payload;

      return {
        ...state,
        requestStatus: 'error',
        requestError: error,
      };
    }

    case 'OWNED_CHAINSURE_TOGGLE_REQUEST': {
      const alreadyOnRequest = state.action === 'request';

      return {
        ...state,
        action: alreadyOnRequest ? null : 'request',
        requestStatus: null,
        requestError: null,
      };
    }

    case 'OWNED_CHAINSURE_CLEAN_ERROR': {
      return {
        ...state,
        chainsureError: null,
        requestStatus: null,
        requestError: null,
      };
    }

    case 'AUTH_STATE_CHANGED': {
      const { user } = action.payload;

      if (user) return state;

      return {
        ...INITIAL_STATE,
      };
    }

    default:
      return state;
  }
};

export default reducer;
