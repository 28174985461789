import React from 'react';
import './GiftAvailable.scss';
import { Button, UncontrolledTooltip } from 'reactstrap';
import FontAwesomeIcon from '../../Icon/FontAwesomeIcon';
import { i18nTranslator } from '../../../utils/i18n';

type Props = {
  chainsureId: string;
  giftCode?: string;
  giftSent?: boolean;
  requestSent?: boolean;
  hasRequests?: boolean;
  tooltipPlacement: 'bottom' | 'right';
  className?: string;
};

const GiftAvailable = ({
  giftCode,
  giftSent,
  requestSent,
  hasRequests,
  className,
  chainsureId,
  tooltipPlacement,
}: Props) => {
  if (!giftCode && !giftSent && !requestSent && !hasRequests) {
    return null;
  }

  const giftCodeId = `gift-avail-gift-${className || ''}-${chainsureId}`;
  const giftTagId = `gift-avail-tag-${className || ''}-${chainsureId}`;
  const requestId = `request-${className || ''}-${chainsureId}`;

  return (
    <div className={`gift-vailable ${className || ''}`}>
      {giftCode && (
        <>
          <Button color="secondary" className="btn-icon-only" id={giftCodeId}>
            <FontAwesomeIcon icon="gift" size="lg" />
          </Button>
          <UncontrolledTooltip placement={tooltipPlacement} target={giftCodeId}>
            {i18nTranslator('CHAINSURE_GIFT_CREATED')}
          </UncontrolledTooltip>
        </>
      )}
      {giftSent && (
        <>
          <Button color="secondary" className="btn-icon-only" id={giftTagId}>
            <FontAwesomeIcon icon="user-tag" size="lg" />
          </Button>
          <UncontrolledTooltip placement={tooltipPlacement} target={giftTagId}>
            {i18nTranslator('CHAINSURE_GIFT_SENT')}
          </UncontrolledTooltip>
        </>
      )}
      {(requestSent || hasRequests) && (
        <>
          <Button color="secondary" className="btn-icon-only" id={requestId}>
            <FontAwesomeIcon icon="user-check" size="lg" />
          </Button>
          <UncontrolledTooltip placement={tooltipPlacement} target={requestId}>
            {i18nTranslator(
              hasRequests ? 'CHAINSURE_HAS_REQUESTS' : 'CHAINSURE_REQUEST_SENT'
            )}
          </UncontrolledTooltip>
        </>
      )}
    </div>
  );
};

export default GiftAvailable;
