import React, { PureComponent, ReactNode } from 'react';
import './Footer.scss';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import Logo from '../Logo/Logo';

type Props = WithNamespaces;

class Footer extends PureComponent<Props> {
  sections: {
    title: string;
    links: {
      title: string;
      to?: string;
      href?: string;
      onClick?: (e: any) => void;
    }[];
  }[] = [];

  render() {
    const { t } = this.props;
    const sections = [
      {
        title: t('FOOTER_ABOUT'),
        links: [
          {
            title: t('FOOTER_ABOUT_TEAM'),
            to: '/about#team',
          },
          {
            title: t('FOOTER_ABOUT_BLOG'),
            href: 'https://chainsure.plids.com',
          },
          {
            title: t('FOOTER_ABOUT_CONTACT'),
            href: 'mailto:info@chainsure.de',
          },
        ],
      },
      {
        title: t('FOOTER_HELP'),
        links: [
          {
            title: t('FOOTER_HELP_FAQS'),
            to: '/about',
          },
          {
            title: t('FOOTER_HELP_TERM'),
            to: '/terms-of-use',
          },
          {
            title: t('FOOTER_HELP_PRIVACY'),
            to: '/privacy',
          },
        ],
      },
      {
        title: t('FOOTER_SOCIALS'),
        links: [
          {
            title: 'Facebook',
            href: 'https://www.facebook.com/chainsur',
          },
          {
            title: 'Twitter',
            href: 'https://twitter.com/chainsure',
          },
          {
            title: 'Youtube',
            href: 'https://www.youtube.com/channel/UC95DxBx9--kuAq520OsXzwg',
          },
        ],
      },
      {
        title: t('FOOTER_LANGUAGES'),
        links: ['en', 'de'].map((lng) => ({
          title: t(`FOOTER_LANGUAGES_${lng.toUpperCase()}`),
          onClick: (e) => {
            const { i18n } = this.props;

            i18n.changeLanguage(lng);

            e.preventDefault();
          },
        })),
      },
    ];

    const cols = sections.map((section) => (
      <Col xs={12} md={2} key={section.title} className="mb-3 mb-md-0">
        <h4>{section.title}</h4>
        {(section.links as {
          title: string;
          to?: string;
          href?: string;
          onClick?: () => void;
        }[]).map((link) => {
          let content: ReactNode = null;

          if (link.onClick) {
            content = (
              <a href="./" className="d-inline-block" target="_blank" rel="noopener noreferrer" onClick={link.onClick}>
                {link.title}
              </a>
            );
          }

          if (link.to) {
            content = (
              <Link to={link.to} className="d-inline-block">
                {link.title}
              </Link>
            );
          }

          if (link.href) {
            content = (
              <a href={link.href} className="d-inline-block" target="_blank" rel="noopener noreferrer">
                {link.title}
              </a>
            );
          }

          return (
            <div key={link.title} className="d-inline-block d-md-block mr-3 mr-md-0 text-truncate">
              {content}
            </div>
          );
        })}
      </Col>
    ));

    return (
      <footer className="footer">
        <Container>
          <Row>
            <Col xs={12} md={4} className="text-center text-md-left">
              <div>
                <Logo />
              </div>
              <div>
                <Link className="font-weight-bold" to="/">
                  chainsure.de
                </Link>
              </div>
              <div>
                <a href="mailto:info@chainsure.de" target="_top">
                  info@chainsure.de
                </a>
              </div>
            </Col>
            {cols}
          </Row>
          <div className="text-center mt-0 mt-md-3 text-primary">
            {t('FOOTER_COPYRIGHT', {
              year: new Date().getFullYear(),
            })}
          </div>
        </Container>
      </footer>
    );
  }
}

export default withNamespaces()(Footer);
