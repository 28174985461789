import { currLanguage } from './i18n';

const toLocaleString = (value?: number) =>
  (value || 0).toLocaleString(currLanguage(), {
    maximumFractionDigits: 2,
    minimumFractionDigits: 1,
    useGrouping: true,
  });

const toLocaleCurrencyString = (currency?: string, value?: number) =>
  (value || 0).toLocaleString(currLanguage(), {
    maximumFractionDigits: 2,
    minimumFractionDigits: 1,
    currency: currency || 'USD',
    style: 'currency',
    useGrouping: true,
  });

export default {
  toLocaleString,
  toLocaleCurrencyString,
};
