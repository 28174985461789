import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import reduxStore from '../../reduxStore';
import Home from '../Home/Home';
import LogIn from '../Auth/LogIn/LogIn';
import SignUp from '../Auth/SignUp/SignUp';
import Offers from '../Offers/Offers';
import Inventories from '../Inventories/Inventories';
import Damages from '../Damages/Damages';
import PrivateRoute from '../../components/Route/PrivateRoute';
import AnonymousRoute from '../../components/Route/AnonymousRoute';
import Gift from '../Gift/Gift';
import ResetPassword from '../Auth/ResetPassword/ResetPassword';
import ForgotPassword from '../Auth/ForgotPassword/ForgotPassword';
import StaticRoute from '../../components/Route/StaticRoute';
import AsyncComponent from '../../components/AsyncComponent/AsyncComponent';
import Dashboard from '../Dashboard/Dashboard';
import Showcase from '../Showcase/Showcase';
import OwnedInventories from '../OwnedInventories/OwnedInventories';

const SettingsAsync = AsyncComponent(() =>
  import('../Settings/Settings').then(module => module.default)
);

const App = () => (
  <Provider store={reduxStore.store}>
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/app" component={Showcase} />
          <StaticRoute path="/about" exact page="about" />
          <StaticRoute path="/help" exact page="help" />
          <StaticRoute path="/terms-of-use" exact page="terms-of-use" />
          <StaticRoute path="/privacy" exact page="privacy" />
          <StaticRoute path="/how-it-works" exact page="how-it-works" />
          <AnonymousRoute path="/login" component={LogIn} />
          <AnonymousRoute path="/signup" component={SignUp} />
          <AnonymousRoute path="/reset-password" component={ResetPassword} />
          <AnonymousRoute path="/forgot-password" component={ForgotPassword} />
          <PrivateRoute
            path="/dashboard/p/:productId"
            exact
            component={Dashboard}
          />
          <PrivateRoute
            path="/dashboard/gift/c/:chainsureId/g/:giftCode"
            exact
            component={Dashboard}
          />
          <PrivateRoute
            path="/dashboard/c/:chainsureId"
            exact
            component={Dashboard}
          />
          <PrivateRoute path="/dashboard" exact component={Dashboard} />
          <PrivateRoute path="/offers" exact component={Offers} />
          <PrivateRoute path="/offers/p/:productId" exact component={Offers} />
          <PrivateRoute
            path="/inventories/c/:chainsureId"
            exact
            component={Inventories}
          />
          <PrivateRoute path="/inventories" exact component={Inventories} />
          <PrivateRoute
            path="/joined/c/:chainsureId"
            exact
            component={OwnedInventories}
          />
          <PrivateRoute path="/joined" exact component={OwnedInventories} />
          <PrivateRoute path="/payouts/c/:damageId" exact component={Damages} />
          <PrivateRoute path="/payouts" exact component={Damages} />
          <Route
            path="/gift/c/:chainsureId/g/:giftCode"
            exact
            component={Gift}
          />
          <PrivateRoute
            path="/gift/c/:chainsureId/g/:giftCode/accept"
            exact
            component={Gift}
          />
          <PrivateRoute path="/settings" component={SettingsAsync} />
          <Route component={Home} />
        </Switch>
      </Layout>
    </BrowserRouter>
  </Provider>
);

export default App;
