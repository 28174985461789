import React, { PureComponent } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import Photo from '../../components/Photo/Photo';
import './Home.scss';

type Props = WithNamespaces;

type HomeContent = {
  photoURL: string;
  title: string;
  description: string;
  btnTitle: string;
  btnLink: string;
};

class Home extends PureComponent<Props> {
  renderTop = ({ photoURL, title, description, btnTitle, btnLink }: HomeContent) => (
    <Row noGutters>
      <Col>
        <div className="rounded top">
          <div
            className="top-bg"
            style={{
              backgroundImage: `url(${photoURL})`,
            }}
          />
          {/* <img src={photoURL} className="img-fluid" alt="how it works" /> */}
          <Row noGutters>
            <Col
              lg={{
                size: 8,
                offset: 2,
              }}
              xs={{
                size: 10,
                offset: 1,
              }}
              className="content pt-4 pb-4 col-lg-8 offset-lg-2 d-flex flex-column justify-content-center align-items-center "
            >
              <div className="font-weight-bold title mt-3">{title}</div>
              <div className="descripiton mt-2 pl-md-5 pr-md-5">{description}</div>
              <div className="mt-3 mb-3">
                <Button color="primary" tag={NavLink} to={btnLink}>
                  {btnTitle}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );

  renderSections = (sections: HomeContent[]) =>
    sections.map(({ photoURL, title, description, btnLink, btnTitle }: HomeContent, index) => {
      const odd = index % 2 === 1;
      return (
        <Row key={photoURL} noGutters className={`mt-3 ${odd ? 'flex-row-reverse' : ''} section`}>
          <Col
            lg={{
              size: 4,
              offset: odd ? 1 : 0,
            }}
            sm={{
              size: 6,
              offset: odd ? 1 : 0,
            }}
            xs={{
              size: 4,
              offset: odd ? 1 : 0,
            }}
          >
            <Photo photoURL={photoURL} photoClassName="rounded" />
          </Col>
          <Col
            lg={{
              size: 7,
              offset: odd ? 0 : 1,
            }}
            sm={{
              size: 5,
              offset: odd ? 0 : 1,
            }}
            xs={{
              size: 7,
              offset: odd ? 0 : 1,
            }}
            className={`p-lg-4 d-flex flex-column justify-content-center align-items-${
              odd ? 'start' : 'end'
            } align-items-lg-center text-${odd ? 'left' : 'right'} text-lg-center`}
          >
            <div className="title font-weight-bold">{title}</div>
            <div className="description mt-2">{description}</div>
            {btnTitle && (
              <div className={`mt-2 mb-2 w-100 text-${odd ? 'left' : 'right'} text-lg-center `}>
                <Button color="primary" tag={NavLink} to={btnLink}>
                  {btnTitle}
                </Button>
              </div>
            )}
          </Col>
        </Row>
      );
    });

  renderBottom = ({ btnTitle, btnLink, description }: HomeContent) => (
    <Row noGutters className="bottom">
      <Col className="text-center">
        <Row noGutters>
          <Col
            lg={{
              size: 8,
              offset: 2,
            }}
            xs={{
              size: 10,
              offset: 1,
            }}
            className="pt-4 pb-4 col-lg-8 offset-lg-2 text-center"
          >
            <div className="mt-3 description">{description}</div>
            <div className="mt-3 mb-3">
              <Button color="primary" tag={NavLink} to={btnLink}>
                {btnTitle}
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );

  render() {
    const { t } = this.props;

    const contents: HomeContent[] = t('HOME', {
      returnObjects: true,
    });

    return (
      <Container tag="main" role="main" className="home">
        {this.renderTop(contents[0])}
        {this.renderSections(contents.slice(1, contents.length - 1))}
        {this.renderBottom(contents[contents.length - 1])}
      </Container>
    );
  }
}

export default withNamespaces('home')(Home);
