import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import './StaticPage.scss';
import { currLanguage } from '../../utils/i18n';
import httpClient from '../../utils/httpClient';

type State = {
  __html: string;
  lang: string;
};
type Props = { page: string } & WithNamespaces;

class StaticPage extends Component<Props, State> {
  state: State = {
    __html: '',
    lang: '',
  };

  async componentDidMount() {
    await this.loadContent();
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    const { lang } = this.state;
    const { page } = this.props;

    if (this.state !== nextState) {
      return true;
    }

    if (nextProps.page !== page || currLanguage() !== lang) {
      setImmediate(this.loadContent);
    }

    return false;
  }

  loadContent = async () => {
    const { page } = this.props;
    const lang = currLanguage();
    const response = await httpClient.pages.get<string>(`/${lang}/${page}`);

    window.scrollTo(0, 0);

    this.setState({
      lang,
      __html: response.data,
    });
  };

  render() {
    const { __html } = this.state;

    return (
      <Container
        tag="main"
        role="main"
        className="static-container"
        dangerouslySetInnerHTML={{
          __html,
        }}
      />
    );
  }
}

export default withNamespaces()(StaticPage);
