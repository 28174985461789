import { ThunkAction, Action } from './types';
import { Damage } from '../types';
import httpClient from '../utils/httpClient';
import datetimeFormat from '../utils/datetime/datetimeFormat';

const getAll = (silent?: boolean, lastItem?: Damage | null): ThunkAction => async (dispatch) => {
  dispatch({
    type: 'DAMAGES_LOADING',
    payload: {
      silent,
    },
  });

  const size = 6;
  let endPoint = `/damages?size=${size}`;

  if (lastItem) {
    endPoint += `&date=${datetimeFormat.timestampToDate(lastItem.createdAt).toISOString()}`;
  }

  const { data: damages } = await httpClient.app.get<Damage[]>(endPoint);

  dispatch({
    type: 'DAMAGES_LOADED',
    payload: {
      damages,
      canLoadMore: damages.length === size,
      refresh: !lastItem,
    },
  });
};

const selectDamage = (damageId: string | null): Action => ({
  type: 'DAMAGE_SELECTED',
  payload: {
    damageId,
  },
});

const getDamageDetail = (damageId: string): ThunkAction => async (dispatch) => {
  try {
    const response = await httpClient.app.get<Damage>(`/damages/c/${damageId}`);

    dispatch({
      type: 'DAMAGE_DETAIL_LOADED',
      payload: {
        damage: response.data,
      },
    });

    dispatch({
      type: 'CHAINSURE_HISTORIES_INIT',
      payload: {
        chainsure: response.data,
        type: 'damage',
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export default {
  getAll,
  selectDamage,
  getDamageDetail,
};
