import React from 'react';
import datetimeFormat from '../../utils/datetime/datetimeFormat';
import { Timestamp } from '../../types';

type Props = {
  startDate: string | Timestamp;
  endDate: string | Timestamp;
  textClassname?: string | null;
  labelOnly?: string | null;
};

const DateRangeLabel = (props: Props) => {
  const { startDate, endDate, textClassname, labelOnly } = props;
  const startDateValue = datetimeFormat.timestampToDate(startDate);
  const endDateValue = datetimeFormat.timestampToDate(endDate);

  const renderContent = () => {
    if (labelOnly) {
      return labelOnly;
    }

    if (
      endDateValue.getDate() === startDateValue.getDate() &&
      endDateValue.getMonth() === startDateValue.getMonth()
    ) {
      const dateLabel = datetimeFormat.dateToLocaleString(
        startDateValue,
        'date-trim-year'
      );
      const startTime = datetimeFormat.dateToLocaleString(
        startDateValue,
        'time'
      );

      if (startDateValue.getTime() === endDateValue.getTime()) {
        return (
          <>
            <div className="mr-1">{`${dateLabel} ${startTime}`}</div>
          </>
        );
      }

      const endTime = datetimeFormat.dateToLocaleString(endDateValue, 'time');

      return (
        <>
          <div className="mr-1">{dateLabel}</div>
          <div className="date-range-label-separator" />
          <div>{`${startTime} - ${endTime}`}</div>
        </>
      );
    }

    return (
      <>
        <div>
          {datetimeFormat.dateToLocaleString(startDateValue, 'full-trim-year')}
        </div>
        <div className="date-range-label-separator" />
        <div>
          {datetimeFormat.dateToLocaleString(endDateValue, 'full-trim-year')}
        </div>
      </>
    );
  };

  return (
    <div className={`${textClassname || ''} d-flex flex-column`}>
      {renderContent()}
    </div>
  );
};

export default DateRangeLabel;
