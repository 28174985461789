import React, { PureComponent, SyntheticEvent } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Alert, Col, Container, Popover, PopoverBody, Row } from 'reactstrap';
import chainsureAction from '../../actions/chainsure.action';
import dashboardActions from '../../actions/dashboard.actions';
import giftActions from '../../actions/gift.actions';
import productActions from '../../actions/product.actions';
import FontAwesomeIcon from '../../components/Icon/FontAwesomeIcon';
import ProductInfoListItem from '../../components/ProductInfo/ListItem/ProductInfoListItem';
import ProductInfoListItemLoading from '../../components/ProductInfo/ListItem/ProductInfoListItemLoading';
import UserPhoto from '../../components/UserPhoto/UserPhoto';
import { ReduxState } from '../../reducers/types';
import { Chainsure, Timestamp } from '../../types';
import contentFromPattern from '../../utils/contentFromPattern';
import datetimeFormat from '../../utils/datetime/datetimeFormat';
import numberFormat from '../../utils/numberFormat';
import GiftDetailModal from '../Gift/DetailModal/GiftDetailModal';
import ChainsureDetailModal from '../Inventories/Chainsure/DetailModal/ChainsureDetailModal';
import ChainsureListItem from '../Inventories/Chainsure/ListItem/ChainsureListItem';
import ProductDetailModal from '../Offers/Product/DetailModal/ProductDetailModal';
import './Dashboard.scss';
import Statistic from './Statistic/Statistic';

const mapStateToProps = ({
  dashboard,
  products: { selectedProductId },
  chainsures: { chainsureId },
  gift: { chainsureId: selectedGiftChainsureId, giftCode: selectedGiftCode },
}: ReduxState) => ({
  ...dashboard,
  selectedProductId,
  selectedChainsureId: chainsureId,
  selectedGiftChainsureId,
  selectedGiftCode,
});

type Props = RouteComponentProps<{
  productId: string;
  chainsureId: string;
  giftCode: string;
}> &
  RouteComponentProps<{}> &
  WithNamespaces & {
    getUserOveralStatistics: typeof dashboardActions.getUserOveralStatistics;
    getProducts: typeof dashboardActions.getProducts;
    switchProductMode: typeof dashboardActions.switchProductMode;
    getUsedChainsures: typeof dashboardActions.getUsedChainsures;
    getSentToChainsures: typeof dashboardActions.getSentToChainsures;
    getPendingRequestChainsures: typeof dashboardActions.getPendingRequestChainsures;
    setDefaultCurreny: typeof dashboardActions.setDefaultCurreny;
    selectProduct: typeof productActions.selectProduct;
    selectChainsure: typeof chainsureAction.selectChainsure;
    selectGift: typeof giftActions.selectGift;
  } & ReturnType<typeof mapStateToProps>;

type State = {
  showingMoneyStatPopUp: boolean;
};

class Dashboard extends PureComponent<Props, State> {
  state: State = {
    showingMoneyStatPopUp: false,
  };

  componentDidMount() {
    const {
      getUserOveralStatistics,
      getProducts,
      productMode,
      topProducts,
      latestProducts,
      isLoadingProducts,
      match,
      selectProduct,
      isLoadingChainsures,
      usedChainsures,
      sentToChainsures,
      pendingRequestChainsures,
      getUsedChainsures,
      getSentToChainsures,
      getPendingRequestChainsures,
      selectChainsure,
      selectGift,
    } = this.props;

    if (usedChainsures == null && !isLoadingChainsures) {
      getUsedChainsures();
    }

    if (sentToChainsures == null) {
      getSentToChainsures();
    }

    if (pendingRequestChainsures == null) {
      getPendingRequestChainsures();
    }

    getUserOveralStatistics();

    if (
      !isLoadingProducts &&
      ((productMode === 'top' && topProducts == null) || (productMode === 'latest' && latestProducts == null))
    ) {
      getProducts(productMode);
    }

    const { productId, chainsureId, giftCode } = match.params;

    if (productId) {
      selectProduct(productId);
    }

    if (chainsureId) {
      if (giftCode) {
        selectGift(chainsureId, giftCode);

        return;
      }

      selectChainsure(chainsureId);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      getProducts,
      productMode,
      topProducts,
      latestProducts,
      isLoadingProducts,
      match,
      selectedProductId,
      selectProduct,
      selectedChainsureId,
      selectedGiftCode,
      selectGift,
      selectChainsure,
      shouldRefreshChainsures,
      getUsedChainsures,
      pendingRequestChainsures,
      getPendingRequestChainsures,
      sentToChainsures,
      getSentToChainsures,
      lng,
    } = this.props;

    if (
      !isLoadingProducts &&
      ((productMode === 'top' && topProducts == null) || (productMode === 'latest' && latestProducts == null))
    ) {
      getProducts(productMode);
    }

    const { productId, chainsureId, giftCode } = match.params;

    if (prevProps.selectedGiftCode && !selectedGiftCode && giftCode) {
      // close gift code from actions
      return;
    }

    if (selectedGiftCode !== giftCode) {
      if (giftCode) {
        selectGift(chainsureId, giftCode);
      } else if (selectedGiftCode) {
        // this is for unselect chainsureid/giftcode
        selectGift(chainsureId, giftCode);
      }
    } else if (!giftCode && chainsureId !== selectedChainsureId) {
      selectChainsure(chainsureId);
    }

    if (productId !== selectedProductId) {
      selectProduct(productId);
    }

    if (shouldRefreshChainsures && !prevProps.shouldRefreshChainsures) {
      getUsedChainsures();
    }

    if (prevProps.sentToChainsures !== sentToChainsures && sentToChainsures == null) {
      getSentToChainsures();
    }

    if (prevProps.pendingRequestChainsures !== pendingRequestChainsures && pendingRequestChainsures == null) {
      getPendingRequestChainsures();
    }

    if (lng !== prevProps.lng) {
      if (latestProducts) {
        getProducts('latest', true);
      }
      if (topProducts) {
        getProducts('top', true);
      }
      getUsedChainsures();
      getPendingRequestChainsures();
      getSentToChainsures();
    }
  }

  closeDetailModal = () => {
    const { history } = this.props;

    history.push('/dashboard');
  };

  setDefaultCurrency = (currency: string) => {
    const { setDefaultCurreny, stats } = this.props;

    if (stats) {
      setDefaultCurreny(currency, stats);
    }
  };

  renderDefaultCurrencySelector = () => {
    const { t } = this.props;
    const { stats } = this.props;

    if (!stats) {
      return null;
    }

    const { availCurrencies, selectedCurrency } = stats || {};

    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="currency-hint">{t('DASHBOARD_STAT_MONEY_POPUP_OPTION')}</div>
        <div>
          {availCurrencies.map((x) => (
            <button
              key={x}
              type="button"
              className={`currency-option  ${selectedCurrency === x ? 'selected' : ''}`}
              onClick={(e) => {
                e.preventDefault();

                this.setDefaultCurrency(x);
              }}
            >
              {x}
            </button>
          ))}
        </div>
      </div>
    );
  };

  renderMoneyDetailOnPopover = (title: string, data: { currency: string; amount: number }[]) => {
    if (data.length === 0) return null;

    return (
      <div className="section">
        <div className="title">{title}</div>
        {data.map((x) => (
          <div key={x.currency} className="money">
            <div className="currency">{x.currency}</div>
            <div className="amount">{numberFormat.toLocaleString(x.amount)}</div>
          </div>
        ))}
      </div>
    );
  };

  toggleMoneyStatPopUp = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    this.setState(({ showingMoneyStatPopUp }) => ({
      showingMoneyStatPopUp: !showingMoneyStatPopUp,
    }));
  };

  renderStatistics = () => {
    const { t, stats } = this.props;
    const { showingMoneyStatPopUp } = this.state;

    let buyCount = '--';
    let buyAgainCount = '--';
    let giftSentCount = '--';
    let giftReceivedCount = '--';
    let moneyPaid = '--';
    let moneySpent = '--';

    if (stats) {
      buyCount = stats.buy.total.toFixed(0);
      buyAgainCount = stats.buy.rebuy.toFixed(0);
      giftSentCount = stats.gift.sent.toFixed(0);
      giftReceivedCount = stats.gift.received.toFixed(0);
      moneyPaid = numberFormat.toLocaleCurrencyString(stats.selectedCurrency, stats.convertedMoney.paid);
      moneySpent = numberFormat.toLocaleCurrencyString(stats.selectedCurrency, stats.convertedMoney.spent);

      if (!stats.convertedMoneySuccess) {
        moneyPaid += '+';
        moneySpent += '+';
      }
    }

    return (
      <Row>
        <Col md={4}>
          <Statistic
            linkTo="/offers"
            icon="gift"
            color="secondary"
            topCount={giftSentCount}
            desc={t('DASHBOARD_STAT_GIFT_SENT')}
            addDesc={t('DASHBOARD_STAT_GIFT_RECEIVED').replace(/\[NUMBER\]/g, giftReceivedCount)}
          />
        </Col>
        <Col md={4}>
          <Statistic
            linkTo="/inventories"
            icon="cubes"
            color="primary"
            topCount={buyCount}
            desc={t('DASHBOARD_STAT_PRODUCT_BUY')}
            addDesc={t('DASHBOARD_STAT_PRODUCT_REBUY').replace(/\[NUMBER\]/g, buyAgainCount)}
          />
        </Col>
        <Col md={4}>
          <Statistic
            linkTo="/payouts"
            icon="coins"
            color="danger"
            topCount={moneyPaid}
            desc={t('DASHBOARD_STAT_MONEY_PAID')}
            addDesc={t('DASHBOARD_STAT_MONEY_SPENT').replace(/\[NUMBER\]/g, moneySpent)}
            renderAdditionContent={() => {
              if (!stats || stats.availCurrencies.length <= 1) return null;

              const { spent, paid } = stats.rawMoney;

              return (
                <>
                  <button
                    type="button"
                    id="money-stat-popover-toggler"
                    className="top-right-link"
                    onClick={this.toggleMoneyStatPopUp}
                  >
                    <FontAwesomeIcon icon="ellipsis-v" />
                  </button>
                  <Popover
                    isOpen={showingMoneyStatPopUp}
                    toggle={this.toggleMoneyStatPopUp}
                    trigger="legacy"
                    placement="bottom"
                    target="money-stat-popover-toggler"
                  >
                    <PopoverBody className="money-stat-popover">
                      {this.renderMoneyDetailOnPopover(t('DASHBOARD_STAT_MONEY_POPUP_PAID'), paid)}
                      {this.renderMoneyDetailOnPopover(t('DASHBOARD_STAT_MONEY_POPUP_SPENT'), spent)}
                      {this.renderDefaultCurrencySelector()}
                    </PopoverBody>
                  </Popover>
                </>
              );
            }}
          />
        </Col>
      </Row>
    );
  };

  switchProductMode = () => {
    const { switchProductMode } = this.props;

    switchProductMode();
  };

  renderProducts = () => {
    const { productMode, latestProducts, topProducts, t } = this.props;

    if (productMode === 'top') {
      return (
        topProducts &&
        topProducts.map((product, index) => (
          <Row key={product.id}>
            <Col>
              <ProductInfoListItem
                {...product}
                horizontal
                productOnly
                linkTo={`/dashboard/p/${product.id}`}
                timeClassName="product-top-stat text-left text-md-right"
                renderTime={() => (
                  <>
                    <div className="top-pos">{`#${index + 1}`}</div>
                    <div className="top-data">
                      {t('DASHBOARD_PRODUCT_BUY_TIMES').replace(/\[NUMBER\]/g, (product.buyTimes || 0).toFixed(0))}
                    </div>
                    <div className="top-data">
                      {t('DASHBOARD_PRODUCT_BUY_AGAIN_TIMES').replace(
                        /\[NUMBER\]/g,
                        (product.rebuyTimes || 0).toFixed(0)
                      )}
                    </div>
                  </>
                )}
              />
            </Col>
          </Row>
        ))
      );
    }

    return (
      latestProducts &&
      latestProducts.map((product) => (
        <Row key={product.id}>
          <Col>
            <ProductInfoListItem
              {...product}
              horizontal
              linkTo={`/dashboard/p/${product.id}`}
              timeClassName="product-latest-time"
              renderTime={() => (
                <>
                  <div className="font-weight-bold">{t('PRODUCT_DATE_AVAILABLE')}</div>
                  <div>{datetimeFormat.parseToLocaleString(product.dateAvailable, 'date-trim-year')}</div>
                </>
              )}
            />
          </Col>
        </Row>
      ))
    );
  };

  renderProductsSection = () => {
    const { isLoadingProducts, t, productMode } = this.props;

    return (
      <>
        <Row>
          <Col className="heading d-flex justify-content-between mb-3 mt-2">
            <div className="d-flex">
              <div className="mr-2 pr-2 border-right border-primary">
                <div className="title">
                  {productMode === 'top' ? t('DASHBOARD_PRODUCT_TOP_HEADING') : t('DASHBOARD_PRODUCT_LATEST_HEADING')}
                </div>
              </div>
              <button type="button" className="btn btn-link heading-link-btn" onClick={this.switchProductMode}>
                <small>
                  {productMode === 'top' ? t('DASHBOARD_PRODUCT_LATEST_LINK') : t('DASHBOARD_PRODUCT_TOP_LINK')}
                </small>
              </button>
            </div>
            <Link to="/offers">
              <small>{t('VIEW_ALL')}</small>
            </Link>
          </Col>
        </Row>
        {isLoadingProducts ? (
          <Row key="loading">
            <Col>
              <ProductInfoListItemLoading horizontal />
            </Col>
          </Row>
        ) : (
          this.renderProducts()
        )}
      </>
    );
  };

  renderUsedChainsures = () => {
    const { usedChainsures, t } = this.props;

    if (!usedChainsures) {
      return null;
    }

    if (usedChainsures.length === 0) {
      return (
        <Row>
          <Col>
            <Alert isOpen color="primary" fade={false}>
              {t('DASHBOARD_INVENTORIES_NOT_FOUND')}
            </Alert>
          </Col>
        </Row>
      );
    }

    return usedChainsures.map((chainsure) => (
      <Row key={chainsure.id}>
        <Col>
          <ChainsureListItem
            t={t}
            chainsure={chainsure}
            horizontal
            linkTo={`/dashboard/c/${chainsure.id}`}
            hideBottom
          />
        </Col>
      </Row>
    ));
  };

  renderRequestDesc = (chainsure: Chainsure) => {
    const { t } = this.props;
    const { requestsDetails } = chainsure;

    let photoURL = '';
    let comment = '';
    let displayName = '';
    let date: Timestamp | string = '';
    let pattern = '';

    if (requestsDetails && requestsDetails.length === 1) {
      const requestDetail = requestsDetails[0];

      pattern = t('DASHBOARD_PENDING_REQUEST_ONE');
      ({
        date,
        comment,
        user: { photoURL, displayName },
      } = requestDetail);
    } else {
      pattern = t('DASHBOARD_PENDING_REQUEST_MULTIPLE');
    }

    const content = contentFromPattern(
      pattern,
      [
        {
          regex: /\[DISPLAYNAME\]/g,
          build: () => (
            <span className="font-weight-bold" key="user-name">
              {displayName}
            </span>
          ),
        },
        {
          regex: /\[NUM_OF_REQUESTS\]/g,
          build: () => (
            <span className="font-weight-bold" key="num-of-request">
              {requestsDetails && requestsDetails.length}
            </span>
          ),
        },
        {
          regex: /\[COMMENT\]/g,
          build: () => (
            <span className="font-weight-medium" key="comment">
              {comment}
            </span>
          ),
        },
      ],
      0
    );

    return (
      <>
        {requestsDetails && requestsDetails.length === 1 ? (
          <>
            <div>
              <UserPhoto photoURL={photoURL} />
            </div>
            <div className="flex-grow-1 ml-2">
              <div>{content}</div>
              <div className="date">{datetimeFormat.parseToLocaleString(date, 'full-trim-year')}</div>
            </div>
          </>
        ) : (
          <div>{content}</div>
        )}
        <FontAwesomeIcon icon="user-check" className="indicator-icon" />
      </>
    );
  };

  renderGiftDesc = (chainsure: Chainsure) => {
    const { t } = this.props;
    const {
      user: { photoURL, displayName },
      detailsGiftSentTo,
      giftComment,
    } = chainsure;

    const { comment, date = '' } = detailsGiftSentTo || {};

    const content = contentFromPattern(
      t('DASHBOARD_GIFT_SENT_TO'),
      [
        {
          regex: /\[DISPLAYNAME\]/g,
          build: () => (
            <span className="font-weight-bold" key="user-name">
              {displayName}
            </span>
          ),
        },
        {
          regex: /\[COMMENT\]/g,
          build: () => (
            <span className="font-weight-medium" key="comment">
              {comment || giftComment}
            </span>
          ),
        },
      ],
      0
    );

    return (
      <>
        <div>
          <UserPhoto photoURL={photoURL} />
        </div>
        <div className="flex-grow-1 ml-2">
          <div>{content}</div>
          <div className="date">{datetimeFormat.parseToLocaleString(date, 'full-trim-year')}</div>
        </div>
        <FontAwesomeIcon icon="gift" className="indicator-icon" />
      </>
    );
  };

  renderGiftsSentTo = () => {
    const { t, sentToChainsures } = this.props;

    if (!sentToChainsures || sentToChainsures.length === 0) {
      return null;
    }

    return (
      <>
        <Row>
          <Col className="heading d-flex justify-content-between mb-3 mt-2">
            <div className="d-flex">
              <div className="mr-2 pr-2  ">
                <div className="title">{t('DASHBOARD_GIFTS_SENT_TO_HEADING')}</div>
              </div>
            </div>
          </Col>
        </Row>
        {sentToChainsures.map((chainsure) => (
          <Row key={chainsure.id}>
            <Col>
              <ChainsureListItem
                t={t}
                chainsure={chainsure}
                horizontal
                indicatorClass="gift-sent-to"
                indicatorComponent={this.renderGiftDesc(chainsure)}
                linkTo={`/dashboard/gift/c/${chainsure.id}/g/${chainsure.gift}`}
                hideBottom
              />
            </Col>
          </Row>
        ))}
      </>
    );
  };

  renderPendingRequests = () => {
    const { t, pendingRequestChainsures } = this.props;

    if (!pendingRequestChainsures || pendingRequestChainsures.length === 0) {
      return null;
    }

    return (
      <>
        <Row>
          <Col className="heading d-flex justify-content-between mb-3 mt-2">
            <div className="d-flex">
              <div className="mr-2 pr-2  ">
                <div className="title">{t('DASHBOARD_PENDING_REQUESTS_HEADING')}</div>
              </div>
            </div>
          </Col>
        </Row>
        {pendingRequestChainsures.map((chainsure) => (
          <Row key={chainsure.id}>
            <Col>
              <ChainsureListItem
                t={t}
                chainsure={chainsure}
                horizontal
                indicatorClass="pending-request"
                indicatorComponent={this.renderRequestDesc(chainsure)}
                linkTo={`/dashboard/c/${chainsure.id}`}
                hideBottom
              />
            </Col>
          </Row>
        ))}
      </>
    );
  };

  renderInventoriesSection = () => {
    const { t, usedChainsures } = this.props;

    return (
      <>
        <Row>
          <Col className="heading d-flex justify-content-between mb-3 mt-2">
            <div className="d-flex">
              <div className="mr-2 pr-2  ">
                <div className="title">{t('DASHBOARD_INVENTORIES_HEADING')}</div>
              </div>
            </div>
            <Link to="/inventories">
              <small>{t('VIEW_ALL')}</small>
            </Link>
          </Col>
        </Row>
        {usedChainsures == null ? (
          <Row key="loading">
            <Col>
              <ProductInfoListItemLoading horizontal />
            </Col>
          </Row>
        ) : (
          this.renderUsedChainsures()
        )}
      </>
    );
  };

  render() {
    const { history, location } = this.props;

    return (
      <Container className="container-sm-fluid dashboard">
        {this.renderStatistics()}
        {this.renderGiftsSentTo()}
        {this.renderPendingRequests()}
        {this.renderInventoriesSection()}
        {this.renderProductsSection()}
        <GiftDetailModal onClose={this.closeDetailModal} history={history} />
        <ProductDetailModal onClose={this.closeDetailModal} history={history} location={location} />
        <ChainsureDetailModal onClose={this.closeDetailModal} history={history} location={location} />
      </Container>
    );
  }
}

export default withRouter(
  withNamespaces()(
    connect(mapStateToProps, {
      getUserOveralStatistics: dashboardActions.getUserOveralStatistics,
      getProducts: dashboardActions.getProducts,
      switchProductMode: dashboardActions.switchProductMode,
      getUsedChainsures: dashboardActions.getUsedChainsures,
      getSentToChainsures: dashboardActions.getSentToChainsures,
      getPendingRequestChainsures: dashboardActions.getPendingRequestChainsures,
      setDefaultCurreny: dashboardActions.setDefaultCurreny,
      selectProduct: productActions.selectProduct,
      selectChainsure: chainsureAction.selectChainsure,
      getGift: giftActions.getGift,
      selectGift: giftActions.selectGift,
    })(Dashboard)
  )
);
