import { ThunkAction, Action } from './types';
import { Chainsure, PaymentCheckoutResponse, CheckoutDetail } from '../types';
import httpClient from '../utils/httpClient';
import getErrorFromAxios from '../utils/getErrorFromAxios';
import { getDispatch } from '../reduxStore';
import mediaService from '../utils/mediaService';
import datetimeFormat from '../utils/datetime/datetimeFormat';

const getOwned = (silent?: boolean, lastItem?: Chainsure | null): ThunkAction => async (dispatch) => {
  dispatch({
    type: 'PRODUCTS_LOADING',
    payload: {
      silent,
    },
  });

  const size = 6;
  let endPoint = `/inventories/owned?size=${size}`;

  if (lastItem) {
    endPoint += `&date=${datetimeFormat.timestampToDate(lastItem.createdAt).toISOString()}`;
  }

  const { data: chainsures } = await httpClient.app.get<Chainsure[]>(endPoint);

  dispatch({
    type: 'OWNED_CHAINSURES_LOADED',
    payload: {
      chainsures,
      canLoadMore: chainsures.length === size,
      refresh: !lastItem,
    },
  });
};

const selectOwned = (chainsureId?: string | null): Action => ({
  type: 'OWNED_CHAINSURE_SELECTED',
  payload: {
    chainsureId,
  },
});

const getOwnedChainsureDetail = (chainsureId: string): ThunkAction => async (dispatch) => {
  try {
    const response = await httpClient.app.get<Chainsure>(`/inventories/owned/c/${chainsureId}`);

    dispatch({
      type: 'OWNED_CHAINSURE_DETAIL_LOADED',
      payload: {
        chainsure: response.data,
      },
    });

    dispatch({
      type: 'CHAINSURE_HISTORIES_INIT',
      payload: {
        chainsure: response.data,
        type: 'owned',
      },
    });
  } catch (error) {
    console.log(error);

    const responseError = getErrorFromAxios(error);

    if (responseError) {
      dispatch({
        type: 'OWNED_CHAINSURE_DETAIL_ERROR',
        payload: {
          chainsureId,
          error: responseError,
        },
      });
    }
  }
};

const getAll = (silent?: boolean, lastItem?: Chainsure | null): ThunkAction => async (dispatch) => {
  dispatch({
    type: 'CHAINSURES_LOADING',
    payload: {
      silent,
    },
  });

  const size = 6;
  let endPoint = `/inventories?size=${size}`;

  if (lastItem) {
    endPoint += `&date=${datetimeFormat.timestampToDate(lastItem.createdAt).toISOString()}`;
  }

  const { data: chainsures } = await httpClient.app.get<Chainsure[]>(endPoint);

  dispatch({
    type: 'CHAINSURES_LOADED',
    payload: {
      chainsures,
      canLoadMore: chainsures.length === size,
      refresh: !lastItem,
    },
  });
};

const getReBuyingNote = async (chainsureId: string) => {
  try {
    const { data } = await httpClient.app.get<{ comment: string }>(`/inventories/c/${chainsureId}/buy`);

    return data.comment;
  } catch (error) {
    return '';
  }
};

const getChainsureDetail = (chainsureId: string): ThunkAction => async (dispatch) => {
  try {
    const response = await httpClient.app.get<Chainsure>(`/inventories/c/${chainsureId}`);

    dispatch({
      type: 'CHAINSURE_DETAIL_LOADED',
      payload: {
        chainsure: response.data,
      },
    });

    dispatch({
      type: 'CHAINSURE_HISTORIES_INIT',
      payload: {
        chainsure: response.data,
        type: 'chainsure',
      },
    });
  } catch (error) {
    console.log(error);

    const responseError = getErrorFromAxios(error);

    if (responseError) {
      dispatch({
        type: 'CHAINSURE_DETAIL_ERROR',
        payload: {
          chainsureId,
          error: responseError,
        },
      });
    }
  }
};

const saveGiftComment = async (chainsureId: string, comment: string) => {
  try {
    const {
      data: { giftCode, giftComment },
    } = await httpClient.app.patch<{
      giftCode: string;
      giftComment: string;
    }>(`/inventories/c/${chainsureId}/gift`, {
      comment,
    });

    getDispatch()({
      type: 'CHAINSURE_GIFT_CODE',
      payload: {
        chainsureId,
        giftCode,
        giftComment,
      },
    });
  } catch (error) {
    console.log(error);

    const responseError = getErrorFromAxios(error);

    if (responseError) {
      getDispatch()({
        type: 'CHAINSURE_GIFT_CODE_ERROR',
        payload: {
          chainsureId,
          error: responseError,
        },
      });
    }
  }
};

const getGiftCode = (chainsureId: string): ThunkAction => async (dispatch) => {
  try {
    const {
      data: { giftCode, giftComment, giftSent },
    } = await httpClient.app.get<{
      giftCode: string;
      giftComment: string;
      giftSent: boolean;
    }>(`/inventories/c/${chainsureId}/gift`);

    dispatch({
      type: 'CHAINSURE_GIFT_CODE',
      payload: {
        chainsureId,
        giftCode,
        giftComment,
        giftSent,
      },
    });
  } catch (error) {
    console.log(error);

    const responseError = getErrorFromAxios(error);

    if (responseError) {
      dispatch({
        type: 'CHAINSURE_GIFT_CODE_ERROR',
        payload: {
          chainsureId,
          error: responseError,
        },
      });
    }
  }
};

const toggleMakingGift = (
  chainsureId?: string,
  giftCode?: string,
  giftComment?: string,
  giftSent?: boolean
): Action => ({
  type: 'CHAINSURE_TOGGLE_MAKING_GIFT',
  payload: {
    chainsureId,
    giftCode,
    giftComment,
    giftSent,
  },
});

const toggleRelease = (chainsureId?: string, chainsure?: Chainsure): Action => ({
  type: 'CHAINSURE_TOGGLE_RELEASE',
  payload: {
    chainsureId,
    chainsure,
  },
});

const toggleDamage = (chainsureId?: string): Action => ({
  type: 'CHAINSURE_TOGGLE_DAMAGE',
  payload: {
    chainsureId,
  },
});

const selectChainsure = (chainsureId?: string | null): Action => ({
  type: 'CHAINSURE_SELECTED',
  payload: {
    chainsureId,
  },
});

const reportDamage = (
  chainsureId: string,
  email: string,
  description: string,
  mediaIds: string[]
): ThunkAction => async (dispatch) => {
  try {
    dispatch({
      type: 'DAMAGE_REPORTING',
    });

    await httpClient.app.patch<Chainsure>(`/inventories/c/${chainsureId}/damage`, {
      email,
      description,
      mediaIds,
    });

    dispatch({
      type: 'DAMAGE_REPORT_SUCCESS',
      payload: {
        chainsureId,
      },
    });
  } catch (error) {
    console.log(error);

    if (getErrorFromAxios(error)) {
      dispatch({
        type: 'DAMAGE_REPORT_ERROR',
        payload: {
          error: error.response.data.error,
        },
      });
    }
  }
};

const releaseChainsure = (chainsureId: string, comment: string): ThunkAction => async (dispatch) => {
  try {
    dispatch({
      type: 'CHAINSURE_RELEASING',
    });

    const response = await httpClient.app.patch<Chainsure>(`/inventories/c/${chainsureId}/release`, {
      chainsureId,
      comment,
    });

    dispatch({
      type: 'CHAINSURE_RELEASE_SUCCESS',
      payload: {
        chainsure: response.data,
      },
    });
  } catch (error) {
    console.log(error);

    const responseError = getErrorFromAxios(error);
    if (responseError) {
      dispatch({
        type: 'CHAINSURE_RELEASE_ERROR',
        payload: {
          error: responseError,
        },
      });
    }
  }
};

const getUseStatus = (chainsure: Chainsure) => {
  const now = new Date();
  const startDate = datetimeFormat.timestampToDate(chainsure.startDate);
  const endDate = datetimeFormat.timestampToDate(chainsure.endDate);

  if (
    chainsure.status === 'released' ||
    chainsure.status === 'paid' ||
    chainsure.status === 'pay_waiting' ||
    endDate < now
  ) {
    return 'used';
  }

  if (startDate < now) {
    return 'using';
  }

  return 'willuse';
};

const buyChainsure = (
  chainsureId: string,
  startDate: Date,
  endDate: Date,
  comment: string,
  mediaIds: string[],
  isImmediate: boolean
): ThunkAction => async (dispatch) => {
  try {
    dispatch({
      type: 'CHAINSURE_BUY',
      payload: {
        chainsureId,
      },
    });

    const response = await httpClient.app.post<CheckoutDetail>(`/inventories/c/${chainsureId}/buy`, {
      startDate,
      endDate,
      comment,
      mediaIds,
      isImmediate,
    });

    dispatch({
      type: 'CHAINSURE_CHECKOUT',
      payload: {
        chainsureId,
        checkoutDetail: response.data,
      },
    });
  } catch (error) {
    console.log(error.response);

    dispatch({
      type: 'CHAINSURE_CHECKOUT_ERROR',
      payload: {
        chainsureId,
      },
    });
  }
};
const verifyChainsureCheckout = (chainsureId: string, paymentId: string): ThunkAction => async (dispatch) => {
  try {
    const { data: chainsure } = await httpClient.app.get<Chainsure>(`/inventories/c/${chainsureId}`);

    dispatch({
      type: 'CHAINSURE_DETAIL_LOADED',
      payload: {
        chainsure,
      },
    });

    dispatch({
      type: 'CHAINSURE_CHECKOUT_VERIFYING',
      payload: {
        chainsureId,
      },
    });

    const { data: payment } = await httpClient.app.get<PaymentCheckoutResponse>(
      `/inventories/c/${chainsureId}/payments/${paymentId}`
    );

    if (payment.status !== 'completed') {
      dispatch({
        type: 'CHAINSURE_CHECKOUT_ERROR',
        payload: {
          chainsureId,
        },
      });
      return;
    }
    dispatch({
      type: 'CHAINSURE_CHECKOUT_SUCCESS',
      payload: {
        chainsureId,
        payment,
      },
    });
  } catch (error) {
    console.log(error);

    dispatch({
      type: 'CHAINSURE_CHECKOUT_ERROR',
      payload: {
        chainsureId,
      },
    });
  }
};

const toggleOwnedRequest = (): Action => ({
  type: 'OWNED_CHAINSURE_TOGGLE_REQUEST',
});

const getRedeemNote = async (chainsureId: string) => {
  try {
    const { data } = await httpClient.app.get<{ comment: string }>(`/inventories/c/${chainsureId}/redeem`);

    return data.comment;
  } catch (error) {
    return '';
  }
};

const getRequestNote = async (chainsureId: string) => {
  try {
    const { data } = await httpClient.app.get<{ comment: string }>(`/inventories/owned/c/${chainsureId}/request`);

    return data.comment;
  } catch (error) {
    return '';
  }
};

const getReleaseNote = async (chainsureId: string) => {
  try {
    const { data } = await httpClient.app.get<{ comment: string }>(`/inventories/c/${chainsureId}/release`);

    return data.comment;
  } catch (error) {
    return '';
  }
};

const cleanOwnedError = (): Action => ({
  type: 'OWNED_CHAINSURE_CLEAN_ERROR',
});

const requestOwner = (chainsureId: string, comment: string): ThunkAction => async (dispatch) => {
  dispatch({
    type: 'OWNED_CHAINSURE_REQUESTING',
  });

  try {
    await httpClient.app.post(`/inventories/owned/c/${chainsureId}/request`, {
      chainsureId,
      comment,
    });

    dispatch({
      type: 'OWNED_CHAINSURE_REQUESTED_SUCCESS',
    });
  } catch (error) {
    console.log(error);

    const responseError = getErrorFromAxios(error);
    if (responseError) {
      dispatch({
        type: 'OWNED_CHAINSURE_REQUESTED_ERROR',
        payload: {
          error: responseError,
        },
      });
    }
  }
};

const denyOwnedRequest = (chainsureId: string, requestUid: string): ThunkAction => async (dispatch) => {
  try {
    dispatch({
      type: 'OWNED_CHAINSURE_REQUEST_DENY',
      payload: {
        chainsureId,
        requestUid,
      },
    });

    await httpClient.app.patch(`/inventories/c/${chainsureId}/request/u/${requestUid}/deny`);

    dispatch({
      type: 'OWNED_CHAINSURE_REQUEST_DENIED',
      payload: {
        chainsureId,
        requestUid,
      },
    });
  } catch (error) {
    const responseError = getErrorFromAxios(error);

    if (responseError) {
      dispatch({
        type: 'OWNED_CHAINSURE_REQUEST_DENIED_ERROR',
        payload: {
          chainsureId,
          requestUid,
          error: responseError,
        },
      });
    }
  }
};

const acceptOwnedRequest = (chainsureId: string, requestUid: string): ThunkAction => async (dispatch) => {
  try {
    dispatch({
      type: 'OWNED_CHAINSURE_REQUEST_ACCEPT',
      payload: {
        requestUid,
      },
    });

    await httpClient.app.patch(`/inventories/c/${chainsureId}/request/u/${requestUid}/accept`);

    dispatch({
      type: 'OWNED_CHAINSURE_REQUEST_ACCEPT_SUCCESS',
      payload: {
        chainsureId,
        requestUid,
      },
    });
  } catch (error) {
    const responseError = getErrorFromAxios(error);

    if (responseError) {
      dispatch({
        type: 'OWNED_CHAINSURE_REQUEST_ACCEPT_ERROR',
        payload: {
          chainsureId,
          requestUid,
          error: responseError,
        },
      });
    }
  }
};

const cleanRequestError = (): Action => ({
  type: 'OWNED_CHAINSURE_REQUEST_CLEAN_ERROR',
});

const getChainsurePhoto = (chainsure: Chainsure) => {
  const { lastLink, product } = chainsure;

  if (!lastLink || !lastLink.mediaIds || lastLink.mediaIds.length === 0) {
    return product.photoURL;
  }

  return mediaService.getJoinedMediaImgLink(lastLink.mediaIds, product.photoURL);
};

export default {
  getOwned,
  selectOwned,
  getOwnedChainsureDetail,
  getAll,
  selectChainsure,
  getChainsureDetail,
  getUseStatus,
  toggleMakingGift,
  toggleRelease,
  reportDamage,
  releaseChainsure,
  toggleDamage,
  buyChainsure,
  verifyChainsureCheckout,
  getGiftCode,
  saveGiftComment,
  getReBuyingNote,
  getRedeemNote,
  getReleaseNote,
  requestOwner,
  toggleOwnedRequest,
  acceptOwnedRequest,
  cleanRequestError,
  denyOwnedRequest,
  getRequestNote,
  getChainsurePhoto,
  cleanOwnedError,
};
