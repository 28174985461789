import React from 'react';
import { ModalBody, Row, Col, Card, CardBody } from 'reactstrap';

type Props = {
  children?: any;
  wrapInCard?: boolean;
};

const ModalBodyWrapper = ({ children, wrapInCard }: Props) => {
  let content = children;

  if (wrapInCard) {
    content = (
      <Card className="border-primary">
        <CardBody>{children}</CardBody>
      </Card>
    );
  }

  return (
    <ModalBody>
      <Row noGutters>
        <Col>{content}</Col>
      </Row>
    </ModalBody>
  );
};

export default ModalBodyWrapper;
