import React from 'react';
import './ProductInfoActionContainer.scss';
import { Card, CardBody, Button } from 'reactstrap';
import FontAwesomeIcon from '../../Icon/FontAwesomeIcon';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Props = {
  icon?: string;
  title?: string;
  description?: string;
  onClose?: () => any;
  children: any;
  className?: string;
};

const ProductInfoActionContainer = ({
  children,
  icon,
  title,
  description,
  onClose,
  className,
}: Props) => (
  <Card
    className={`border-primary card flex-grow-1 bg-grey product-action-container ${
      className || ''
    }`}
  >
    <CardBody>
      <div className="text-center mb-2 mt-1">
        <FontAwesomeIcon icon={icon as IconProp} className="action-icon" />
      </div>
      <div className="text-center mb-1 action-title">{title}</div>
      <div className="text-center mb-2 action-desc">{description}</div>
      <div>{children}</div>
      <div className="action-close">
        <Button color="secondary" className="btn-icon-only" onClick={onClose}>
          <FontAwesomeIcon icon="times" size="lg" />
        </Button>
      </div>
    </CardBody>
  </Card>
);

export default ProductInfoActionContainer;
