import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import './ChainsureInfoHistories.scss';
import { ModalBody, Row, Col } from 'reactstrap';
import { ReduxState, HistoriesState } from '../../../reducers/types';
import { i18nTranslator } from '../../../utils/i18n';
import ChainsureHistoryItem from '../HistoryItem/ChainsureHistoryItem';
import FontAwesomeIcon from '../../Icon/FontAwesomeIcon';
import mediaService from '../../../utils/mediaService';
import historiesAction from '../../../actions/histories.action';
import LightboxGallery from '../../Photo/LightboxGallery/LightboxGallery';
import ChainsureHistoryItemLoading from '../HistoryItem/ChainsureHistoryItemLoading';

type Props = HistoriesState & {
  loadMore: typeof historiesAction.loadMore;
};

type State = {
  showingGallery: boolean;
  mediasUrls: string[];
  mediasThumbnails: string[];
  mediasTypes: string[];
  index: number;
};

class ChainsureInfoHistories extends PureComponent<Props, State> {
  state: State = {
    showingGallery: false,
    mediasUrls: [],
    mediasThumbnails: [],
    mediasTypes: [],
    index: -1,
  };

  componentDidMount() {
    const { chainsure, type } = this.props;

    const scrollElement = document.querySelector(`.modal-${type}-detail`);

    if (scrollElement) {
      if (chainsure && chainsure.histories) {
        scrollElement.addEventListener('scroll', this.onScroll);
      }
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { chainsure, type } = this.props;

    const scrollElement = document.querySelector(`.modal-${type}-detail`);

    if (scrollElement) {
      if (
        chainsure &&
        chainsure.histories &&
        (!prevProps.chainsure || !prevProps.chainsure.histories)
      ) {
        scrollElement.addEventListener('scroll', this.onScroll);
      } else if (
        prevProps.chainsure &&
        prevProps.chainsure.histories &&
        (!chainsure || !chainsure.histories)
      ) {
        scrollElement.removeEventListener('scroll', this.onScroll);
      }
    }
  }

  componentWillUnmount() {
    const { type } = this.props;

    const scrollElement = document.querySelector(`.modal-${type}-detail`);

    if (scrollElement) {
      scrollElement.removeEventListener('scroll', this.onScroll);
    }
  }

  onScroll = ({ currentTarget }: Event) => {
    const target = currentTarget as HTMLElement;
    if (target.scrollTop + target.clientHeight + 100 >= target.scrollHeight) {
      this.loadMore();
    }
  };

  loadMore = () => {
    const {
      chainsure,
      isLoading,
      canLoadMore,
      loadMore,
      type,
      histories,
    } = this.props;

    if (!chainsure || !histories || isLoading || !canLoadMore || !type) return;

    loadMore(type, chainsure.id, histories[histories.length - 1]);
  };

  onMediaSelect = (mediasIds: string[], index: number) => {
    const medias = mediasIds.map((m) => mediaService.getMedia(m));

    this.setState({
      showingGallery: true,
      mediasUrls: medias.map((m) => m.fullLink),
      mediasThumbnails: medias.map((m) => m.thumbnailLink),
      mediasTypes: medias.map((m) => m.type),
      index,
    });
  };

  render() {
    const { histories, isLoading } = this.props;
    const {
      showingGallery,
      mediasUrls,
      mediasThumbnails,
      mediasTypes,
      index,
    } = this.state;

    if (!histories || histories.length === 0) {
      return null;
    }

    const topAction = histories[0].action;

    return (
      <>
        <LightboxGallery
          showingGallery={showingGallery}
          mediasUrls={mediasUrls}
          mediasThumbnails={mediasThumbnails}
          mediasTypes={mediasTypes}
          index={index}
          onClose={() =>
            this.setState({
              showingGallery: false,
            })
          }
        />
        <ModalBody className="histories">
          <Row noGutters>
            <Col>
              <div>
                <div className="hitories-heading">
                  <div className="history-time-line">
                    <div className={`root-icon item-${topAction}`}>
                      <FontAwesomeIcon icon={['fas', 'clock']} size="2x" />
                    </div>
                    <div className="history-vline-container">
                      <div
                        className={`history-vline item-${topAction} solid`}
                      />
                    </div>
                  </div>
                  <div className="root-lbl">
                    {i18nTranslator('CHAINSURE_HISTORIES')}
                  </div>
                </div>
                {histories.map((item) => (
                  <div key={item.id}>
                    <ChainsureHistoryItem
                      data={item}
                      onMediaSelect={this.onMediaSelect}
                    />
                  </div>
                ))}
                <div key="last">
                  <ChainsureHistoryItemLoading isEnd={!isLoading} />
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </>
    );
  }
}

const mapStateToProps = ({ histories }: ReduxState) => ({
  ...histories,
});

export default connect(mapStateToProps, {
  loadMore: historiesAction.loadMore,
})(ChainsureInfoHistories);
