import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import './UserNavigation.scss';
import { NavLink as RRDNavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { Container, Nav, NavItem, NavLink } from 'reactstrap';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import FontAwesomeIcon from '../../Icon/FontAwesomeIcon';
import { ReduxState } from '../../../reducers/types';

type Props = {
  isAuth: boolean;
} & RouteComponentProps<{}> &
  WithNamespaces;

class UserNavigation extends PureComponent<Props> {
  render() {
    const { isAuth, location, t } = this.props;

    if (!isAuth) return null;

    let passed = false;
    [
      /^\/dashboard(\/[pc]\/[\w\d]+)?/g,
      /^\/offers(\/p\/[\w\d]+)?/g,
      /^\/inventories(\/c\/[\w\d]+)?/g,
      /^\/joined(\/c\/[\w\d]+)?/g,
      /^\/payouts/g,
    ].every((regex) => {
      if (regex.test(location.pathname)) {
        passed = true;

        return false;
      }

      return true;
    });

    if (!passed) {
      return null;
    }

    return (
      <Container className="user-nav">
        <Nav pills justified>
          <NavItem>
            <NavLink tag={RRDNavLink} to="/dashboard">
              <FontAwesomeIcon icon={['far', 'window-maximize']} className="nav-icon" />
              {t('MENU_DASHBOARD')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={RRDNavLink} to="/offers">
              <FontAwesomeIcon icon="gift" className="nav-icon" />
              {t('MENU_OFFERS')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={RRDNavLink} to="/inventories">
              <FontAwesomeIcon icon="cubes" className="nav-icon" />
              {t('MENU_INVENTORIES')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={RRDNavLink} to="/payouts">
              <FontAwesomeIcon icon="coins" className="nav-icon" />
              {t('MENU_PAYOUTS')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={RRDNavLink} to="/joined">
              <FontAwesomeIcon icon="user-clock" className="nav-icon" />
              {t('MENU_JOINED')}
            </NavLink>
          </NavItem>
        </Nav>
      </Container>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  isAuth: state.auth.isAuth,
});

export default withNamespaces()(withRouter(connect(mapStateToProps)(UserNavigation)));
