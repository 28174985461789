import React, { PureComponent, ReactNode } from 'react';
import { WithNamespaces } from 'react-i18next';
import './ChainsureListItem.scss';
import { Chainsure } from '../../../../types';
import DateRangeLabel from '../../../../components/DateRangeLabel/DateRangeLabel';
import chainsureAction from '../../../../actions/chainsure.action';
import ProductInfoListItem from '../../../../components/ProductInfo/ListItem/ProductInfoListItem';
import GiftAvailable from '../../../../components/ChainsureInfo/GiftAvailable/GiftAvailable';
import productTypeSelect from '../../../../utils/productTypeSelect';
import datetimeFormat from '../../../../utils/datetime/datetimeFormat';

type Props = {
  chainsure: Chainsure;
  linkTo?: string;
  horizontal?: boolean;
  horizontalTop?: string;
  indicatorClass?: string;
  indicatorComponent?: React.ReactNode;
  hideBottom?: boolean;
} & Pick<WithNamespaces, 't'>;

class ChainsureListItem extends PureComponent<Props> {
  refreshTimeOut: any = null;

  componentDidMount() {
    this.setupTimeoutForUpdateStatus();
  }

  componentDidUpdate() {
    this.setupTimeoutForUpdateStatus();
  }

  componentWillUnmount() {
    clearTimeout(this.refreshTimeOut);
  }

  setupTimeoutForUpdateStatus = () => {
    const { chainsure } = this.props;
    const now = new Date();

    const status = chainsureAction.getUseStatus(chainsure);

    clearTimeout(this.refreshTimeOut);

    switch (status) {
      case 'willuse':
        this.refreshTimeOut = setTimeout(
          () => this.forceUpdate(),
          +datetimeFormat.timestampToDate(chainsure.startDate) - +now
        );
        break;
      case 'using':
        this.refreshTimeOut = setTimeout(
          () => this.forceUpdate(),
          +datetimeFormat.timestampToDate(chainsure.endDate) - +now
        );
        break;
      default:
        break;
    }
  };

  render() {
    const { chainsure, t, linkTo, horizontal, hideBottom, indicatorComponent, indicatorClass } = this.props;
    const { product } = chainsure;

    const status = chainsureAction.getUseStatus(chainsure);

    let bottomComponent: ReactNode = null;
    let topComponent: ReactNode = null;
    let timeLabel: string | null = null;
    let stateKey: string | null = null;

    switch (status) {
      case 'used': {
        timeLabel = t('CHAINSURE_LAST_USE_ON');

        if (chainsure.status === 'released') {
          bottomComponent = null;
          topComponent = (
            <GiftAvailable
              hasRequests={!!chainsure.requestedBy && chainsure.requestedBy.length > 0}
              className="on-list"
              tooltipPlacement="bottom"
              chainsureId={chainsure.id}
            />
          );

          // state RELEASED
          stateKey = productTypeSelect(
            chainsure.product.type,
            {
              free: 'STATE_RELEASED_FREE',
              money: 'STATE_RELEASED_MONEY',
              money_immediate: 'STATE_RELEASED_MONEY_IMMEDIATE',
              good_mood: 'STATE_RELEASED_GOOD_MOOD',
              insurance: 'STATE_RELEASED_INSURANCE',
            },
            chainsure.isImmediate
          );
        } else if (chainsure.product.type === 'free' || (chainsure.product.type === 'money' && chainsure.isImmediate)) {
          // state ACCEPTED (Free/Money Immediate)
          stateKey = productTypeSelect(
            chainsure.product.type,
            {
              free: 'STATE_ACCEPTED_FREE',
              money_immediate: 'STATE_ACCEPTED_MONEY_IMMEDIATE',
            },
            chainsure.isImmediate
          );
        } else {
          // state FINISHED
          stateKey = productTypeSelect(chainsure.product.type, {
            money: 'STATE_FINISHED_MONEY',
            good_mood: 'STATE_FINISHED_GOOD_MOOD',
            insurance: 'STATE_FINISHED_INSURANCE',
          });
        }

        break;
      }
      case 'using': {
        timeLabel = t('CHAINSURE_USING');
        bottomComponent = null;

        // state STARTED
        stateKey = productTypeSelect(chainsure.product.type, {
          money: 'STATE_STARTED_MONEY',
          good_mood: 'STATE_STARTED_GOOD_MOOD',
          insurance: 'STATE_STARTED_INSURANCE',
        });

        break;
      }
      case 'willuse': {
        timeLabel = t('CHAINSURE_USE_ON');
        topComponent = (
          <GiftAvailable
            giftCode={chainsure.gift}
            giftSent={chainsure.giftSent}
            requestSent={!!chainsure.requestedBy && chainsure.requestedBy.length > 0}
            className="on-list"
            tooltipPlacement="bottom"
            chainsureId={chainsure.id}
          />
        );

        bottomComponent = null;
        if (chainsure.giftSent) {
          // State OFFERED
          stateKey = productTypeSelect(
            chainsure.product.type,
            {
              free: 'STATE_OFFERED_FREE',
              money: 'STATE_OFFERED_MONEY',
              money_immediate: 'STATE_OFFERED_MONEY_IMMEDIATE',
              good_mood: 'STATE_OFFERED_GOOD_MOOD',
              insurance: 'STATE_OFFERED_INSURANCE',
            },
            chainsure.isImmediate
          );
        } else if (!chainsure.giftAcceptedFrom) {
          // State BOUGHT
          stateKey = productTypeSelect(
            chainsure.product.type,
            {
              free: 'STATE_BOUGHT_FREE',
              money: 'STATE_BOUGHT_MONEY',
              money_immediate: 'STATE_BOUGHT_MONEY_IMMEDIATE',
              good_mood: 'STATE_BOUGHT_GOOD_MOOD',
              insurance: 'STATE_BOUGHT_INSURANCE',
            },
            chainsure.isImmediate
          );
        } else {
          // State ACCEPTED (Money, Good Mood, Insrance)

          stateKey = productTypeSelect(
            chainsure.product.type,
            {
              money: 'STATE_ACCEPTED_MONEY',
              good_mood: 'STATE_ACCEPTED_GOOD_MOOD',
              insurance: 'STATE_ACCEPTED_INSURANCE',
            },
            chainsure.isImmediate
          );
        }

        break;
      }
      default:
        break;
    }

    if (chainsure.status === 'verifying_buy') {
      bottomComponent = null;
      stateKey = 'STATE_INREVIEW_INSURANCE';
    }

    return (
      <ProductInfoListItem
        horizontal={horizontal}
        linkTo={linkTo || `/inventories/c/${chainsure.id}`}
        {...{
          ...product,
          currency: chainsure.currency,
          price: chainsure.amount,
        }}
        lastUpdatedTime={chainsure.createdAt}
        photoURL={chainsureAction.getChainsurePhoto(chainsure)}
        indicatorClass={indicatorClass}
        indicatorComponent={indicatorComponent}
        timeClassName={`align-items-start ${horizontal ? 'text-left text-md-right' : ''}`}
        renderTime={() => (
          <>
            <div className="font-weight-bold">{timeLabel}</div>
            <DateRangeLabel
              startDate={chainsure.startDate}
              endDate={chainsure.endDate}
              labelOnly={
                chainsure.isImmediate &&
                chainsure.status === 'inactive' &&
                datetimeFormat.timestampToDate(chainsure.startDate) > new Date()
                  ? t('CHAINSURE_USE_ON_IMMEDIATE')
                  : null
              }
            />
          </>
        )}
        timeAndPriceContainerClassName={horizontal ? null : 'mt-sm-3'}
        renderBottom={hideBottom ? null : bottomComponent}
        renderTop={topComponent}
        stateBottomKey={stateKey}
      />
    );
  }
}

export default ChainsureListItem;
