import React, { Component, SyntheticEvent } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Container, Row, Form, FormGroup, Col, Button } from 'reactstrap';
import '../auth.scss';
import SocialAuth from '../../../components/SocialAuth/SocialAuth';
import validate from '../../../utils/validations/validate';
import TextField from '../../../components/TextField/TextField';
import isEmpty from '../../../utils/validations/isEmpty';
import Hud from '../../../components/Hud/Hud';
import authActions from '../../../actions/auth.actions';

type State = {
  email: string;
  displayName: string;
  password: string;
  confirmPassword: string;
  remember: boolean;
  isSigningUp: boolean;
  signUpError: string | null;
  errors: { [field: string]: string };
};

const SignupValidateConfig = {
  email: {
    require: true,
    email: true,
  },
  displayName: {
    require: true,
  },
  password: {
    require: true,
    length: {
      min: 6,
    },
  },
  confirmPassword: {
    require: true,
    equalOther: 'password',
  },
};
type Props = RouteComponentProps<{}> & WithNamespaces;
class SignUp extends Component<Props, State> {
  state: State = {
    email: '',
    displayName: '',
    password: '',
    confirmPassword: '',
    remember: true,
    isSigningUp: false,
    signUpError: null,
    errors: validate({}, SignupValidateConfig) || {},
  };

  onChangeField = (event: SyntheticEvent<HTMLInputElement>) => {
    this.setState(
      {
        [event.currentTarget.name]:
          event.currentTarget.type === 'checkbox' ? event.currentTarget.checked : event.currentTarget.value,
      } as any,
      () => {
        this.setState((state) => ({
          errors: validate(state, SignupValidateConfig) || {},
        }));
      }
    );
  };

  onSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { email, password, displayName, remember } = this.state;

    this.setState({
      isSigningUp: true,
    });

    try {
      await authActions.signUpByEmail(email, password, displayName, remember);
    } catch (error) {
      console.log(error.code);

      this.setState({
        isSigningUp: false,
        signUpError: error.code,
      });
    }
  };

  tryAgain = () => {
    this.setState({
      signUpError: null,
    });
  };

  render() {
    const { location, t } = this.props;
    const { email, displayName, password, confirmPassword, remember, isSigningUp, signUpError, errors } = this.state;

    const signupErrorMessages = {
      email: {
        require: t('USER_EMAIL_REQUIRED'),
        email: t('USER_EMAIL_INVALID'),
      },
      displayName: {
        require: t('USER_DISPLAY_NAME_REQUIRED'),
      },
      password: {
        require: t('USER_PASSWORD_REQUIRED'),
        length: t('USER_PASSWORD_LENGTH'),
      },
      confirmPassword: {
        require: t('USER_CONFIRM_PASSWORD_REQUIRED'),
        equalOther: t('USER_CONFIRM_PASSWORD_NOT_MATCH'),
      },
    };

    let errorMessage: string | null = null;
    switch (signUpError) {
      case 'auth/email-already-in-use':
        errorMessage = t('USER_SIGN_UP_ERR_EMAIL_DUPLICATED');
        break;
      default:
        errorMessage = t('USER_SIGN_UP_ERR_UNKOWN');
        break;
    }

    return (
      <Container tag="main" role="main">
        <Hud
          isOpen={signUpError != null || isSigningUp}
          type={isSigningUp ? 'loading' : 'alert'}
          alertBtnTitle={t('ALERT_OK')}
          alertTitle={t('ALERT_ERROR')}
          alertDesc={errorMessage}
          toggle={this.tryAgain}
        />

        <Row>
          <Col
            xs={12}
            sm={{
              size: 8,
              offset: 2,
            }}
            lg={{
              size: 6,
              offset: 3,
            }}
            className=" d-flex flex-column align-items-center auth"
          >
            <h5 className="mt-md-3 font-weight-bold text-primary">{t('USER_SIGN_UP_TITLE')}</h5>
            <p>{t('USER_SIGN_UP_DESC')}</p>
            <Form className="w-100" onSubmit={this.onSubmit}>
              <TextField
                type="text"
                name="displayName"
                placeholder={t('USER_DISPLAY_NAME')}
                value={displayName}
                onChange={this.onChangeField}
                disabled={isSigningUp}
                invalid={errors.displayName != null}
                invalidMessage={errors.displayName && signupErrorMessages.displayName[errors.displayName]}
              />
              <TextField
                type="email"
                name="email"
                placeholder={t('USER_EMAIL')}
                value={email}
                onChange={this.onChangeField}
                disabled={isSigningUp}
                invalid={errors.email != null}
                invalidMessage={errors.email && signupErrorMessages.email[errors.email]}
              />
              <TextField
                type="password"
                name="password"
                placeholder={t('USER_PASSWORD')}
                value={password}
                onChange={this.onChangeField}
                disabled={isSigningUp}
                invalid={errors.password != null}
                invalidMessage={errors.password && signupErrorMessages.password[errors.password]}
              />
              <TextField
                type="password"
                name="confirmPassword"
                placeholder={t('USER_CONFIRM_PASSWORD')}
                value={confirmPassword}
                onChange={this.onChangeField}
                disabled={isSigningUp}
                invalid={errors.confirmPassword != null}
                invalidMessage={errors.confirmPassword && signupErrorMessages.confirmPassword[errors.confirmPassword]}
              />
              <FormGroup className="custom-control custom-checkbox text-center">
                <input
                  className="custom-control-input"
                  type="checkbox"
                  id="rememberme"
                  name="remember"
                  checked={remember}
                  onChange={this.onChangeField}
                />
                <label className="custom-control-label text-primary" htmlFor="rememberme">
                  {t('USER_REMEMBER')}
                </label>
              </FormGroup>
              <Button type="submit" color="primary" className="w-100" disabled={isSigningUp || !isEmpty(errors)}>
                {t('USER_SIGN_UP_BTN')}
              </Button>
            </Form>
            <div className="text-right switch mt-3">
              <span>{t('USER_LOG_IN_QUESTION')}</span>
              <Link to={`/login/${location.search}`}>{t('MENU_LOG_IN')}</Link>
            </div>
            <div className="d-flex justify-content-center align-items-center mb-2 mt-3 social-separator">
              <hr />
              <span>{t('USER_AUTH_OR')}</span>
              <hr />
            </div>
            <p>{t('USER_AUTH_SOCIAL')}</p>
            <div className="w-100 d-flex flex-column justify-content-between social-list">
              <SocialAuth />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withNamespaces()(SignUp);
