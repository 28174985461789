import React, { Component } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import productActions from '../../actions/product.actions';
import { ProductsState, ReduxState } from '../../reducers/types';
import ProductDetailModal from './Product/DetailModal/ProductDetailModal';
import ProductListItem from './Product/ListItem/ProductListItem';
import ProductListItemLoading from './Product/ListItem/ProductListItemLoading';

type Props = ProductsState & {
  getAll: typeof productActions.getAll;
  selectProduct: typeof productActions.selectProduct;
} & RouteComponentProps<{
    productId: string;
  }> &
  WithNamespaces;

class Offers extends Component<Props> {
  componentDidMount() {
    const { getAll, products, match, selectProduct } = this.props;

    !products && getAll();

    const { productId } = match.params;

    if (productId) {
      selectProduct(productId);
    }

    window.addEventListener('scroll', this.onScroll);
  }

  componentDidUpdate(prevProps: Props) {
    const { match, selectedProductId, selectProduct, lng, getAll } = this.props;
    const { productId } = match.params;

    if (selectedProductId !== productId) {
      selectProduct(productId);
    }

    if (lng !== prevProps.lng) {
      getAll(true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    if (window.innerHeight + window.pageYOffset + 100 >= (document.body.children[1] as HTMLElement).offsetHeight) {
      this.loadMore();
    }
  };

  loadMore = () => {
    const { getAll, isLoading, products, canLoadMore } = this.props;

    if (isLoading || !canLoadMore) return;

    getAll(false, products && products.length > 0 ? products[products.length - 1] : null);
  };

  closeProductDetail = () => {
    const { history } = this.props;

    history.push('/offers');
  };

  render() {
    const { isLoading, products, history , location} = this.props;

    return (
      <Container className="container-sm-fluid">
        <ProductDetailModal onClose={this.closeProductDetail} history={history} location={location} />
        <Row>
          {products &&
            products.map((p) => (
              <Col key={p.id} md={6} lg={4} sm={6}>
                <ProductListItem product={p} />
              </Col>
            ))}
          {isLoading &&
            Array.from(
              {
                length: 3,
              },
              (_, i) => (
                <Col key={`loading_${i}`} md={6} lg={4} sm={6}>
                  <ProductListItemLoading />
                </Col>
              )
            )}
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  ...state.products,
});

export default withNamespaces()(
  connect(mapStateToProps, {
    getAll: productActions.getAll,
    selectProduct: productActions.selectProduct,
  })(Offers)
);
