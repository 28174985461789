import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';
import FontAwesomeIcon from '../Icon/FontAwesomeIcon';
import authActions from '../../actions/auth.actions';
import Hud from '../Hud/Hud';
import { i18nTranslator } from '../../utils/i18n';
import googleIcon from '../../assets/google_icon.svg';

type State = {
  isRequesting: boolean;
  authError: string | null;
};

class SocialAuth extends PureComponent<{}, State> {
  state: State = {
    isRequesting: false,
    authError: null,
  };

  onAuth = async (provider: 'facebook' | 'google' | 'twitter') => {
    try {
      this.setState({
        isRequesting: true,
      });

      await authActions.authByWithSocial(provider);
    } catch (error) {
      this.setState({
        isRequesting: false,
        authError: error.code,
      });
    }
  };

  tryAgain = () => {
    this.setState({
      authError: null,
    });
  };

  render() {
    const { isRequesting, authError } = this.state;

    let errorMessage: string | null = null;
    switch (authError) {
      case 'auth/account-exists-with-different-credential':
        errorMessage = i18nTranslator('USER_AUTH_SOCIAL_ERR_DUPLICATED_EMAIL');
        break;
      case 'auth/popup-closed-by-user':
        errorMessage = i18nTranslator('USER_AUTH_SOCIAL_ERR_USER_DENIED');
        break;
      default:
        errorMessage = i18nTranslator('USER_AUTH_SOCIAL_ERR_UNKNOWN');
        break;
    }

    return (
      <>
        <Hud
          isOpen={isRequesting || authError != null}
          type={isRequesting ? 'loading' : 'alert'}
          alertBtnTitle={i18nTranslator('ALERT_OK')}
          alertTitle={i18nTranslator('ALERT_ERROR')}
          alertDesc={errorMessage}
          toggle={this.tryAgain}
        />
        <Button
          color="facebook"
          className="btn-icon-left"
          onClick={() => this.onAuth('facebook')}
        >
          <div className="btn-icon">
            <FontAwesomeIcon icon={['fab', 'facebook']} size="2x" />
          </div>
          <span>
            {i18nTranslator('USER_AUTH_SOCIAL_BTN').replace(
              /\[PROVIDER\]/g,
              'Facebook'
            )}
          </span>
        </Button>
        <Button
          color="google"
          className="btn-icon-left"
          onClick={() => this.onAuth('google')}
        >
          <div className="btn-icon">
            <img src={googleIcon} alt="" className="btn-icon-svg" />
          </div>
          <span>
            {i18nTranslator('USER_AUTH_SOCIAL_BTN').replace(
              /\[PROVIDER\]/g,
              'Google'
            )}
          </span>
        </Button>
        <Button
          color="twitter"
          className="btn-icon-left"
          onClick={() => this.onAuth('twitter')}
        >
          <div className="btn-icon">
            <FontAwesomeIcon icon={['fab', 'twitter']} size="2x" />
          </div>
          <span>
            {i18nTranslator('USER_AUTH_SOCIAL_BTN').replace(
              /\[PROVIDER\]/g,
              'Twitter'
            )}
          </span>
        </Button>
      </>
    );
  }
}

export default SocialAuth;
