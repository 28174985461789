import React, { PureComponent } from 'react';
import classesNames from './UserPhoto.module.scss';
import FontAwesomeIcon from '../Icon/FontAwesomeIcon';

class UserPhoto extends PureComponent<{
  photoURL?: string | null;
  className?: string | null;
}> {
  render() {
    const { photoURL, className } = this.props;
    if (photoURL) {
      return (
        <img
          className={`${classesNames['user-photo']} ${className || ''}`}
          src={photoURL}
          alt=""
        />
      );
    }

    return (
      <div className={`${classesNames['user-photo']} ${className || ''}`}>
        <FontAwesomeIcon
          icon="user"
          className={`${classesNames['user-photo-svg-fa']}`}
        />
      </div>
    );
  }
}

export default UserPhoto;
