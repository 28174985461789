import config from '../utils/config';
import { ThunkAction, Action } from './types';
import { UserStat, Product, Chainsure } from '../types';
import { i18nTranslator } from '../utils/i18n';
import arrayUnion from '../utils/arrayUnion';
import httpClient from '../utils/httpClient';

const transformUserStat = async (userStat: UserStat) => {
  const stats = {
    ...userStat,
  };
  const { spent, paid } = stats.money;

  const spentCurrencies = Object.keys(spent);
  const paidCurrencies = Object.keys(paid);
  const currencies = arrayUnion(spentCurrencies, paidCurrencies);
  let selectedCurrency = currencies[0];

  stats.convertedMoneySuccess = true;
  stats.rawMoney = {
    spent: [],
    paid: [],
  };

  for (let i = 0; i < spentCurrencies.length; i++) {
    stats.rawMoney.spent.push({
      currency: spentCurrencies[i],
      amount: stats.money.spent[spentCurrencies[i]],
    });
  }

  for (let i = 0; i < paidCurrencies.length; i++) {
    stats.rawMoney.paid.push({
      currency: paidCurrencies[i],
      amount: stats.money.paid[paidCurrencies[i]],
    });
  }

  if (currencies.length > 1) {
    // get latest selected currency
    const lastSelectedCurrency = localStorage.getItem('_dashboard_selected_currency');

    if (!lastSelectedCurrency) {
      if (currencies.includes(i18nTranslator('DEFAULT_CURRENCY'))) {
        selectedCurrency = i18nTranslator('DEFAULT_CURRENCY');
      }
    } else {
      selectedCurrency = lastSelectedCurrency;
    }

    // getting currency converter api
    const pairsToLoad: string[] = [];
    let exchangeRates: {
      [pairToLoad: string]: number;
    } = {};

    for (let i = 0; i < currencies.length; i++) {
      if (currencies[i] !== selectedCurrency) {
        pairsToLoad.push(`${currencies[i]}_${selectedCurrency}`);
        exchangeRates[`${currencies[i]}_${selectedCurrency}`] = 0;
      }
    }

    try {
      const exchangeRatesResponse = await httpClient.freeCurrenciesConvert.get<{
        [pairToLoad: string]: number;
      }>(
        '/convert?' + `q=${pairsToLoad.join(',')}&` + 'compact=ultra&' + `apiKey=${config.freeCurrencyConverter.apiKey}`
      );

      exchangeRates = exchangeRatesResponse.data;

      localStorage.setItem('_last_exchange_rates', JSON.stringify(exchangeRates));
    } catch (error) {
      const savedLastExchangeRates = localStorage.getItem('_last_exchange_rates');

      if (savedLastExchangeRates) {
        exchangeRates = JSON.parse(savedLastExchangeRates);
      } else {
        stats.convertedMoneySuccess = false;
      }
    }

    stats.convertedMoney = {
      spent: 0,
      paid: 0,
    };

    for (let i = 0; i < spentCurrencies.length; i++) {
      if (spentCurrencies[i] === selectedCurrency) {
        stats.convertedMoney.spent += spent[spentCurrencies[i]];
      } else {
        stats.convertedMoney.spent +=
          spent[spentCurrencies[i]] * exchangeRates[`${spentCurrencies[i]}_${selectedCurrency}`];
      }
    }

    for (let i = 0; i < paidCurrencies.length; i++) {
      if (paidCurrencies[i] === selectedCurrency) {
        stats.convertedMoney.paid += paid[paidCurrencies[i]];
      } else {
        stats.convertedMoney.paid +=
          paid[paidCurrencies[i]] * exchangeRates[`${paidCurrencies[i]}_${selectedCurrency}`];
      }
    }
  } else {
    stats.convertedMoney = {
      spent: spent[selectedCurrency] || 0,
      paid: paid[selectedCurrency] || 0,
    };
  }

  stats.availCurrencies = currencies;
  stats.selectedCurrency = selectedCurrency;

  return stats;
};

const getUserOveralStatistics = (): ThunkAction => async (dispatch) => {
  const { data: stats } = await httpClient.app.get<UserStat>('/statistics/user/overall');

  dispatch({
    type: 'DASHBOARD_STATS_LOADED',
    payload: {
      stats: await transformUserStat(stats),
    },
  });
};

const getProducts = (mode: 'top' | 'latest', notSwitch?: boolean): ThunkAction => async (dispatch) => {
  dispatch({
    type: 'DASHBOARD_PRODUCTS_LOADING',
    payload: {
      mode,
      notSwitch,
    },
  });

  const response =
    mode === 'top'
      ? await httpClient.app.get<Product[]>('/statistics/products/topbuy?size=3')
      : await httpClient.app.get<Product[]>('/offers?size=3');

  dispatch({
    type: 'DASHBOARD_PRODUCTS_LOADED',
    payload: {
      products: response.data,
      mode,
      notSwitch,
    },
  });
};

const getSentToChainsures = (): ThunkAction => async (dispatch) => {
  const response = await httpClient.app.get<Chainsure[]>('/gifts/to/me?size=10');

  dispatch({
    type: 'DASHBOARD_SENT_TO_CHAINSURES_LOADED',
    payload: {
      chainsures: response.data,
    },
  });
};

const getPendingRequestChainsures = (): ThunkAction => async (dispatch) => {
  const response = await httpClient.app.get<Chainsure[]>('/inventories/pending/requests?size=10');

  dispatch({
    type: 'DASHBOARD_PENDING_REQUEST_CHAINSURES_LOADED',
    payload: {
      chainsures: response.data,
    },
  });
};

const getUsedChainsures = (): ThunkAction => async (dispatch) => {
  dispatch({
    type: 'DASHBOARD_CHAINSURES_LOADING',
  });

  const response = await httpClient.app.get<Chainsure[]>('/inventories/used?size=3');

  dispatch({
    type: 'DASHBOARD_CHAINSURES_LOADED',
    payload: {
      chainsures: response.data,
    },
  });
};

const switchProductMode = (): Action => ({
  type: 'DASHBOARD_PRODUCTS_SWITCH',
});

const setDefaultCurreny = (currency: string, userStat: UserStat): ThunkAction => async (dispatch) => {
  localStorage.setItem('_dashboard_selected_currency', currency);

  dispatch({
    type: 'DASHBOARD_STATS_LOADED',
    payload: {
      stats: await transformUserStat(userStat),
    },
  });
};

export default {
  getUserOveralStatistics,
  getProducts,
  switchProductMode,
  getUsedChainsures,
  setDefaultCurreny,
  getSentToChainsures,
  getPendingRequestChainsures,
};
