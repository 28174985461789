import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Row, Container, Alert } from 'reactstrap';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { ReduxState, DamagesState } from '../../reducers/types';
import damageAction from '../../actions/damage.action';
import DamageListItem from './Damage/ListItem/DamageListItem';
import DamageDetailModal from './Damage/DetailModal/DamageDetailModal';
import ChainsureListItemLoading from '../Inventories/Chainsure/ListItem/ChainsureListItemLoading';

type Props = DamagesState & {
  getAll: typeof damageAction.getAll;
  selectDamage: typeof damageAction.selectDamage;
} & RouteComponentProps<{
    damageId: string;
  }> &
  WithNamespaces;

class Damages extends Component<Props> {
  componentDidMount() {
    const { getAll, damages, match, selectDamage } = this.props;

    !damages && getAll();

    const { damageId } = match.params;

    if (damageId) {
      selectDamage(damageId);
    }

    window.addEventListener('scroll', this.onScroll);
  }

  componentDidUpdate(prevProps: Props) {
    const { match, damageId: selectedDamage, selectDamage, lng, getAll } = this.props;
    const { damageId } = match.params;

    if (selectedDamage !== damageId) {
      selectDamage(damageId);
    }

    if (lng !== prevProps.lng) {
      getAll(true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    if (
      document.body.children[1] &&
      window.innerHeight + window.pageYOffset + 100 >= (document.body.children[1] as HTMLElement).offsetHeight
    ) {
      this.loadMore();
    }
  };

  loadMore = () => {
    const { getAll, isLoading, damages, canLoadMore } = this.props;

    if (isLoading || !canLoadMore) return;

    getAll(false, damages && damages.length > 0 ? damages[damages.length - 1] : null);
  };

  closeDamageModal = () => {
    const { history } = this.props;

    history.push('/payouts');
  };

  renderList = () => {
    const { damages, isLoading, t } = this.props;

    if (!isLoading && damages && damages.length === 0) {
      return (
        <Alert isOpen color="primary" fade={false}>
          {t('DAMAGES_EMPTY')}
        </Alert>
      );
    }

    return (
      <Row>
        {damages &&
          damages.map((d) => (
            <Col key={d.id} md={6} lg={4} sm={6}>
              <DamageListItem damage={d} t={t} />
            </Col>
          ))}

        {isLoading &&
          Array.from(
            {
              length: 3,
            },
            (_, i) => (
              <Col key={`loading_${i}`} md={6} lg={4} sm={6}>
                <ChainsureListItemLoading />
              </Col>
            )
          )}
      </Row>
    );
  };

  render() {
    return (
      <Container className="container-sm-fluid">
        <DamageDetailModal onClose={this.closeDamageModal} />
        {this.renderList()}
      </Container>
    );
  }
}

const mapStateToProps = ({ damages: { isLoading, damages, damage, damageId, canLoadMore } }: ReduxState) => ({
  isLoading,
  damages,
  damage,
  damageId,
  canLoadMore,
});

export default withNamespaces()(
  connect(mapStateToProps, {
    getAll: damageAction.getAll,
    selectDamage: damageAction.selectDamage,
  })(Damages)
);
