import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';

import { ReduxState } from './reducers/types';
import { Dispatch } from './actions/types';

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, {}, composeEnhancers(applyMiddleware(thunk)));

export const getState: () => ReduxState = () => store.getState();

export const getDispatch: () => Dispatch = () => store.dispatch as any;

export const getStore = () => store;

export default {
  store,
};
