import React, { PureComponent } from 'react';
import ProductInfoListItemLoading from '../../../../components/ProductInfo/ListItem/ProductInfoListItemLoading';
import './ChainsureListItem.scss';

type Props = {
  horizontal?: boolean;
  horizontalTop?: boolean;
  indicatorClass?: string;
  className?: string;
};

class ChainsureListItemLoading extends PureComponent<Props> {
  render() {
    const { horizontal, className, indicatorClass } = this.props;

    return (
      <ProductInfoListItemLoading
        className={`chainsure-list-item ${className || ''}`}
        horizontal={horizontal}
        indicatorClass={indicatorClass}
      />
    );
  }
}

export default ChainsureListItemLoading;
