const copyToClipboard = (content: string) => {
  if ((window as any).clipboardData && (window as any).clipboardData.setData) {
    // IE specific code path to prevent textarea being shown while dialog is visible.
    (window as any).clipboardData.setData('Text', content);
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const fakeElem = document.createElement('textarea');
    // Prevent zooming on iOS
    fakeElem.style.fontSize = '12pt';
    // Reset box model
    fakeElem.style.border = '0';
    fakeElem.style.padding = '0';
    fakeElem.style.margin = '0';
    // Move element out of screen horizontally
    fakeElem.style.position = 'absolute';
    fakeElem.style.left = '-9999px';
    // Move element to the same position vertically
    const yPosition = window.pageYOffset || document.documentElement.scrollTop;
    fakeElem.style.top = `${yPosition}px`;

    fakeElem.setAttribute('readonly', 'readonly');
    fakeElem.value = content;

    document.body.appendChild(fakeElem);

    fakeElem.select();
    fakeElem.setSelectionRange(0, fakeElem.value.length);

    try {
      document.execCommand('copy');
    } catch (err) {}
  }
};

export default copyToClipboard;
