import React, { Component } from 'react';
import { Card, CardBody, CardFooter } from 'reactstrap';
import { ChainsureLink } from '../../../types';
import datetimeFormat from '../../../utils/datetime/datetimeFormat';
import contentFromPattern from '../../../utils/contentFromPattern';
import { i18nTranslator } from '../../../utils/i18n';
import numberFormat from '../../../utils/numberFormat';
import FontAwesomeIcon from '../../Icon/FontAwesomeIcon';
import './ChainsureHistoryItem.scss';
import UserPhoto from '../../UserPhoto/UserPhoto';
import PhotoGallery from '../../Photo/PhotoGallery/PhotoGallery';
import config from '../../../utils/config';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Props = {
  data: ChainsureLink;
  onMediaSelect: (mediasIds: string[], index: number) => any;
};

export default class ChainsureHistoryItem extends Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    const { data } = this.props;

    return nextProps.data.id !== data.id;
  }

  onMediaSelect = (index: number) => {
    const {
      onMediaSelect,
      data: { mediaIds },
    } = this.props;

    onMediaSelect(mediaIds, index);
  };

  render() {
    const { data } = this.props;
    const { action, comment, mediaIds, fromUser, timestamp } = data;
    let { user } = data;

    // should keep data.user.displayName, because user will be replace by fromUser then
    const replaceConfigs = [
      {
        regex: /\[USER_NAME\]/g,
        build: () => (
          <span className="font-weight-bold" key="user-name">
            {data.user.displayName}
          </span>
        ),
      },
      {
        regex: /\[AMOUNT\]/g,
        build: () => (
          <span className="font-weight-bold" key="amount">
            {numberFormat.toLocaleCurrencyString(data.currency, data.amount)}
          </span>
        ),
      },
      {
        regex: /\[USER_NAME_SENDER\]/g,
        build: () => (
          <span className="font-weight-bold" key="sender">
            {fromUser ? fromUser.displayName : ''}
          </span>
        ),
      },
    ];

    let pattern: string = '';
    let indicatorIcon: IconProp = 'question-circle';
    let subContent = comment ? <div className="comment">{comment}</div> : null;

    switch (action) {
      case 'extend':
      case 'buy': {
        indicatorIcon = ['fas', 'gift'];
        pattern = i18nTranslator('CHAINSURE_HISTORY_BUY');

        break;
      }
      case 'request': {
        indicatorIcon = ['fas', 'user-check'];
        pattern = i18nTranslator('CHAINSURE_HISTORY_REQUEST');

        if (user && comment) {
          subContent = (
            <Card className="sub-content bg-secondary">
              <CardBody>
                <div className="heading">
                  <UserPhoto photoURL={user.photoURL} className="user-photo" />
                  <strong>{user.displayName}</strong>
                </div>
                <div className="comment">{comment}</div>
              </CardBody>
            </Card>
          );
        }

        user = fromUser;

        break;
      }
      case 'release': {
        indicatorIcon = ['fas', 'box'];
        pattern = i18nTranslator('CHAINSURE_HISTORY_RELEASE');

        break;
      }
      case 'damage': {
        indicatorIcon = ['fas', 'box-open'];
        pattern = i18nTranslator('CHAINSURE_HISTORY_DAMAGE');

        break;
      }
      case 'gift': {
        indicatorIcon = ['fas', 'user-friends'];
        pattern = i18nTranslator('CHAINSURE_HISTORY_GIFT');

        if (fromUser && comment) {
          subContent = (
            <Card className="sub-content bg-secondary">
              <CardBody>
                <div className="heading">
                  <UserPhoto
                    photoURL={fromUser.photoURL}
                    className="user-photo"
                  />
                  <strong>{fromUser.displayName}</strong>
                </div>
                <div className="comment">{comment}</div>
              </CardBody>
            </Card>
          );
        }

        break;
      }
      default:
        break;
    }

    const content = contentFromPattern(pattern, replaceConfigs, 0);
    const mediaExists = mediaIds && mediaIds.length > 0;

    return (
      <div className="history-item">
        <div className="history-time-line">
          <div className={`history-indicator item-${action}`}>
            <FontAwesomeIcon icon={indicatorIcon} />
          </div>
          <div className="history-vline-container">
            <div className={`history-vline item-${action}`} />
          </div>
        </div>
        <div className="history-wrapper">
          <div className="product-date">
            {datetimeFormat.parseToLocaleString(data.date, 'full-trim-year')}
          </div>
          <Card className="history-card">
            <CardBody className="history-content">
              <div className="heading">
                <UserPhoto photoURL={user.photoURL} className="user-photo" />
                <div className="content">
                  <span>{content}</span>
                </div>
              </div>
              {subContent}
              {mediaExists && (
                <div className="media">
                  <PhotoGallery
                    mediaIds={mediaIds}
                    onMediaSelect={this.onMediaSelect}
                  />
                </div>
              )}
            </CardBody>
            {timestamp && (
              <CardFooter className={` item-${action}`}>
                <a
                  href={config.originstamp.timestampUrl.replace(
                    '{{hash}}',
                    timestamp
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  originstamp&nbsp;
                  <FontAwesomeIcon icon="award" />
                </a>
              </CardFooter>
            )}
          </Card>
        </div>
      </div>
    );
  }
}
