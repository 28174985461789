import { ProductType } from '../types';

export default function productTypeSelect(
  type: ProductType,
  options: {
    free?: string;
    money?: string;
    money_immediate?: string;
    good_mood?: string;
    insurance?: string;
  },
  isImmediate?: boolean
) {
  switch (type) {
    case 'free':
      return options.free || '';
    case 'money':
      if (isImmediate) return options.money_immediate || '';

      return options.money || '';
    case 'good-mood':
      return options.good_mood || '';
    case 'insurance':
      return options.insurance || '';
    default:
      return options.free || '';
  }
}
