import React, { Component, SyntheticEvent } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Container, Row, Form, FormGroup, Col, Button } from 'reactstrap';
import '../auth.scss';
import SocialAuth from '../../../components/SocialAuth/SocialAuth';
import TextField from '../../../components/TextField/TextField';
import validate from '../../../utils/validations/validate';
import isEmpty from '../../../utils/validations/isEmpty';
import authActions from '../../../actions/auth.actions';
import Hud from '../../../components/Hud/Hud';

type State = {
  email: string;
  password: string;
  remember: boolean;
  isLoggingIn: boolean;
  logInError: string | null;
  errors: { [field: string]: string } | null;
};

const LoginValidateConfig = {
  email: {
    require: true,
    email: true,
  },
  password: {
    require: true,
  },
};

type Props = RouteComponentProps<{}> & WithNamespaces;

class LogIn extends Component<Props, State> {
  state: State = {
    email: '',
    password: '',
    remember: true,
    errors: validate({}, LoginValidateConfig),
    isLoggingIn: false,
    logInError: null,
  };

  onChangeField = (event: SyntheticEvent<HTMLInputElement>) => {
    this.setState(
      {
        [event.currentTarget.name]:
          event.currentTarget.type === 'checkbox' ? event.currentTarget.checked : event.currentTarget.value,
      } as any,
      () => {
        this.setState({
          errors: validate(this.state, LoginValidateConfig),
        });
      }
    );
  };

  onSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { email, password, remember } = this.state;

    this.setState({
      isLoggingIn: true,
    });

    try {
      await authActions.loginByEmail(email, password, remember);
    } catch (error) {
      console.log(error.code);

      this.setState({
        isLoggingIn: false,
        logInError: error.code,
      });
    }
  };

  tryAgain = () => {
    this.setState({
      logInError: null,
    });
  };

  render() {
    const { location, t } = this.props;
    const { email, password, remember, errors, isLoggingIn, logInError } = this.state;

    const loginErrorMessages = {
      email: {
        require: t('USER_EMAIL_REQUIRED'),
        email: t('USER_EMAIL_INVALID'),
      },
      password: {
        require: t('USER_PASSWORD_REQUIRED'),
      },
    };

    let errorMessage: string | null = null;

    switch (logInError) {
      case 'auth/wrong-password':
      case 'auth/user-not-found':
        errorMessage = t('USER_LOG_IN_ERR_INVALID');
        break;
      default:
        errorMessage = t('USER_LOG_IN_ERR_UNKOWN');
        break;
    }

    return (
      <Container tag="main" role="main">
        <Hud
          isOpen={logInError != null || isLoggingIn}
          type={isLoggingIn ? 'loading' : 'alert'}
          alertBtnTitle={t('ALERT_OK')}
          alertTitle={t('ALERT_ERROR')}
          alertDesc={errorMessage}
          toggle={this.tryAgain}
        />

        <Row>
          <Col
            xs={12}
            sm={{
              size: 8,
              offset: 2,
            }}
            lg={{
              size: 6,
              offset: 3,
            }}
            className=" d-flex flex-column align-items-center auth"
          >
            <h5 className="mt-md-3 font-weight-bold text-primary">{t('USER_LOG_IN_TITLE')}</h5>
            <p>{t('USER_LOG_IN_DESC')}</p>
            <Form className="w-100" onSubmit={this.onSubmit}>
              <TextField
                type="email"
                name="email"
                placeholder={t('USER_EMAIL')}
                value={email}
                onChange={this.onChangeField}
                disabled={isLoggingIn}
                invalid={!!errors && errors.email != null}
                invalidMessage={!!errors && !!errors.email && loginErrorMessages.email[errors.email]}
              />
              <TextField
                type="password"
                name="password"
                placeholder={t('USER_PASSWORD')}
                value={password}
                onChange={this.onChangeField}
                disabled={isLoggingIn}
                invalid={!!errors && errors.password != null}
                invalidMessage={!!errors && !!errors.password && loginErrorMessages.password[errors.password]}
              />
              <FormGroup className="custom-control custom-checkbox text-center">
                <input
                  className="custom-control-input"
                  type="checkbox"
                  id="rememberme"
                  name="remember"
                  checked={remember}
                  onChange={this.onChangeField}
                />
                <label className="custom-control-label text-primary" htmlFor="rememberme">
                  {t('USER_REMEMBER')}
                </label>
              </FormGroup>
              <Button type="submit" color="primary" className="w-100" disabled={isLoggingIn || !isEmpty(errors)}>
                {t('USER_LOG_IN_BTN')}
              </Button>
              <div className="text-center mt-2">
                <Link to="/forgot-password">{t('USER_FORGOT_PASSWORD')}</Link>
              </div>
            </Form>
            <div className="text-right switch  mt-3">
              <span>{t('USER_SIGN_UP_QUESTION')}</span>
              <Link to={`/signup/${location.search}`}>{t('MENU_SIGN_UP')}</Link>
            </div>
            <div className="d-flex justify-content-center align-items-center mb-2 mt-3 social-separator">
              <hr />
              <span>{t('USER_AUTH_OR')}</span>
              <hr />
            </div>
            <p>{t('USER_AUTH_SOCIAL')}</p>
            <div className="w-100 d-flex flex-column justify-content-between social-list">
              <SocialAuth />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default withNamespaces()(LogIn);
