import React from 'react';
import { Row, Card, CardBody } from 'reactstrap';
import './ProductInfoBasic.scss';
import { Product } from '../../../types';
import Photo from '../../Photo/Photo';
import datetimeFormat from '../../../utils/datetime/datetimeFormat';
import buildClickableText from '../../../utils/buildClickableText';
import ModalBodyWrapper from '../../Modal/ModalBodyWrapper/ModalBodyWrapper';

type Props = Product & {
  secondPrice?: React.ReactNode;
};

const ProductInfoBasic = (props: Props) => {
  const { photoURL, title, description, dateAvailable } = props;

  return (
    <ModalBodyWrapper>
      <Row noGutters className="text-center mb-3">
        <Photo
          photoURL={photoURL}
          className="photo col-6 col-sm-4"
          photoClassName="photo-img"
        />
      </Row>
      <Card className="border-primary">
        <CardBody>
          <div className="product-title border-bottom border-primary pb-2 mb-2 font-weight-bold">
            {title}
          </div>
          <div className="product-date mb-1">
            {datetimeFormat.parseToLocaleString(
              dateAvailable,
              'date-trim-year'
            )}
          </div>
          <div className="product-description">
            {buildClickableText(description, '', 'font-weight-bold')}
          </div>
        </CardBody>
      </Card>
    </ModalBodyWrapper>
  );
};

export default ProductInfoBasic;
