import { HistoriesState } from './types';
import { Action } from '../actions/types';

const INITIAL_STATE: HistoriesState = {};

const reducer = (state: HistoriesState = INITIAL_STATE, action: Action): HistoriesState => {
  switch (action.type) {
    case 'CHAINSURE_HISTORIES_INIT': {
      const { chainsure, type } = action.payload;

      if (!chainsure) {
        return {
          ...INITIAL_STATE,
        };
      }

      return {
        ...state,
        chainsure,
        type,
        histories: chainsure.histories || [],
        canLoadMore: !chainsure || !chainsure.histories || chainsure.histories.length >= 5,
        isLoading: false,
      };
    }

    case 'CHAINSURE_HISTORIES_LOADING': {
      const { chainsureId } = action.payload;

      if (!state.chainsure || state.chainsure.id !== chainsureId) {
        return state;
      }

      return {
        ...state,
        isLoading: true,
      };
    }

    case 'CHAINSURE_HISTORIES_LOADED': {
      const { chainsureId, histories, canLoadMore } = action.payload;

      if (!state.chainsure || state.chainsure.id !== chainsureId) {
        return state;
      }

      return {
        ...state,
        isLoading: false,
        histories: state.histories ? [...state.histories, ...histories] : histories,
        canLoadMore,
      };
    }

    case 'AUTH_STATE_CHANGED': {
      const { user } = action.payload;

      if (user) return state;

      return {
        ...INITIAL_STATE,
      };
    }

    default:
      return state;
  }
};

export default reducer;
