import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Navigation from '../Navigation/Navigation';
import Footer from '../Footer/Footer';
import UserNavigation from '../Navigation/UserNavigation/UserNavigation';
import queryString from '../../utils/queryString';

type Props = RouteComponentProps<{}> & { children: any };

const Layout = ({ location, children }: Props) => {
  if (location.search) {
    const qss = queryString.parse(location.search);

    if (qss.hideNav === 'true') {
      return (
        <>
          <UserNavigation />
          {children}
        </>
      );
    }
  }

  return (
    <>
      <Navigation />
      <UserNavigation />
      {children}
      <Footer />
    </>
  );
};

export default withRouter(Layout);
