import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ModalBody } from 'reactstrap';
import chainsureAction from '../../../../actions/chainsure.action';
import Hud, { HudType } from '../../../../components/Hud/Hud';
import ProductInfoActionContainer from '../../../../components/ProductInfo/ActionContainer/ProductInfoActionContainer';
import Quoteditable from '../../../../components/Quoteditable/Quoteditable';
import { ReduxState } from '../../../../reducers/types';
import { i18nTranslator } from '../../../../utils/i18n';

const mapStateToProps = ({ owned: { chainsureId, action, chainsure, requestStatus, requestError } }: ReduxState) => ({
  chainsureId,
  action,
  chainsure,
  requestStatus,
  requestError,
});

type Props = {
  requestOwner: typeof chainsureAction.requestOwner;
  toggleOwnedRequest: typeof chainsureAction.toggleOwnedRequest;
  selectOwned: typeof chainsureAction.selectOwned;
  getOwnedChainsureDetail: typeof chainsureAction.getOwnedChainsureDetail;
  cleanOwnedError: typeof chainsureAction.cleanOwnedError;
} & ReturnType<typeof mapStateToProps>;

type State = {
  comment: string;
};

class OwnedChainsureRequest extends PureComponent<Props, State> {
  state: State = {
    comment: '',
  };

  quoteditableRef: Quoteditable | null = null;

  componentDidMount() {
    this.getRequestNote();
  }

  componentDidUpdate(prevProps: Props) {
    const { chainsure } = this.props;

    if (!chainsure) {
      return;
    }

    if (chainsure && prevProps.chainsure !== chainsure) {
      setImmediate(() => {
        this.setState(
          {
            comment: '',
          },
          this.getRequestNote
        );
      });
    }
  }

  getRequestNote = () => {
    const { comment } = this.state;
    const { chainsureId, chainsure } = this.props;

    if (comment || !chainsureId || !chainsure) return;

    const { status, requestedDetail } = chainsure;

    if (requestedDetail && requestedDetail.comment) {
      setImmediate(() => {
        this.setState({
          comment: requestedDetail.comment,
        });
      });

      return;
    }

    const useStatus = chainsureAction.getUseStatus(chainsure);

    if (useStatus === 'willuse' || (useStatus === 'used' && status === 'released')) {
      chainsureAction
        .getRequestNote(chainsureId)
        .then((requestNote) => {
          this.setState({
            comment: requestNote,
          });
        })
        .catch(() => {});
    }
  };

  onClose = () => {
    const { toggleOwnedRequest } = this.props;

    toggleOwnedRequest();
  };

  hudOnClose = () => {
    const {
      cleanOwnedError,
      selectOwned,
      requestError,
      requestStatus,
      getOwnedChainsureDetail,
      chainsureId,
    } = this.props;

    if (requestError) {
      cleanOwnedError();

      return;
    }

    if (requestStatus === 'success') {
      if (this.quoteditableRef) {
        this.quoteditableRef.discardDraft();
      }
    }

    selectOwned(chainsureId);
    chainsureId && getOwnedChainsureDetail(chainsureId);
  };

  onRequestPress = () => {
    const { requestOwner, chainsureId } = this.props;

    if (!chainsureId) {
      return;
    }

    const comment = this.quoteditableRef?.getEditingContent() || '';

    requestOwner(chainsureId, comment);
  };

  render() {
    const { requestStatus, action, chainsure, chainsureId } = this.props;
    const { comment } = this.state;

    if (!chainsure || action !== 'request' || chainsure.product.type !== 'insurance') return null;

    const title = i18nTranslator('CHAINSURE_REQUEST_TITLE');
    const description = i18nTranslator('CHAINSURE_REQUEST_DESC');

    let hudType: HudType | null = null;
    let alertDesc: string | null = null;
    let alertTitle: string | null = null;

    if (requestStatus) {
      hudType = requestStatus === 'requesting' ? 'loading' : 'alert';

      switch (requestStatus) {
        case 'success':
          hudType = 'toast';
          alertDesc = i18nTranslator('CHAINSURE_REQUEST_SUCCESS');
          break;
        case 'error':
          alertTitle = i18nTranslator('ALERT_ERROR');
          alertDesc = i18nTranslator('CHAINSURE_REQUEST_ERROR');
          break;
        default:
          break;
      }
    }

    return (
      <ModalBody>
        <Hud
          type={hudType}
          isOpen={hudType != null}
          alertBtnTitle={i18nTranslator('ALERT_OK')}
          alertDesc={alertDesc}
          alertTitle={alertTitle}
          toastMessage={alertDesc}
          toastTimeout={1500}
          toggle={this.hudOnClose}
        />
        <ProductInfoActionContainer icon="user-check" title={title} description={description} onClose={this.onClose}>
          <Quoteditable
            draftKey={`OwnedChainsureRequest_cId_${chainsureId}`}
            content={comment}
            label={i18nTranslator('CHAINSURE_REQUEST_COMMENT')}
            onChange={(text) => {
              this.setState({
                comment: text,
              });
            }}
            ref={(ref) => {
              this.quoteditableRef = ref;
            }}
          />

          <div className="w-100 d-flex flex-column flex-sm-row justify-content-between">
            <div className="mr-0 mb-2 mb-sm-0 w-100">
              <Button type="button" color="primary" className="w-100" onClick={this.onRequestPress}>
                {i18nTranslator(chainsure.requested ? 'CHAINSURE_ACTION_UPDATE_REQUEST' : 'CHAINSURE_ACTION_REQUEST')}
              </Button>
            </div>
          </div>
        </ProductInfoActionContainer>
      </ModalBody>
    );
  }
}

export default connect(mapStateToProps, {
  requestOwner: chainsureAction.requestOwner,
  selectOwned: chainsureAction.selectOwned,
  getOwnedChainsureDetail: chainsureAction.getOwnedChainsureDetail,
  toggleOwnedRequest: chainsureAction.toggleOwnedRequest,
  cleanOwnedError: chainsureAction.cleanOwnedError,
})(OwnedChainsureRequest);
