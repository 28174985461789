import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Damage } from '../../../types';
import { i18nTranslator } from '../../../utils/i18n';
import datetimeFormat from '../../../utils/datetime/datetimeFormat';
import PhotoGallery from '../../Photo/PhotoGallery/PhotoGallery';
import ModalBodyWrapper from '../../Modal/ModalBodyWrapper/ModalBodyWrapper';

type Props = { data: Damage };

const DamageInfoPaidAt = ({ data }: Props) => {
  const { damage, createdAt, status } = data;

  const mediaExists =
    (damage.mediaIds && damage.mediaIds.length > 0) ||
    (damage.photos && damage.photos.length > 0);

  return (
    <ModalBodyWrapper>
      <Card className="border-primary">
        <CardBody>
          <div className="d-flex align-items-center justify-content-between">
            <div className="font-weight-bold">
              {i18nTranslator(
                status === 'paid'
                  ? 'CHAINSURE_PAID_AT'
                  : 'CHAINSURE_REQUESTED_AT'
              )}
            </div>
            <div className="text-left product-date">
              {datetimeFormat.parseToLocaleString(createdAt, 'full-trim-year')}
            </div>
          </div>
          <div className="mt-0 mt-sm-1">
            <div className="font-weight-bold">
              {i18nTranslator('CHAINSURE_DAMAGE')}
            </div>
            <div>{damage.description}</div>
            {mediaExists && (
              <PhotoGallery
                mediaIds={damage.mediaIds}
                photos={damage.photos}
                className="mt-3"
              />
            )}
          </div>
        </CardBody>
      </Card>
    </ModalBodyWrapper>
  );
};

export default DamageInfoPaidAt;
