import React, { PureComponent } from 'react';
import './ChainsureMakeGift.scss';
import { connect } from 'react-redux';
import { ModalBody } from 'reactstrap';
import { ReduxState } from '../../../../reducers/types';
import chainsureAction from '../../../../actions/chainsure.action';
import Hud from '../../../../components/Hud/Hud';
import { i18nTranslator } from '../../../../utils/i18n';
import ChainsureSendGift from './ChainsureSendGift/ChainsureSendGift';
import ChainsureShareGift from './ChainsureShareGift/ChainsureShareGift';
import ProductInfoActionContainer from '../../../../components/ProductInfo/ActionContainer/ProductInfoActionContainer';
import LoadingIndicator from '../../../../components/Icon/LoadingIndicator';
import Quoteditable from '../../../../components/Quoteditable/Quoteditable';
import draftService from '../../../../utils/draftService';

const mapStateToProps = ({
  chainsures: { action, chainsureId, giftCode, giftCodeError, giftComment },
}: ReduxState) => ({
  chainsureId,
  action,
  giftCode,
  giftComment,
  giftCodeError,
});

type Props = ReturnType<typeof mapStateToProps> & {
  getGiftCode: typeof chainsureAction.getGiftCode;
  toggleMakingGift: typeof chainsureAction.toggleMakingGift;
};

type State = {
  isShowingUserTag: boolean;
};

class ChainsureMakeGift extends PureComponent<Props, State> {
  state: State = {
    isShowingUserTag: true,
  };

  userTagRef: HTMLInputElement | null = null;

  timeoutForValidation: any = null;

  async componentDidMount() {
    const { chainsureId, giftCode, getGiftCode } = this.props;

    if (chainsureId) {
      if (!giftCode) {
        getGiftCode(chainsureId);
      } else {
        await this.tryToRestoreDraft();
      }
    }
  }

  async componentDidUpdate(prevProps: Props, prevState: State) {
    const { chainsureId, giftCode, getGiftCode, action } = this.props;

    if (!chainsureId) {
      return;
    }

    if (action === 'makegift' && !prevProps.action) {
      if (!giftCode) {
        getGiftCode(chainsureId);
      } else {
        await this.tryToRestoreDraft();
      }
    } else if (!action && prevProps.action === 'makegift') {
      await this.saveDraft(prevProps.chainsureId, prevProps.giftCode, prevState);
    }
  }

  async componentWillUnmount() {
    const { chainsureId, action, giftCode } = this.props;

    if (chainsureId && action === 'makegift') {
      await this.saveDraft(chainsureId, giftCode, this.state);
    }
  }

  saveDraft = async (chainsureId: string | null | undefined, giftCode: string | null | undefined, state: State) => {
    if (!chainsureId || !giftCode) return;

    const { isShowingUserTag } = state;

    await draftService.set<Partial<State>>(`ChainsureMakeGift_cId_${chainsureId}_gc_${giftCode}`, {
      isShowingUserTag,
    });
  };

  tryToRestoreDraft = async () => {
    const { chainsureId, giftCode } = this.props;

    const draft = await draftService.get<State>(`ChainsureMakeGift_cId_${chainsureId}_gc_${giftCode}`);

    if (draft) {
      setImmediate(() => {
        this.setState({
          ...draft,
        });
      });
    }
  };

  onClose = () => {
    const { toggleMakingGift } = this.props;

    toggleMakingGift();
  };

  toggleTagUser = () => {
    this.setState((state) => ({
      isShowingUserTag: !state.isShowingUserTag,
    }));
  };

  renderSendGift = () => (
    <ChainsureSendGift
      toggleTagUser={this.toggleTagUser}
      // onSuccess={() => {
      //   this.setState({
      //     isShowingUserTag: false,
      //   });
      // }}
    />
  );

  renderShareGift = () => <ChainsureShareGift toggleTagUser={this.toggleTagUser} />;

  saveGiftComment = async (giftComment: string) => {
    const { chainsureId } = this.props;

    if (!chainsureId) {
      return;
    }

    await chainsureAction.saveGiftComment(chainsureId, giftComment);
  };

  renderGiftDesc = () => {
    const { giftComment, chainsureId, giftCode } = this.props;

    return (
      <Quoteditable
        draftKey={`ChainsureMakeGift_cId_${chainsureId}_gc_${giftCode}`}
        saveRequired
        content={giftComment || ''}
        label={i18nTranslator('CHAINSURE_MAKE_GIFT_COMMENT')}
        onChange={this.saveGiftComment}
      />
    );
  };

  renderContent = () => {
    const { giftCode } = this.props;
    const { isShowingUserTag } = this.state;

    if (!giftCode) {
      return <LoadingIndicator />;
    }

    return (
      <ProductInfoActionContainer
        icon="gift"
        title={i18nTranslator('CHAINSURE_MAKE_GIFT_TITLE')}
        description={i18nTranslator('CHAINSURE_MAKE_GIFT_DESC')}
        onClose={this.onClose}
        className="chainsure-make-gift-modal"
      >
        {this.renderGiftDesc()}
        <div className="gift-action">{isShowingUserTag ? this.renderSendGift() : this.renderShareGift()}</div>
      </ProductInfoActionContainer>
    );
  };

  render() {
    const { action, giftCodeError } = this.props;

    if (action !== 'makegift') return null;

    const hudIsOpen = giftCodeError != null;

    let hudAlertDesc = '';

    if (giftCodeError) {
      switch (giftCodeError.code) {
        case 'WRONG_PERMISSION':
          hudAlertDesc = i18nTranslator('CHAINSURE_ACTION_ERROR_WRONG_PERMISSION');
          break;
        default:
          hudAlertDesc = i18nTranslator('CHAINSURE_MAKE_GIFT_ERROR');
          break;
      }
    }

    return (
      <ModalBody>
        <Hud
          isOpen={hudIsOpen}
          type="alert"
          alertBtnTitle={i18nTranslator('ALERT_OK')}
          alertTitle={i18nTranslator('ALERT_ERROR')}
          alertDesc={hudAlertDesc}
          toggle={this.onClose}
        />
        {this.renderContent()}
      </ModalBody>
    );
  }
}

export default connect(mapStateToProps, {
  getGiftCode: chainsureAction.getGiftCode,
  toggleMakingGift: chainsureAction.toggleMakingGift,
})(ChainsureMakeGift);
