import React, { PureComponent, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Modal, ModalBody } from 'reactstrap';
import chainsureAction from '../../../../actions/chainsure.action';
import GiftAvailable from '../../../../components/ChainsureInfo/GiftAvailable/GiftAvailable';
import ChainsureInfoHistories from '../../../../components/ChainsureInfo/Histories/ChainsureInfoHistories';
import ChainsureInfoModal from '../../../../components/ChainsureInfo/Modal/ChainsureInfoModal';
import ChainsureRequests from '../../../../components/ChainsureInfo/Requests/ChainsureRequests';
import Hud, { HudType } from '../../../../components/Hud/Hud';
import ModalBodyWrapper from '../../../../components/Modal/ModalBodyWrapper/ModalBodyWrapper';
import ModalClose from '../../../../components/Modal/ModalClose/ModalClose';
import ProductBuyingForm from '../../../../components/ProductInfo/BuyingForm/ProductBuyingForm';
import { ReduxState } from '../../../../reducers/types';
import contentFromPattern from '../../../../utils/contentFromPattern';
import datetimeFormat from '../../../../utils/datetime/datetimeFormat';
import draftService from '../../../../utils/draftService';
import { i18nTranslator } from '../../../../utils/i18n';
import numberFormat from '../../../../utils/numberFormat';
import productTypeSelect from '../../../../utils/productTypeSelect';
import queryString from '../../../../utils/queryString';
import ChainsureMakeGift from '../MakeGift/ChainsureMakeGift';
import ChainsureRelease from '../Release/ChainsureRelease';
import ChainsureDamageReport from '../ReportDamage/ChainsureDamageReport';
import './ChainsureDetailModal.scss';

const mapStateToProps = ({
  chainsures: {
    chainsure,
    chainsureError,
    chainsureId,
    buyStatus,
    buyImmediateGift,
    checkoutDetail,
    action,
    shouldFocusOnHistory,
  },
}: ReduxState) => ({
  chainsure,
  chainsureError,
  chainsureId,
  buyStatus,
  buyImmediateGift,
  checkoutDetail,
  action,
  shouldFocusOnHistory,
});

type Props = { onClose: () => void } & ReturnType<typeof mapStateToProps> & {
    selectChainsure: typeof chainsureAction.selectChainsure;
    getChainsureDetail: typeof chainsureAction.getChainsureDetail;
    toggleMakingGift: typeof chainsureAction.toggleMakingGift;
    toggleRelease: typeof chainsureAction.toggleRelease;
    toggleDamage: typeof chainsureAction.toggleDamage;
    buyChainsure: typeof chainsureAction.buyChainsure;
    verifyChainsureCheckout: typeof chainsureAction.verifyChainsureCheckout;
  } & Pick<
    RouteComponentProps<{
      chainsureId: string;
    }>,
    'history' | 'location'
  >;

type State = {
  showBuyForm: boolean;
  rebuyComment: string;
};

class ChainsureDetailModal extends PureComponent<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    if (!props.chainsure && state.showBuyForm) {
      return {
        showBuyForm: false,
      };
    }

    return null;
  }

  state: State = {
    showBuyForm: false,
    rebuyComment: '',
  };

  productBuyingFormRef = React.createRef<ProductBuyingForm>();

  refreshTimeOut: any = null;

  scrollToHistoryItemInterval: any = null;

  componentDidMount() {
    const { chainsure, chainsureId, getChainsureDetail, location } = this.props;

    if (location.search) {
      const qss = queryString.parse(location.search);

      if (qss.paymentId && qss.token && qss.PayerID) {
        return;
      }
    }

    if (chainsureId) {
      if (!chainsure) {
        getChainsureDetail(chainsureId);
      } else if (chainsure.status === 'released') {
        this.getRebuyingNote();
      }
    }

    this.setupTimeoutForUpdateStatus();
  }

  componentDidUpdate(prevProps: Props) {
    const {
      chainsure,
      chainsureId,
      getChainsureDetail,
      checkoutDetail,
      location,
      verifyChainsureCheckout,
      shouldFocusOnHistory,
    } = this.props;

    if (prevProps.chainsureId !== chainsureId) {
      if (location.search) {
        const qss = queryString.parse(location.search);

        if (qss.paymentId) {
          // paypal return url detected

          verifyChainsureCheckout(chainsureId || '', qss.paymentId);

          return;
        }
      }

      if (chainsureId && !chainsure) {
        getChainsureDetail(chainsureId);

        return;
      }
    }

    if (!prevProps.checkoutDetail && checkoutDetail) {
      if (checkoutDetail.isFree) {
        verifyChainsureCheckout(chainsureId || '', checkoutDetail.id);
      } else {
        window.location.href = checkoutDetail.checkoutLink;
      }
    }

    if (chainsure && chainsure.status === 'released' && prevProps.chainsure !== chainsure) {
      this.getRebuyingNote();
    }

    this.setupTimeoutForUpdateStatus();

    if (prevProps.shouldFocusOnHistory == null && shouldFocusOnHistory != null) {
      clearInterval(this.scrollToHistoryItemInterval);
      this.scrollToHistoryItemInterval = setInterval(() => {
        const focusingHistoryItem = document.querySelectorAll('.history-item')[shouldFocusOnHistory];

        if (focusingHistoryItem) {
          focusingHistoryItem.scrollIntoView(false);
          clearInterval(this.scrollToHistoryItemInterval);
        }
      }, 500);
    }
  }

  componentWillUnmount() {
    clearInterval(this.scrollToHistoryItemInterval);
    clearTimeout(this.refreshTimeOut);
  }

  get isFree() {
    const { chainsure } = this.props;

    return chainsure != null && chainsure.product.type === 'free';
  }

  getRebuyingNote = () => {
    const { chainsureId } = this.props;
    const { rebuyComment } = this.state;

    if (rebuyComment || !chainsureId) return;

    chainsureAction
      .getReBuyingNote(chainsureId)
      .then((comment) => {
        this.setState({
          rebuyComment: comment,
        });
      })
      .catch(() => {});
  };

  setupTimeoutForUpdateStatus = () => {
    const { chainsure } = this.props;
    const now = new Date();

    clearTimeout(this.refreshTimeOut);

    if (!chainsure) return;

    const status = chainsureAction.getUseStatus(chainsure);

    switch (status) {
      case 'willuse':
        this.refreshTimeOut = setTimeout(() => {
          this.forceUpdate();

          if (!chainsure.histories || chainsure.histories.length === 0) {
            // case gift has just been unwrapped
            const { getChainsureDetail, chainsureId } = this.props;

            chainsureId && getChainsureDetail(chainsureId);
          }
        }, +datetimeFormat.timestampToDate(chainsure.startDate) - +now);
        break;
      case 'using':
        this.refreshTimeOut = setTimeout(
          () => this.forceUpdate(),
          +datetimeFormat.timestampToDate(chainsure.endDate) - +now
        );
        break;
      default:
        break;
    }
  };

  onBuyProductAgain = (startDate: Date, endDate: Date, comment: string, mediaIds: string[], isImmediate: boolean) => {
    const { chainsureId, buyChainsure } = this.props;

    if (!chainsureId) {
      return;
    }

    buyChainsure(chainsureId, startDate, endDate, comment, mediaIds, isImmediate);
  };

  onMakingGiftClick = () => {
    const { chainsure, toggleMakingGift } = this.props;

    if (!chainsure) return;

    const { id, gift, giftComment, giftSent } = chainsure;

    toggleMakingGift(id, gift, giftComment, giftSent);
  };

  onReleaseClick = () => {
    const { chainsure, toggleRelease } = this.props;

    if (!chainsure) {
      return;
    }

    toggleRelease(chainsure.id, chainsure);
  };

  onDamageClick = () => {
    const { chainsure, toggleDamage } = this.props;

    if (!chainsure) {
      return;
    }

    toggleDamage(chainsure.id);
  };

  toggleBuyAgain = () => {
    this.setState((state) => ({
      showBuyForm: !state.showBuyForm,
    }));
  };

  renderBuyAgainBtnContent = (_: string, __: boolean) => {
    const { chainsure } = this.props;

    if (!chainsure) return null;

    const pattern = i18nTranslator(
      chainsure.product.type === 'free' ? 'PRODUCT_MODAL_BUY_FREE' : 'PRODUCT_MODAL_BUY_PRICE'
    );

    const content = contentFromPattern(
      pattern,
      [
        {
          regex: /\[PRICE\]/g,
          build: () => (
            <span className="price-value ml-1" key="price-value">
              {numberFormat.toLocaleCurrencyString(chainsure.product.currency, chainsure.product.price)}
            </span>
          ),
        },
      ],
      0
    );

    return content;
  };

  renderAction = () => {
    const { chainsure, action, history } = this.props;
    const { showBuyForm, rebuyComment } = this.state;

    if (!chainsure) return null;

    if (chainsure.status === 'verifying_buy') {
      return (
        <ModalBodyWrapper wrapInCard>
          <div className="state-desc">{i18nTranslator('STATE_DESC_INREVIEW_INSURANCE')}</div>
        </ModalBodyWrapper>
      );
    }

    if (showBuyForm) {
      const titleKey = productTypeSelect(chainsure.product.type, {
        free: 'CHAINSURE_BUY_FORM_TITLE_FREE',
        money: 'CHAINSURE_BUY_FORM_TITLE_MONEY',
        good_mood: 'CHAINSURE_BUY_FORM_TITLE_GOOD_MOOD',
        insurance: 'CHAINSURE_BUY_FORM_TITLE_INSURANCE',
      });
      const descKey = productTypeSelect(chainsure.product.type, {
        free: 'CHAINSURE_BUY_FORM_DESC_FREE',
        money: 'CHAINSURE_BUY_FORM_DESC_MONEY',
        good_mood: 'CHAINSURE_BUY_FORM_DESC_GOOD_MOOD',
        insurance: 'CHAINSURE_BUY_FORM_DESC_INSURANCE',
      });

      return (
        <ModalBody>
          <ProductBuyingForm
            productId={chainsure.product.id}
            chainsureId={chainsure.id}
            maxMedia={chainsure.product.maxMedia}
            productType={chainsure.product.type}
            minTime={chainsure.product.minTime}
            onSubmit={this.onBuyProductAgain}
            btnClassName="d-flex chainsure-buy-again-btn align-items-center justify-content-center btn btn-primary"
            btnContent={this.renderBuyAgainBtnContent('', true)}
            initComment={rebuyComment}
            titleKey={titleKey}
            descKey={descKey}
            onClose={this.toggleBuyAgain}
            ref={this.productBuyingFormRef}
          />
        </ModalBody>
      );
    }

    const useStatus = chainsureAction.getUseStatus(chainsure);

    const actionComponent = null;

    switch (useStatus) {
      case 'used': {
        if (chainsure.status === 'released') {
          // state RELEASED
          const stateDescKey = productTypeSelect(
            chainsure.product.type,
            {
              free: 'STATE_DESC_RELEASED_FREE',
              money: 'STATE_DESC_RELEASED_MONEY',
              money_immediate: 'STATE_DESC_RELEASED_MONEY_IMMEDIATE',
              good_mood: 'STATE_DESC_RELEASED_GOOD_MOOD',
              insurance: 'STATE_DESC_RELEASED_INSURANCE',
            },
            chainsure.isImmediate
          );

          const buyChainsureKey = productTypeSelect(
            chainsure.product.type,
            {
              free: 'STATE_RELEASED_FREE',
              money: 'STATE_RELEASED_MONEY',
              money_immediate: 'STATE_RELEASED_MONEY_IMMEDIATE',
              good_mood: 'STATE_RELEASED_GOOD_MOOD',
              insurance: 'STATE_RELEASED_INSURANCE',
            },
            chainsure.isImmediate
          );

          return (
            <>
              <ModalBodyWrapper wrapInCard>
                <div className="state-desc">{i18nTranslator(stateDescKey)}</div>

                <Button type="button" color="primary" className="w-100 " onClick={this.toggleBuyAgain}>
                  {this.renderBuyAgainBtnContent(buyChainsureKey, false)}
                </Button>
              </ModalBodyWrapper>
            </>
          );
        }

        let stateDescKey: string = '';
        let releaseOnlyKey: string = '';

        if (chainsure.product.type === 'free' || (chainsure.product.type === 'money' && chainsure.isImmediate)) {
          // state ACCEPTED (Free/Money Immediate)
          stateDescKey = productTypeSelect(
            chainsure.product.type,
            {
              free: 'STATE_DESC_ACCEPTED_FREE',
              money_immediate: 'STATE_DESC_ACCEPTED_MONEY_IMMEDIATE',
            },
            chainsure.isImmediate
          );

          releaseOnlyKey = productTypeSelect(
            chainsure.product.type,
            {
              free: 'STATE_ACCEPTED_FREE',
              money_immediate: 'STATE_ACCEPTED_MONEY_IMMEDIATE',
            },
            chainsure.isImmediate
          );
        } else {
          // state FINISHED
          stateDescKey = productTypeSelect(chainsure.product.type, {
            money: 'STATE_DESC_FINISHED_MONEY',
            good_mood: 'STATE_DESC_FINISHED_GOOD_MOOD',
            insurance: 'STATE_DESC_FINISHED_INSURANCE',
          });
        }

        let buttons: ReactNode = null;

        if (chainsure.product.type === 'free' || (chainsure.product.type === 'money' && chainsure.isImmediate)) {
          buttons = (
            <div className="mr-0 mb-sm-0 w-100">
              <Button type="button" color="primary" className="w-100" onClick={this.onReleaseClick}>
                {i18nTranslator(releaseOnlyKey || 'CHAINSURE_ACTION_RELEASE')}
              </Button>
            </div>
          );
        } else {
          buttons = (
            <>
              <div className="mr-0 mb-2 mb-sm-0 mr-sm-1 w-100">
                <Button type="button" color="primary" className="w-100" onClick={this.onReleaseClick}>
                  {i18nTranslator('CHAINSURE_ACTION_RELEASE')}
                </Button>
              </div>
              <div className="mr-0 ml-sm-1 w-100">
                <Button type="button" color="danger" className="w-100" onClick={this.onDamageClick}>
                  {i18nTranslator('CHAINSURE_ACTION_REDEEM')}
                </Button>
              </div>
            </>
          );
        }

        return (
          <>
            <ChainsureRelease />
            <ChainsureDamageReport history={history} />
            {!action && (
              <ModalBodyWrapper wrapInCard>
                <div className="state-desc">{i18nTranslator(stateDescKey)}</div>

                <div className="w-100 d-flex flex-column flex-sm-row justify-content-between">{buttons}</div>
              </ModalBodyWrapper>
            )}
          </>
        );
      }
      case 'using': {
        // state STARTED
        const stateDescKey = productTypeSelect(chainsure.product.type, {
          money: 'STATE_DESC_STARTED_MONEY',
          good_mood: 'STATE_DESC_STARTED_GOOD_MOOD',
          insurance: 'STATE_DESC_STARTED_INSURANCE',
        });

        return (
          <>
            <ChainsureRelease />
            <ChainsureDamageReport history={history} />
            {!action && (
              <ModalBodyWrapper wrapInCard>
                <div className="state-desc">{i18nTranslator(stateDescKey)}</div>

                <div className="w-100 d-flex flex-column flex-sm-row justify-content-between">
                  <div className="mr-0 mb-2 mb-sm-0 mr-sm-1 w-100">
                    <Button type="button" color="primary" className="w-100" onClick={this.onReleaseClick}>
                      {i18nTranslator('CHAINSURE_ACTION_RELEASE')}
                    </Button>
                  </div>
                  <div className="mr-0 ml-sm-1 w-100">
                    <Button type="button" color="danger" className="w-100" onClick={this.onDamageClick}>
                      {i18nTranslator('CHAINSURE_ACTION_REDEEM')}
                    </Button>
                  </div>
                </div>
              </ModalBodyWrapper>
            )}
          </>
        );
      }
      case 'willuse': {
        let stateDescKey: string;
        let giftMakeKey: string;

        if (chainsure.giftSent) {
          // State OFFERED
          stateDescKey = productTypeSelect(
            chainsure.product.type,
            {
              free: 'STATE_DESC_OFFERED_FREE',
              money: 'STATE_DESC_OFFERED_MONEY',
              money_immediate: 'STATE_DESC_OFFERED_MONEY_IMMEDIATE',
              good_mood: 'STATE_DESC_OFFERED_GOOD_MOOD',
              insurance: 'STATE_DESC_OFFERED_INSURANCE',
            },
            chainsure.isImmediate
          );

          giftMakeKey = productTypeSelect(
            chainsure.product.type,
            {
              free: 'STATE_OFFERED_FREE',
              money: 'STATE_OFFERED_MONEY',
              money_immediate: 'STATE_OFFERED_MONEY_IMMEDIATE',
              good_mood: 'STATE_OFFERED_GOOD_MOOD',
              insurance: 'STATE_OFFERED_INSURANCE',
            },
            chainsure.isImmediate
          );
        } else if (!chainsure.giftAcceptedFrom) {
          // State BOUGHT
          stateDescKey = productTypeSelect(
            chainsure.product.type,
            {
              free: 'STATE_DESC_BOUGHT_FREE',
              money: 'STATE_DESC_BOUGHT_MONEY',
              money_immediate: 'STATE_DESC_BOUGHT_MONEY_IMMEDIATE',
              good_mood: 'STATE_DESC_BOUGHT_GOOD_MOOD',
              insurance: 'STATE_DESC_BOUGHT_INSURANCE',
            },
            chainsure.isImmediate
          );

          giftMakeKey = productTypeSelect(
            chainsure.product.type,
            {
              free: 'STATE_BOUGHT_FREE',
              money: 'STATE_BOUGHT_MONEY',
              money_immediate: 'STATE_BOUGHT_MONEY_IMMEDIATE',
              good_mood: 'STATE_BOUGHT_GOOD_MOOD',
              insurance: 'STATE_BOUGHT_INSURANCE',
            },
            chainsure.isImmediate
          );
        } else {
          // State ACCEPTED (Money, Good Mood, Insrance)

          stateDescKey = productTypeSelect(
            chainsure.product.type,
            {
              money: 'STATE_DESC_ACCEPTED_MONEY',
              good_mood: 'STATE_DESC_ACCEPTED_GOOD_MOOD',
              insurance: 'STATE_DESC_ACCEPTED_INSURANCE',
            },
            chainsure.isImmediate
          );

          giftMakeKey = productTypeSelect(
            chainsure.product.type,
            {
              money: 'STATE_ACCEPTED_MONEY',
              good_mood: 'STATE_ACCEPTED_GOOD_MOOD',
              insurance: 'STATE_ACCEPTED_INSURANCE',
            },
            chainsure.isImmediate
          );
        }

        const hidePassOn = ['insurance', 'money'].includes(chainsure.product.type) && chainsure.giftAcceptedFrom;

        return (
          <>
            {!hidePassOn && <ChainsureMakeGift />}
            {!action && (
              <ModalBodyWrapper wrapInCard>
                <div className="state-desc">{i18nTranslator(stateDescKey)}</div>

                {!hidePassOn && (
                  <Button type="button" color="secondary" className="w-100" onClick={this.onMakingGiftClick}>
                    {i18nTranslator(
                      giftMakeKey ||
                        (chainsure.giftAcceptedFrom ? 'CHAINSURE_ACTION_MAKEGIFT_2ND' : 'CHAINSURE_ACTION_MAKEGIFT')
                    )}
                  </Button>
                )}
              </ModalBodyWrapper>
            )}
          </>
        );
      }
      default:
        break;
    }

    return <ModalBody>{actionComponent}</ModalBody>;
  };

  onClose = () => {
    const { onClose } = this.props;

    onClose();
  };

  successErrorAlertClose = async () => {
    const {
      history,
      chainsureId,
      selectChainsure,
      getChainsureDetail,
      chainsureError,
      onClose,
      buyStatus,
    } = this.props;

    if (chainsureError || buyStatus === 'error') {
      onClose();

      this.setState({
        showBuyForm: false,
      });

      return;
    }

    if (buyStatus === 'success') {
      if (this.productBuyingFormRef.current) {
        await this.productBuyingFormRef.current.discardDraft();
      } else {
        const productBuyingFormBaseDraf = ProductBuyingForm.BuildDraftKey(undefined, chainsureId);

        await draftService.remove(productBuyingFormBaseDraf);
        await draftService.remove(`Quoteditable_${productBuyingFormBaseDraf}`);
        await draftService.remove(`PhotoUploader_${productBuyingFormBaseDraf}`);
      }
    }

    if (chainsureId) {
      history.replace(`/inventories/c/${chainsureId}`);

      selectChainsure(chainsureId);
      getChainsureDetail(chainsureId);
    }

    this.setState({
      showBuyForm: false,
    });
  };

  renderTopComponent = () => {
    const { chainsureId, chainsure } = this.props;

    if (chainsureId == null || chainsure == null) {
      return null;
    }

    const useStatus = chainsureAction.getUseStatus(chainsure);

    switch (useStatus) {
      case 'willuse': {
        return (
          <GiftAvailable
            giftCode={chainsure.gift}
            giftSent={chainsure.giftSent}
            hasRequests={!!chainsure.requestedBy && chainsure.requestedBy.length > 0}
            className="on-modal"
            tooltipPlacement="right"
            chainsureId={chainsure.id}
          />
        );
      }
      case 'used': {
        return (
          <GiftAvailable
            hasRequests={!!chainsure.requestedBy && chainsure.requestedBy.length > 0}
            className="on-modal"
            tooltipPlacement="right"
            chainsureId={chainsure.id}
          />
        );
      }
      default:
        return null;
    }
  };

  render() {
    const { chainsureId, chainsure, buyStatus, chainsureError, buyImmediateGift } = this.props;

    const showingDetail = chainsureId != null && chainsure != null && (!buyStatus || buyStatus === 'buying');

    let hudType: HudType | null = null;
    let hudAlertBtnTitle: string | null = null;
    let hudAlertTitle: string | null = null;
    let hudAlertDesc: string | null = null;

    switch (buyStatus) {
      case 'success':
        hudType = 'toast';
        if (chainsure && buyImmediateGift != null) {
          hudAlertDesc = i18nTranslator(
            productTypeSelect(
              chainsure.product.type,
              {
                free: 'CHAINSURE_MODAL_BUY_FREE_SUCCESS',
                money: 'CHAINSURE_MODAL_BUY_MONEY_SUCCESS',
                money_immediate: 'CHAINSURE_MODAL_BUY_MONEY_IMMEDIATE_SUCCESS',
                good_mood: 'CHAINSURE_MODAL_BUY_GOOD_MOOD_SUCCESS',
                insurance: 'CHAINSURE_MODAL_BUY_INSURANCE_SUCCESS',
              },
              buyImmediateGift
            )
          );
        }
        break;
      case 'error':
        hudType = 'alert';
        hudAlertBtnTitle = i18nTranslator('ALERT_OK');
        hudAlertTitle = i18nTranslator('ALERT_ERROR');
        hudAlertDesc = i18nTranslator('CHAINSURE_MODAL_BUY_ERROR');
        break;
      case 'buying':
      case 'verifying':
        hudType = 'loading';
        break;
      default:
        if (chainsureId != null && chainsure == null && !chainsureError) {
          hudType = 'loading';
        }
        break;
    }

    if (chainsureError) {
      if (!hudType) {
        hudType = 'alert';
      }

      hudAlertBtnTitle = i18nTranslator('ALERT_OK');
      hudAlertTitle = i18nTranslator('ALERT_ERROR');

      switch (chainsureError.code) {
        case 'WRONG_PERMISSION':
        case 'CHAINSURE_NOT_FOUND':
          hudAlertDesc = i18nTranslator('CHAINSURE_ACTION_ERROR_WRONG_PERMISSION');
          break;
        default:
          hudAlertDesc = chainsureError.code;
          break;
      }
    }

    return (
      <>
        <Hud
          type={hudType}
          isOpen={hudType != null}
          alertBtnTitle={hudAlertBtnTitle}
          alertTitle={hudAlertTitle}
          alertDesc={hudAlertDesc}
          toastMessage={hudAlertDesc}
          toastTimeout={1000}
          toggle={this.successErrorAlertClose}
        />
        {showingDetail && (
          <Modal
            isOpen={chainsureId != null}
            toggle={this.onClose}
            centered
            modalClassName="modal-product-detail modal-chainsure-detail"
          >
            <ModalClose onClick={this.onClose} />
            {this.renderTopComponent()}
            {chainsure && <ChainsureInfoModal chainsure={chainsure} />}
            {this.renderAction()}
            <ChainsureRequests />
            <ChainsureInfoHistories />
          </Modal>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, {
  selectChainsure: chainsureAction.selectChainsure,
  getChainsureDetail: chainsureAction.getChainsureDetail,
  toggleMakingGift: chainsureAction.toggleMakingGift,
  toggleRelease: chainsureAction.toggleRelease,
  toggleDamage: chainsureAction.toggleDamage,
  buyChainsure: chainsureAction.buyChainsure,
  verifyChainsureCheckout: chainsureAction.verifyChainsureCheckout,
})(ChainsureDetailModal);
