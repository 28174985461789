const arrayUnion: <T>(arr1: T[], arr2: T[]) => T[] = <T>(arr1, arr2) => {
  const obj: { [id: string]: T } = {};

  for (let i = arr1.length - 1; i >= 0; i--) {
    obj[arr1[i].toString()] = arr1[i];
  }
  for (let i = arr2.length - 1; i >= 0; i--) {
    obj[arr2[i].toString()] = arr2[i];
  }

  const res: T[] = [];

  for (const k in obj) {
    if (obj.hasOwnProperty(k)) {
      res.push(obj[k]);
    }
  }

  return res;
};

export default arrayUnion;
