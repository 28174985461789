export const firebaseConfig = {
  apiKey: 'AIzaSyBDb_3T0eTnqubJNeewFVn-H34ZBchBXsI',
  authDomain: 'chainsure-local.firebaseapp.com',
  databaseURL: 'https://chainsure-local.firebaseio.com',
  projectId: 'chainsure-local',
  storageBucket: 'chainsure-local.appspot.com',
  messagingSenderId: '50917774658'
}

export const host = {
  url: 'https://localhost:3000',
  rootApi: 'http://localhost:5001/chainsure-local/us-central1/api',
  rootPages: 'http://localhost:5001/chainsure-local/us-central1/pages'
}

export const social = {
  facebook: {
    appID: 881281275292307,
    version: 'v3.2'
  },
  twitter: {
    account: 'phunhakent'
  }
}

export const originstamp = {
  timestampUrl: 'https://originstamp.org/s/{{hash}}'
}

export const freeCurrencyConverter = {
  rootApi: 'https://free.currencyconverterapi.com/api/v6',
  apiKey: '1047d015658c825c204c'
}

export const cloudinary = {
  rootApi: 'https://api.cloudinary.com/v1_1/dowalhw0e',
  cloudName: 'dowalhw0e',
  unsignUploadPreset: 'x29n5tbu',
  apiKey: '432851346387786'
}

// export const host = {
//   url: 'https://localhost:3000',
//   rootApi: 'http://localhost:8010/chainsure-13a6a/us-central1/api'
// }

// export const firebaseConfig = {
//   apiKey: 'AIzaSyC781prsA6l7NqSriGzZ9rO9GkJgzXzu68',
//   authDomain: 'chainsure-13a6a.firebaseapp.com',
//   databaseURL: 'https://chainsure-13a6a.firebaseio.com',
//   projectId: 'chainsure-13a6a',
//   storageBucket: 'chainsure-13a6a.appspot.com',
//   messagingSenderId: '299675202751'
// }

// export const social = {
//   facebook: {
//     appID: 1581349775497554,
//     version: 'v3.2'
//   },
//   twitter: {
//     account: 'PLIDScom'
//   }
// }
