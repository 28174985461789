import React, { ReactNode } from 'react';
import './PhotoGallery.scss';
import mediaService from '../../../utils/mediaService';
import FontAwesomeIcon from '../../Icon/FontAwesomeIcon';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Props = {
  mediaIds?: string[];
  photos?: any[];
  className?: string;
  onMediaSelect?: (index: number) => any;
};

const PhotoGallery = ({
  mediaIds,
  photos,
  className,
  onMediaSelect,
}: Props) => {
  let mediaList: ReactNode = null;

  if (mediaIds) {
    mediaList = mediaIds.map((mediaId, index) => {
      const { type, id, fullLink, thumbnailLink } = mediaService.getMedia(
        mediaId
      );

      let typeIcon: IconProp = 'question-circle';
      switch (type) {
        case 'video': {
          typeIcon = 'video';
          break;
        }
        case 'image': {
          typeIcon = 'image';
          break;
        }
        default:
          break;
      }

      return (
        <a
          href={fullLink}
          target="_blank"
          rel="noopener noreferrer"
          className={`photo-gallery-media media-${type}`}
          key={id}
          onClick={(e) => {
            if (!onMediaSelect) return;

            e.preventDefault();

            onMediaSelect(index);
          }}
        >
          <img alt="" src={thumbnailLink} className="w-100" />
          <div className="type-indicator">
            <FontAwesomeIcon icon={typeIcon} />
          </div>
        </a>
      );
    });
  } else if (photos) {
    mediaList = photos.map((photo) => {
      const { id, url } = photo;

      return (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="photo-gallery-media"
          key={id}
        >
          <img alt="" src={url} className="w-100" />
          <div className="type-indicator">
            {/* TODO: Check the size (15) */}
            <FontAwesomeIcon icon="image" size="2x" />
          </div>
        </a>
      );
    });
  }

  return (
    <div className={`d-flex flex-wrap photo-gallery ${className}`}>
      {mediaList}
    </div>
  );
};

export default PhotoGallery;
