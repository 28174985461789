import config from './config';

const openDialog = (link: string) => {
  const twitterLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    link
  )}&text=Chainsure%20Gift&via=${encodeURIComponent(config.social.twitter.account)}&hashtags=Chainsure,ChainsureGift`;
  const height = 350;
  const width = 500;
  const top = window.top.outerHeight / 2 + window.top.screenY - height / 2;
  const left = window.top.outerWidth / 2 + window.top.screenX - width / 2;

  window.open(
    twitterLink,
    'twitter-share',
    `menubar=no,location=yes,resizable=yes,scrollbars=yes,status=no,height=${height},width=${width},top=${top},left=${left}`
  );
};

export default {
  openDialog,
};
