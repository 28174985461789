import React, { ReactNode } from 'react';
import arrayFlat from './arrayFlat';

const contentFromPattern = (
  pattern: string,
  patternConfigs: { regex: RegExp; build: (textMatch: string) => ReactNode }[],
  fromPatternIndex: number,
  forKey?: string
): ReactNode[] => {
  const contents: ReactNode[] = [];
  let i = fromPatternIndex;

  for (; i < patternConfigs.length; i++) {
    const patternConfig = patternConfigs[i];
    let match: RegExpMatchArray | null = null;
    let lastIndex = 0;

    patternConfig.regex.lastIndex = 0;

    if (patternConfig.regex.test(pattern)) {
      patternConfig.regex.lastIndex = 0;

      while ((match = patternConfig.regex.exec(pattern)) !== null) {
        const leadingText = pattern.substring(lastIndex, match.index);

        if (leadingText) {
          contents.push(
            contentFromPattern(leadingText, patternConfigs, i + 1, `${forKey || ''}-${lastIndex}-${match.index}`)
          );
        }

        contents.push(patternConfig.build(match[0]));

        lastIndex = (match.index || 0) + match[0].length;
      }

      const trailingText = pattern.substr(lastIndex);
      if (trailingText) {
        contents.push(contentFromPattern(trailingText, patternConfigs, i + 1, `${forKey || ''}-${lastIndex}--1`));
      }

      break;
    }
  }

  if (i === patternConfigs.length) {
    // not match any regex => return span
    contents.push(<span key={`content-from-pattern-${forKey || ''}`}>{pattern}</span>);
  }

  return arrayFlat(contents);
};

export default contentFromPattern;
