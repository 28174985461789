import React from 'react';
import './Showcase.scss';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Container, Row, Col } from 'reactstrap';
import parallelogramSvg from '../../assets/parallelogram.svg';
import appstoreSvg from '../../assets/appstore.svg';
import playstoreSvg from '../../assets/playstore.svg';

type Props = WithNamespaces;

type ScreenData = { screenshot: string; title: string; desc: string };

const Showcase = ({ t }: Props) => {
  const screens: ScreenData[] = t('APP_SCREENS', {
    returnObjects: true,
  });

  return (
    <Container tag="main" role="main" className="showcase">
      <Row className="pt-3 pb-3" noGutters>
        <img src={parallelogramSvg} className="parallelogram" alt="..." />
        <Col>
          <Row noGutters>
            <Col
              lg={{
                size: 5,
              }}
              md={{
                size: 5,
              }}
              sm={{
                size: 5,
              }}
              className="text-center"
            >
              <img src={t('APP_IMG')} className="app-dashboard" alt="dashboard" />
            </Col>
            <Col
              lg={{
                size: 6,
                offset: 1,
              }}
              md={{
                size: 6,
                offset: 1,
              }}
              sm={{
                size: 7,
              }}
              className="d-flex flex-column justify-content-center align-items-center pl-lg-3 pl-2 pr-lg-3 pr-2 app-desc"
            >
              <div className="title">{t('APP_TITLE')}</div>
              <div className="mt-2 desc">{t('APP_DESC')}</div>
              <div className="mt-2 d-flex links">
                <a href="https://itunes.apple.com/app/id1317284556?mt=8" target="_blank" rel="noopener noreferrer">
                  <img src={appstoreSvg} className="app-link" alt="..." />
                </a>

                <a
                  href="https://play.google.com/store/apps/details?id=de.chainsure.my"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={playstoreSvg} className="app-link" alt="..." />
                </a>
              </div>
            </Col>
          </Row>

          <Row noGutters>
            <Col className="pl-2 pr-2 pt-5 text-center top-msg font-weight-bold text-primary">
              {t('APP_SCREENS_TOP')}
            </Col>
          </Row>

          {screens.map(({ screenshot, title, desc }: ScreenData, index) => {
            const even = index % 2 === 0;
            return (
              <Row key={screenshot} noGutters className={`${even ? 'flex-row-reverse' : ''} screen mt-5 mt-sm-1`}>
                <Col
                  lg={{
                    size: 4,
                    offset: even ? 1 : 0,
                  }}
                  sm={{
                    size: 6,
                    offset: even ? 1 : 0,
                  }}
                  className="text-center pl-1 pr-1"
                >
                  <img src={screenshot} alt="screenshot" />
                </Col>
                <Col
                  lg={{
                    size: 7,
                    offset: even ? 0 : 1,
                  }}
                  sm={{
                    size: 5,
                    offset: even ? 0 : 1,
                  }}
                  className="d-flex flex-column justify-content-center align-items-center align-items-lg-center text-center pl-2 pr-2 screen-info"
                >
                  <div className="title font-weight-bold">{title}</div>
                  <div className="description mt-2">{desc}</div>
                </Col>
              </Row>
            );
          })}

          <Row noGutters>
            <Col className="p-3 text-center bottom-msg font-weight-medium text-primary">{t('APP_SCREENS_BOTOM')}</Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default withNamespaces('showcase')(Showcase);
