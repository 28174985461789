import React, { PureComponent } from 'react';
import { Modal } from 'reactstrap';
import { connect } from 'react-redux';
import { ReduxState } from '../../../../reducers/types';
import ModalClose from '../../../../components/Modal/ModalClose/ModalClose';
import Hud from '../../../../components/Hud/Hud';
import damageAction from '../../../../actions/damage.action';
import ChainsureInfoModal from '../../../../components/ChainsureInfo/Modal/ChainsureInfoModal';
import DamageInfoPaidAt from '../../../../components/ChainsureInfo/DamageInfoPaidAt/DamageInfoPaidAt';
import ChainsureInfoHistories from '../../../../components/ChainsureInfo/Histories/ChainsureInfoHistories';
import productTypeSelect from '../../../../utils/productTypeSelect';
import { i18nTranslator } from '../../../../utils/i18n';
import ModalBodyWrapper from '../../../../components/Modal/ModalBodyWrapper/ModalBodyWrapper';

const mapStateToProps = (state: ReduxState) => {
  const { damage, damageId } = state.damages;

  return {
    damage,
    damageId,
  };
};

type Props = { onClose: () => void } & ReturnType<typeof mapStateToProps> & {
    getDamageDetail: typeof damageAction.getDamageDetail;
  };

class DamageDetailModal extends PureComponent<Props> {
  modalRef = React.createRef<Modal>();

  componentDidMount() {
    const { damage, damageId, getDamageDetail } = this.props;

    if (damageId && !damage) {
      getDamageDetail(damageId);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { damage, damageId, getDamageDetail } = this.props;

    if (prevProps.damageId !== damageId) {
      if (damageId && !damage) {
        getDamageDetail(damageId);
      }
    }
  }

  onClose = () => {
    const { onClose } = this.props;

    onClose();
  };

  renderStateDesc = () => {
    const { damage } = this.props;

    if (!damage) return null;

    let stateDescKey = '';

    if (damage.isDeniedInsurance) {
      // state REJECTED REFUNDED
      stateDescKey =
        damage.status === 'pay_waiting' ? 'STATE_DESC_REJECTED_INSURANCE' : 'STATE_DESC_REFUNDED_INSURANCE';
    } else {
      // state REDEEMED & PAID
      stateDescKey =
        damage.status === 'pay_waiting'
          ? productTypeSelect(
              damage.product.type,
              {
                money: 'STATE_DESC_REDEEMED_MONEY',
                good_mood: 'STATE_DESC_REDEEMED_GOOD_MOOD',
                insurance: 'STATE_DESC_REDEEMED_INSURANCE',
              },
              damage.isImmediate
            )
          : productTypeSelect(
              damage.product.type,
              {
                money: 'STATE_DESC_PAID_MONEY',
                good_mood: 'STATE_DESC_PAID_GOOD_MOOD',
                insurance: 'STATE_DESC_PAID_INSURANCE',
              },
              damage.isImmediate
            );
    }

    return (
      <ModalBodyWrapper wrapInCard>
        <div className="state-desc">{i18nTranslator(stateDescKey)}</div>
      </ModalBodyWrapper>
    );
  };

  render() {
    const { damageId, damage } = this.props;

    const showChainsureDetail = damageId != null && damage != null;

    return (
      <>
        <Hud type="loading" isOpen={damageId != null && damage == null} />
        {showChainsureDetail && (
          <Modal
            isOpen={damageId != null}
            toggle={this.onClose}
            centered
            modalClassName="modal-product-detail modal-damage-detail"
            ref={this.modalRef}
          >
            <ModalClose onClick={this.onClose} />
            {damage && <ChainsureInfoModal chainsure={damage} />}
            {this.renderStateDesc()}
            {damage && <DamageInfoPaidAt data={damage} />}
            <ChainsureInfoHistories />
          </Modal>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, {
  getDamageDetail: damageAction.getDamageDetail,
})(DamageDetailModal);
