import { ThunkAction, Action } from './types';
import { Chainsure } from '../types';
import httpClient from '../utils/httpClient';
import getErrorFromAxios from '../utils/getErrorFromAxios';

const getGift = (chainsureId: string, giftCode: string): ThunkAction => async (dispatch) => {
  try {
    const response = await httpClient.app.get<Chainsure>(`/gift/c/${chainsureId}/g/${giftCode}`);

    dispatch({
      type: 'GIFT_DETAIL_LOADED',
      payload: {
        chainsure: response.data,
      },
    });
  } catch (error) {
    console.log(error);

    const responseError = getErrorFromAxios(error);

    if (responseError) {
      dispatch({
        type: 'GIFT_ERROR',
        payload: {
          chainsureId,
          error: responseError,
        },
      });
    }
  }
};

const acceptGift = (chainsureId: string, giftCode: string): ThunkAction => async (dispatch) => {
  try {
    dispatch({
      type: 'GIFT_ACCEPT',
    });

    await httpClient.app.patch<Chainsure>(`/gift/c/${chainsureId}/g/${giftCode}`, {
      chainsureId,
    });

    dispatch({
      type: 'GIFT_SUCCESS',
      payload: {
        chainsureId,
      },
    });
  } catch (error) {
    console.log(error);

    const responseError = getErrorFromAxios(error);

    if (responseError) {
      dispatch({
        type: 'GIFT_ERROR',
        payload: {
          chainsureId,
          error: responseError,
        },
      });
    }
  }
};

const selectGift = (chainsureId: string | null, giftCode: string | null): ThunkAction => async (dispatch, getState) => {
  dispatch({
    type: 'GIFT_SELECT',
    payload: {
      chainsureId,
      giftCode,
    },
  });

  if (chainsureId && giftCode) {
    getGift(chainsureId, giftCode)(dispatch, getState);
  }
};

const resetSendForm = (): Action => ({
  type: 'CHAINSURE_GIFT_SEND_CLEAN',
});

const sentGiftToUser = (
  chainsureId: string,
  giftCode: string,
  usernameOrEmail: string,
  comment: string
): ThunkAction => async (dispatch) => {
  try {
    dispatch({
      type: 'CHAINSURE_GIFT_SENDING',
    });

    await httpClient.app.post(`/gift/c/${chainsureId}/g/${giftCode}`, {
      usernameOrEmail,
      comment,
    });

    dispatch({
      type: 'CHAINSURE_GIFT_SENT_SUCCESS',
    });
  } catch (error) {
    console.log(error);

    const responseError = getErrorFromAxios(error);

    if (responseError) {
      dispatch({
        type: 'CHAINSURE_GIFT_SENT_ERROR',
        payload: {
          error: responseError,
        },
      });
    }
  }
};

export default {
  getGift,
  acceptGift,
  sentGiftToUser,
  resetSendForm,
  selectGift,
};
