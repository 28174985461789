import React, { PureComponent } from 'react';
import './LightboxGallery.scss';
import FsLightbox from 'fslightbox-react';
import { Modal } from 'reactstrap';

type Props = {
  showingGallery: boolean;
  mediasUrls: string[];
  mediasThumbnails: string[];
  mediasTypes: string[];
  index: number;
  onClose: () => any;
};

type State = {
  showingLightbox: boolean;
};

export default class LightboxGallery extends PureComponent<Props, State> {
  state: State = {
    showingLightbox: false,
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { showingGallery, onClose } = this.props;
    const { showingLightbox } = this.state;

    if (!prevProps.showingGallery && showingGallery) {
      setImmediate(() => {
        this.setState({
          showingLightbox: true,
        });
      });
    }

    if (!showingLightbox && prevState.showingLightbox) {
      setImmediate(() => {
        onClose();
        // document.querySelector('html').classList.remove('fslightbox-open')
      });
    }
  }

  render() {
    const {
      showingGallery,
      mediasUrls,
      mediasTypes,
      mediasThumbnails,
      index,
      onClose,
    } = this.props;
    const { showingLightbox } = this.state;

    return (
      <Modal
        isOpen={showingGallery}
        toggle={onClose}
        fade={false}
        backdrop="static"
        className="modal-lightbox-alert"
      >
        <div>
          <FsLightbox
            onClose={() => {
              this.setState({
                showingLightbox: false,
              });
            }}
            toggler={showingLightbox}
            sourceIndex={index}
            sources={mediasUrls}
            types={mediasTypes}
            videosPosters={mediasThumbnails}
          />
        </div>
      </Modal>
    );
  }
}
