import React, { PureComponent, SyntheticEvent, ReactNode } from 'react';
import LoadingIndicator from '../../../Icon/LoadingIndicator';
import FontAwesomeIcon from '../../../Icon/FontAwesomeIcon';
import datetimeFormat from '../../../../utils/datetime/datetimeFormat';
import imageTools from '../../../../utils/imageTools';

type Props = {
  status?: string | null;
  photoId?: string | null;
  photoUrl?: string | null;
  photoData?: any;
  type?: string;
  name: string;
  file: File;
  onRemove: (name: string) => void;
  onPreTouch: (blob: Blob, name: string) => any;
};

type State = {
  status: 'uploading' | 'success' | 'error';
  thumbnail?: string | null;
  videoHeight: number;
  videoWidth: number;
  videoDuration: number;
};

class PhotoUploadItem extends PureComponent<Props, State> {
  state: State = {
    status: 'uploading',
    videoHeight: 0,
    videoWidth: 0,
    videoDuration: 0,
  };

  canvasRef: HTMLCanvasElement | null = null;
  videoRef: HTMLVideoElement | null = null;

  componentDidMount() {
    const { file } = this.props;

    if (file.type.startsWith('image')) {
      if (typeof URL === 'undefined') {
        const fileReader = new FileReader();

        fileReader.onload = () => {
          this.setState({
            status: 'success',
            thumbnail: fileReader.result && fileReader.result.toString(),
          });
        };

        fileReader.onerror = () => {
          this.setState({
            status: 'error',
          });
        };

        fileReader.readAsDataURL(file);
      } else {
        this.setState({
          status: 'success',
          thumbnail: URL.createObjectURL(file),
        });
      }
    }
  }

  componentDidUpdate(_: Props, prevState: State) {
    const { file, name } = this.props;
    const { thumbnail } = this.state;

    if (file.type.match(/^image/) && thumbnail && !prevState.thumbnail) {
      this.scaleImageFile(file, name);
    }
  }

  scaleImageFile = async (file: File, name: string) => {
    try {
      let touchedFile = await imageTools.resize(file, {
        width: 2048,
        height: 1080,
      });

      if (touchedFile.size > 1048576) {
        touchedFile = await imageTools.scale(touchedFile, 1048576);
      }

      const { onPreTouch } = this.props;
      onPreTouch(touchedFile, name);
    } catch (error) {}
  };

  onRemoveClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    const { name, onRemove } = this.props;

    onRemove(name);

    event.preventDefault();
  };

  videoTimeUpdate = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    const { videoHeight, videoWidth, thumbnail } = this.state;

    if (
      videoHeight * videoWidth !== 0 &&
      thumbnail == null &&
      e.currentTarget.currentTime !== 0
    ) {
      if (!this.canvasRef) {
        return;
      }

      const context = this.canvasRef.getContext('2d');

      if (!context) {
        return;
      }

      this.canvasRef.width = videoWidth;
      this.canvasRef.height = videoHeight;
      context.drawImage(e.currentTarget, 0, 0, videoWidth, videoHeight);

      this.setState({
        thumbnail: this.canvasRef.toDataURL(),
        status: 'success',
      });
    }
  };

  videoLoadedMetaData = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    const { videoDuration, videoWidth, videoHeight } = this.state;

    if (
      e.currentTarget.videoHeight *
        e.currentTarget.videoWidth *
        e.currentTarget.duration ===
      0
    ) {
      return;
    }

    if (videoWidth === 0 && videoHeight === 0 && videoDuration === 0) {
      const { duration } = e.currentTarget;

      this.setState(
        {
          videoHeight: e.currentTarget.videoHeight,
          videoWidth: e.currentTarget.videoWidth,
          videoDuration: duration,
        },
        () => {
          if (this.videoRef) {
            this.videoRef.currentTime = duration / 2;
          }
        }
      );
    }
  };

  render() {
    const { file } = this.props;
    const {
      status,
      thumbnail,
      videoHeight,
      videoWidth,
      videoDuration,
    } = this.state;

    let content: ReactNode = null;

    switch (status) {
      case 'uploading':
        content = (
          <>
            <LoadingIndicator />
          </>
        );
        break;
      case 'success':
        content = (
          <>
            <div className="thumnail-container">
              <img alt="" src={thumbnail || undefined} className="w-100" />
            </div>
            {file.type.startsWith('video') && videoDuration !== 0 && (
              <div className="video-duration">
                {datetimeFormat.parseDurationToString(videoDuration)}
              </div>
            )}
            <button
              type="button"
              className="photo-remove"
              onClick={this.onRemoveClick}
            >
              <FontAwesomeIcon icon="trash" size="lg" />
            </button>
            <div className="d-block d-sm-none pt-2 w-100">
              <button
                type="button"
                className="photo-remove-bottom"
                onClick={this.onRemoveClick}
              >
                <FontAwesomeIcon icon="trash" />
              </button>
            </div>
          </>
        );
        break;
      case 'error':
        content = (
          <>
            <FontAwesomeIcon icon="exclamation-triangle" />
            <button
              type="button"
              className="photo-remove"
              onClick={this.onRemoveClick}
            >
              <FontAwesomeIcon icon="trash" size="lg" />
            </button>
            <div className="d-block d-sm-none pt-2 w-100">
              <button
                type="button"
                className="photo-remove-bottom"
                onClick={this.onRemoveClick}
              >
                <FontAwesomeIcon icon="trash" />
              </button>
            </div>
          </>
        );
        break;
      default:
        break;
    }

    let videoComponent: ReactNode = null;
    if (file.type.startsWith('video') && thumbnail == null) {
      videoComponent = (
        <>
          <video
            controls
            onLoadedMetadata={this.videoLoadedMetaData}
            onTimeUpdate={this.videoTimeUpdate}
            ref={(ref) => {
              this.videoRef = ref;
            }}
            autoPlay={false}
            style={{
              position: 'absolute',
              top: '100%',
            }}
          >
            {/* <source type={file} src={URL.createObjectURL(file)} /> */}
            <source type="video/mp4" src={URL.createObjectURL(file)} />
          </video>
          <canvas
            width={videoWidth}
            height={videoHeight}
            ref={(ref) => {
              this.canvasRef = ref;
            }}
            style={{
              position: 'absolute',
              top: '100%',
            }}
          />
        </>
      );
    }

    return (
      <div className={`photo-upload-item photo-upload-item-${status}`}>
        {content}
        {videoComponent}
      </div>
    );
  }
}

export default PhotoUploadItem;
