import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Modal } from 'reactstrap';
import productActions from '../../../../actions/product.actions';
import Hud, { HudType } from '../../../../components/Hud/Hud';
import ModalBodyWrapper from '../../../../components/Modal/ModalBodyWrapper/ModalBodyWrapper';
import ModalClose from '../../../../components/Modal/ModalClose/ModalClose';
import ProductInfoBasic from '../../../../components/ProductInfo/Basic/ProductInfoBasic';
import ProductBuyingForm from '../../../../components/ProductInfo/BuyingForm/ProductBuyingForm';
import ProductInfoPrice from '../../../../components/ProductInfo/Price/ProductInfoPrice';
import { ReduxState } from '../../../../reducers/types';
import contentFromPattern from '../../../../utils/contentFromPattern';
import draftService from '../../../../utils/draftService';
import { i18nTranslator } from '../../../../utils/i18n';
import numberFormat from '../../../../utils/numberFormat';
import productTypeSelect from '../../../../utils/productTypeSelect';
import queryString from '../../../../utils/queryString';
import './ProductDetailModal.scss';

const mapStateToProps = (state: ReduxState) => {
  const { selectedProductId, selectedProduct, checkoutDetail, buyStatus, buyImmediateGift } = state.products;

  return {
    productId: selectedProductId,
    product: selectedProduct,
    checkoutDetail,
    buyStatus,
    buyImmediateGift,
  };
};

type Props = ReturnType<typeof mapStateToProps> & {
  onClose: () => void;
} & {
  getProductDetail: typeof productActions.getProductDetail;
  selectProduct: typeof productActions.selectProduct;
  buyProduct: typeof productActions.buyProduct;
  verifyProductCheckout: typeof productActions.verifyProductCheckout;
} & Pick<
    RouteComponentProps<{
      productId: string;
    }>,
    'history' | 'location'
  >;

type State = {
  showBuyForm: boolean;
  buyComment: string;
};

class ProductDetailModal extends Component<Props, State> {
  state: State = {
    showBuyForm: false,
    buyComment: '',
  };

  productBuyingFormRef = React.createRef<ProductBuyingForm>();

  shouldSaveDraft = true;

  async componentDidMount() {
    const { productId, getProductDetail, product, location } = this.props;

    if (location.search) {
      const qss = queryString.parse(location.search);

      if (qss.paymentId && qss.token && qss.PayerID) {
        return;
      }
    }

    if (productId) {
      if (!product) {
        getProductDetail(productId);
      }

      this.tryToRestoreDraft();
    }
  }

  async componentDidUpdate(prevProps: Props) {
    const { getProductDetail, checkoutDetail, productId, product, location, verifyProductCheckout } = this.props;

    if (productId !== prevProps.productId) {
      setImmediate(() => {
        this.setState({
          buyComment: '',
        });
      });

      if (productId && !prevProps.productId) {
        this.shouldSaveDraft = true;
      }

      if (location.search) {
        const qss = queryString.parse(location.search);

        if (qss.paymentId) {
          // paypal return url detected
          verifyProductCheckout(productId || '', qss.paymentId);

          return;
        }
      }

      if (productId) {
        if (!product) {
          getProductDetail(productId);
        }

        this.tryToRestoreDraft();

        return;
      }
    }

    if (!prevProps.checkoutDetail && checkoutDetail) {
      if (checkoutDetail.isFree) {
        verifyProductCheckout(productId || '', checkoutDetail.id);
      } else {
        window.location.href = checkoutDetail.checkoutLink;
      }
    }
  }

  async componentWillUnmount() {
    const { productId, selectProduct } = this.props;

    if (productId) {
      selectProduct(null);

      await this.saveDraft();
    }
  }

  get isFree() {
    const { product } = this.props;

    return product != null && product.price === 0;
  }

  tryToRestoreDraft = async () => {
    const { productId } = this.props;

    const draft = await draftService.get<State>(`ProductDetailModal_${productId}`);

    if (draft) {
      setImmediate(() => {
        this.setState({
          ...draft,
        });
      });
    }

    if (!draft || !draft.buyComment) {
      this.getBuyingNote();
    }
  };

  getBuyingNote = () => {
    const { productId } = this.props;

    if (!productId) {
      return;
    }

    productActions
      .getBuyingNote(productId)
      .then((comment) => {
        this.setState({
          buyComment: comment,
        });
      })
      .catch(() => {});
  };

  renderContent = () => {
    const { product } = this.props;

    if (!product) return null;

    return (
      <>
        <ProductInfoBasic {...product} />
        <ProductInfoPrice price={product.price} currency={product.currency} titleKey="PRODUCT_BUY_FOR" />
      </>
    );
  };

  toggleBuy = () => {
    this.setState((state) => ({
      showBuyForm: !state.showBuyForm,
    }));
  };

  onSubmit = (startDate: Date, endDate: Date, comment: string, mediaIds: string[], isImmediate: boolean) => {
    const { productId, buyProduct } = this.props;

    buyProduct(productId || '', startDate, endDate, comment, mediaIds, isImmediate);
  };

  renderBuyBtnContent = () => {
    const { product } = this.props;

    if (!product) return null;

    const pattern = i18nTranslator(product.type === 'free' ? 'PRODUCT_MODAL_BUY_FREE' : 'PRODUCT_MODAL_BUY_PRICE');

    const content = contentFromPattern(
      pattern,
      [
        {
          regex: /\[PRICE\]/g,
          build: () => (
            <span className="price-value ml-1" key="price-value">
              {numberFormat.toLocaleCurrencyString(product.currency, product.price)}
            </span>
          ),
        },
      ],
      0
    );

    return content;
  };

  renderBuyForm = () => {
    const { buyComment } = this.state;
    const { product, productId } = this.props;

    if (!product || !productId) {
      return null;
    }

    const titleKey = productTypeSelect(product.type, {
      free: 'PRODUCT_BUY_FORM_TITLE_FREE',
      money: 'PRODUCT_BUY_FORM_TITLE_MONEY',
      good_mood: 'PRODUCT_BUY_FORM_TITLE_GOOD_MOOD',
      insurance: 'PRODUCT_BUY_FORM_TITLE_INSURANCE',
    });
    const descKey = productTypeSelect(product.type, {
      free: 'PRODUCT_BUY_FORM_DESC_FREE',
      money: 'PRODUCT_BUY_FORM_DESC_MONEY',
      good_mood: 'PRODUCT_BUY_FORM_DESC_GOOD_MOOD',
      insurance: 'PRODUCT_BUY_FORM_DESC_INSURANCE',
    });

    return (
      <ProductBuyingForm
        productId={productId}
        maxMedia={product.maxMedia}
        productType={product.type}
        minTime={product.minTime}
        onSubmit={this.onSubmit}
        btnClassName="d-flex product-buy-btn align-items-center justify-content-center btn btn-primary"
        btnContent={this.renderBuyBtnContent()}
        initComment={buyComment}
        titleKey={titleKey}
        descKey={descKey}
        onClose={this.toggleBuy}
        ref={this.productBuyingFormRef}
      />
    );
  };

  saveDraft = async () => {
    const { productId } = this.props;
    const { showBuyForm, buyComment } = this.state;

    if (productId) {
      if (this.shouldSaveDraft) {
        await draftService.set<State>(`ProductDetailModal_${productId}`, {
          showBuyForm,
          buyComment,
        });
      }
    }
  };

  onClose = async () => {
    const { onClose } = this.props;

    onClose();

    await this.saveDraft();

    this.setState({
      showBuyForm: false,
    });
  };

  discardDraft = async () => {
    const { productId } = this.props;

    if (!productId) {
      return;
    }

    this.shouldSaveDraft = false;

    await draftService.remove(`ProductDetailModal_${productId}`);

    if (this.productBuyingFormRef.current) {
      await this.productBuyingFormRef.current.discardDraft();
    } else {
      const productBuyingFormBaseDraf = ProductBuyingForm.BuildDraftKey(productId);

      await draftService.remove(productBuyingFormBaseDraf);
      await draftService.remove(`Quoteditable_${productBuyingFormBaseDraf}`);
      await draftService.remove(`PhotoUploader_${productBuyingFormBaseDraf}`);
    }
  };

  successErrorAlertClose = async () => {
    const { history, location, buyStatus, checkoutDetail } = this.props;

    if (buyStatus === 'error') {
      history.replace('/offers');

      return;
    }

    if (buyStatus === 'success') {
      await this.discardDraft();

      let chainsureId: string | null = null;

      if (checkoutDetail) {
        ({ chainsureId } = checkoutDetail);
        history.push(`/inventories/c/${chainsureId}`);
      } else {
        ({ chainsureId } = queryString.parse(location.search) as {
          chainsureId: string;
        });
        history.replace(`/inventories/c/${chainsureId}`);
      }
    }
  };

  render() {
    const { productId, product, buyStatus, buyImmediateGift } = this.props;
    const { showBuyForm } = this.state;

    const showingDetail = productId != null && product != null && (!buyStatus || buyStatus === 'buying');

    let hudType: HudType | null = null;
    let hudAlertBtnTitle: string | null = null;
    let hudAlertTitle: string | null = null;
    let hudAlertDesc: string | null = null;

    switch (buyStatus) {
      case 'success':
        hudType = 'toast';
        hudAlertDesc =
          product && buyImmediateGift != null
            ? i18nTranslator(
                productTypeSelect(
                  product.type,
                  {
                    free: 'PRODUCT_MODAL_BUY_FREE_SUCCESS',
                    money: 'PRODUCT_MODAL_BUY_MONEY_SUCCESS',
                    money_immediate: 'PRODUCT_MODAL_BUY_MONEY_IMMEDIATE_SUCCESS',
                    good_mood: 'PRODUCT_MODAL_BUY_GOOD_MOOD_SUCCESS',
                    insurance: 'PRODUCT_MODAL_BUY_INSURANCE_SUCCESS',
                  },
                  buyImmediateGift
                )
              )
            : null;
        break;
      case 'error':
        hudType = 'alert';
        hudAlertTitle = i18nTranslator('ALERT_ERROR');
        hudAlertDesc = i18nTranslator('PRODUCT_MODAL_BUY_ERROR');
        hudAlertBtnTitle = i18nTranslator('ALERT_OK');
        break;
      case 'buying':
      case 'verifying':
        hudType = 'loading';
        break;
      default:
        if (productId != null && product == null) {
          hudType = 'loading';
        }
        break;
    }

    return (
      <>
        <Hud
          type={hudType}
          isOpen={hudType != null}
          alertBtnTitle={hudAlertBtnTitle}
          alertTitle={hudAlertTitle}
          alertDesc={hudAlertDesc}
          toastMessage={hudAlertDesc}
          toastTimeout={1000}
          toggle={this.successErrorAlertClose}
        />
        {showingDetail && (
          <Modal isOpen={productId != null} toggle={this.onClose} centered modalClassName="modal-product-detail">
            <ModalClose onClick={this.onClose} />
            {this.renderContent()}
            {showBuyForm ? (
              this.renderBuyForm()
            ) : (
              <ModalBodyWrapper wrapInCard>
                <div className="state-desc">
                  {product &&
                    i18nTranslator(
                      productTypeSelect(product.type, {
                        free: 'STATE_DESC_NEW_FREE',
                        money: 'STATE_DESC_NEW_MONEY',
                        good_mood: 'STATE_DESC_NEW_GOOD_MOOD',
                        insurance: 'STATE_DESC_NEW_INSURANCE',
                      })
                    )}
                </div>

                <Button color="primary" className="w-100" onClick={this.toggleBuy}>
                  {product &&
                    i18nTranslator(
                      productTypeSelect(product.type, {
                        free: 'STATE_NEW_FREE',
                        money: 'STATE_NEW_MONEY',
                        good_mood: 'STATE_NEW_GOOD_MOOD',
                        insurance: 'STATE_NEW_INSURANCE',
                      })
                    )}
                </Button>
              </ModalBodyWrapper>
            )}
          </Modal>
        )}
      </>
    );
  }
}
export default connect(mapStateToProps, {
  getProductDetail: productActions.getProductDetail,
  buyProduct: productActions.buyProduct,
  selectProduct: productActions.selectProduct,
  verifyProductCheckout: productActions.verifyProductCheckout,
})(ProductDetailModal);
