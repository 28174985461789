import { ChainsuresState } from './types';
import { Action } from '../actions/types';
import { Chainsure } from '../types';
import arrayRemove from '../utils/arrayRemove';
import arrayReplaceAt from '../utils/arrayReplaceAt';

const INITIAL_STATE: ChainsuresState = {
  isLoading: false,
  chainsures: null,
  canLoadMore: false,
};

const reducer = (state: ChainsuresState = INITIAL_STATE, action: Action): ChainsuresState => {
  switch (action.type) {
    case 'GIFT_SUCCESS': {
      const { chainsureId } = action.payload;

      return {
        ...INITIAL_STATE,
        justAcceptedChainsureId: chainsureId,
      };
    }

    case 'PRODUCT_CHECKOUT_SUCCESS': {
      return {
        ...INITIAL_STATE,
      };
    }

    case 'CHAINSURES_LOADING': {
      const { silent } = action.payload;
      return {
        ...state,
        isLoading: silent ? state.isLoading : true,
      };
    }
    case 'CHAINSURES_LOADED': {
      const { chainsures, canLoadMore, refresh } = action.payload;

      return {
        ...state,
        isLoading: false,
        canLoadMore,
        chainsures: refresh ? chainsures : [...(state.chainsures || []), ...chainsures],
      };
    }

    case 'CHAINSURE_SELECTED': {
      const { chainsureId } = action.payload;

      // always set chainsure to null here, because we need to get the current status of chainsure

      return {
        ...state,
        chainsureId,
        inActionChainsureId: null,
        action: null,
        chainsure: null,
        chainsureError: null,
        releaseStatus: null,
        releaseError: null,
        damageReportStatus: null,
        damageReportError: null,
        buyStatus: null,
        buyImmediateGift: null,
        checkoutDetail: null,
        giftSendStatus: null,
        giftSendError: null,
        giftSent: null,
        giftCode: null,
        giftComment: null,
        requestStatus: null,
        requestError: null,
        shouldFocusOnHistory: null,
        justAcceptedChainsureId: chainsureId ? state.justAcceptedChainsureId : null,
      };
    }
    case 'CHAINSURE_GIFT_CODE': {
      const { chainsureId, giftCode, giftComment, giftSent } = action.payload;

      if (state.action === 'makegift' && state.inActionChainsureId === chainsureId) {
        let { chainsure, chainsures } = state;

        if (chainsure) {
          chainsure = {
            ...chainsure,
            gift: giftCode || '',
            giftComment,
            giftSent,
          };
        }

        if (chainsures && chainsures.length > 0) {
          const idx = chainsures.findIndex((x) => x.id === chainsureId);

          if (idx !== -1) {
            chainsures = [...chainsures];
            chainsures[idx] = {
              ...chainsures[idx],
              gift: giftCode || "'",
              giftComment,
              giftSent,
            };
          }
        }

        return {
          ...state,
          chainsure,
          chainsures,
          giftCode,
          giftComment,
          giftSent,
        };
      }

      return state;
    }
    case 'CHAINSURE_GIFT_CODE_ERROR': {
      const { chainsureId, error } = action.payload;

      if (state.action === 'makegift' && state.inActionChainsureId === chainsureId) {
        let { chainsures } = state;

        if (error && error.code === 'WRONG_PERMISSION' && chainsures) {
          // Remove WRONG_PERMISSION chainsure from list
          const idx = chainsures.findIndex((x) => x.id === state.inActionChainsureId);

          if (idx !== -1) {
            chainsures = [...chainsures];
            chainsures.splice(idx, 1);
          }
        }

        return {
          ...state,
          chainsures,
          giftCodeError: error,
        };
      }

      return state;
    }

    case 'CHAINSURE_TOGGLE_MAKING_GIFT': {
      const { chainsureId, giftCode, giftComment, giftSent } = action.payload;

      const alreadyOnMakingGift = state.action === 'makegift';

      if (alreadyOnMakingGift) {
        return {
          ...state,
          inActionChainsureId: null,
          action: null,
          giftCode: null,
          giftComment: null,
          giftSent: null,
        };
      }

      return {
        ...state,
        inActionChainsureId: chainsureId || state.chainsureId,
        action: 'makegift',
        giftCode,
        giftComment,
        giftSent,
      };
    }

    case 'CHAINSURE_GIFT_SENDING': {
      return {
        ...state,
        giftSendStatus: 'sending',
      };
    }

    case 'CHAINSURE_GIFT_SENT_SUCCESS': {
      const { inActionChainsureId } = state;
      let { chainsure, chainsures } = state;

      if (chainsure) {
        chainsure = {
          ...chainsure,
          giftSent: true,
        };
      }

      if (chainsures && chainsures.length > 0) {
        const idx = chainsures.findIndex((x) => x.id === inActionChainsureId);

        if (idx !== -1) {
          chainsures = [...chainsures];
          chainsures[idx] = {
            ...chainsures[idx],
            giftSent: true,
          };
        }
      }

      return {
        ...state,
        giftSendStatus: 'success',
        giftSent: true,
        chainsure,
        chainsures,
      };
    }

    case 'CHAINSURE_GIFT_SENT_ERROR': {
      const { error } = action.payload;

      return {
        ...state,
        giftSendStatus: 'error',
        giftSendError: error,
      };
    }

    case 'CHAINSURE_GIFT_SEND_CLEAN': {
      return {
        ...state,
        giftSendStatus: null,
        giftSendError: null,
      };
    }

    case 'CHAINSURE_TOGGLE_RELEASE': {
      const { chainsureId, chainsure } = action.payload;

      const alreadyOnRelease = state.action === 'release';
      const needToRefreshChainsure =
        alreadyOnRelease && state.releaseStatus === 'success' && state.chainsureId && state.chainsure;

      // try to save old chainsure data (from list item/detail modal)
      // for checking type/amount on damage report

      return {
        ...state,
        inActionChainsureId: alreadyOnRelease ? null : chainsureId || state.chainsureId,
        action: alreadyOnRelease ? null : 'release',
        releaseStatus: null,
        releaseError: null,
        chainsure: needToRefreshChainsure ? null : chainsure || state.chainsure,
        chainsureId: needToRefreshChainsure ? null : state.chainsureId,
      };
    }

    case 'CHAINSURE_TOGGLE_DAMAGE': {
      const { chainsureId } = action.payload;

      const alreadyOnDamage = state.action === 'damage';

      return {
        ...state,
        inActionChainsureId: alreadyOnDamage ? null : chainsureId || state.chainsureId,
        action: alreadyOnDamage ? null : 'damage',
        damageReportStatus: null,
        damageReportError: null,
      };
    }

    case 'CHAINSURE_RELEASING': {
      return {
        ...state,
        releaseStatus: 'requesting',
      };
    }

    case 'CHAINSURE_RELEASE_SUCCESS': {
      const { chainsure } = action.payload;

      const chainsures = state.chainsures ? [...state.chainsures] : null;

      if (chainsures) {
        const idx = chainsures.findIndex((x) => x.id === state.inActionChainsureId);

        chainsures[idx] = chainsure;
      }

      return {
        ...state,
        chainsures,
        releaseStatus: 'success',
      };
    }

    case 'CHAINSURE_RELEASE_ERROR': {
      const { error } = action.payload;

      return {
        ...state,
        releaseStatus: 'error',
        releaseError: error,
      };
    }
    case 'DAMAGE_REPORTING': {
      return {
        ...state,
        damageReportStatus: 'requesting',
      };
    }

    case 'DAMAGE_REPORT_SUCCESS': {
      const chainsures = state.chainsures ? [...state.chainsures] : null;

      if (chainsures) {
        const idx = chainsures.findIndex((x) => x.id === state.inActionChainsureId);

        chainsures.splice(idx, 1);
      }

      return {
        ...state,
        chainsures,
        damageReportStatus: 'success',
      };
    }

    case 'DAMAGE_REPORT_ERROR': {
      const { error } = action.payload;

      return {
        ...state,
        damageReportStatus: 'error',
        damageReportError: error,
      };
    }

    case 'CHAINSURE_DETAIL_LOADED': {
      const { chainsure } = action.payload;

      let newChainsures: Chainsure[] | null = null;

      if (state.chainsures != null && !state.isLoading) {
        const idx = state.chainsures.findIndex((c) => c.id === chainsure.id);

        if (idx !== -1) {
          newChainsures = [...state.chainsures];
          newChainsures[idx] = chainsure;
        }
      }

      if (state.chainsureId === chainsure.id || newChainsures != null) {
        const newState = {
          ...state,
        };

        if (state.chainsureId === chainsure.id) {
          newState.chainsure = chainsure;
          newState.giftCode = chainsure.gift;
          newState.giftComment = chainsure.giftComment;
        }

        if (newChainsures != null) {
          newState.chainsures = newChainsures;
        }

        if (state.justAcceptedChainsureId && state.justAcceptedChainsureId === chainsure.id) {
          newState.justAcceptedChainsureId = null;
          newState.shouldFocusOnHistory = chainsure.histories && chainsure.histories.length > 0 ? 1 : null;
        }

        return newState;
      }

      return state;
    }

    case 'CHAINSURE_DETAIL_ERROR': {
      const { error, chainsureId } = action.payload;

      if (state.chainsureId === chainsureId) {
        let { chainsures } = state;

        if (state.chainsures && error && error.code === 'WRONG_PERMISSION' && chainsures) {
          // Remove WRONG_PERMISSION chainsure from list
          const idx = state.chainsures.findIndex((x) => x.id === state.chainsureId);

          if (idx !== -1) {
            chainsures = [...state.chainsures];
            chainsures.splice(idx, 1);
          }
        }

        return {
          ...state,
          chainsureError: error,
          chainsures,
        };
      }

      return state;
    }

    case 'OWNED_CHAINSURE_DETAIL_ERROR': {
      const { error } = action.payload;

      if (error && error.code === 'WRONG_PERMISSION') {
        // WRONG_PERMISSION on owned list => should refresh

        return {
          ...INITIAL_STATE,
        };
      }

      return state;
    }

    case 'CHAINSURE_BUY': {
      const { chainsureId } = action.payload;

      if (state.chainsureId === chainsureId) {
        return {
          ...state,
          buyStatus: 'buying',
          checkoutDetail: null,
        };
      }

      return state;
    }

    case 'CHAINSURE_CHECKOUT': {
      const { chainsureId, checkoutDetail } = action.payload;

      if (state.chainsureId === chainsureId) {
        return {
          ...state,
          buyStatus: 'buying',
          checkoutDetail,
        };
      }

      return state;
    }

    case 'CHAINSURE_CHECKOUT_VERIFYING': {
      const { chainsureId } = action.payload;

      if (state.chainsureId === chainsureId) {
        return {
          ...state,
          buyStatus: 'verifying',
        };
      }

      return state;
    }

    case 'CHAINSURE_CHECKOUT_SUCCESS': {
      const {
        chainsureId,
        payment: { isImmediate },
      } = action.payload;

      if (state.chainsureId === chainsureId) {
        return {
          ...state,
          buyStatus: 'success',
          buyImmediateGift: isImmediate,
        };
      }

      return state;
    }

    case 'CHAINSURE_CHECKOUT_ERROR': {
      const { chainsureId } = action.payload;

      if (state.chainsureId === chainsureId) {
        return {
          ...state,
          buyStatus: 'error',
        };
      }

      return state;
    }

    case 'OWNED_CHAINSURE_REQUEST_CLEAN_ERROR': {
      return {
        ...state,
        requestStatus: null,
        requestError: null,
      };
    }

    case 'OWNED_CHAINSURE_REQUEST_ACCEPT': {
      return {
        ...state,
        requestStatus: 'accepting',
      };
    }

    case 'OWNED_CHAINSURE_REQUEST_ACCEPT_SUCCESS': {
      const chainsures = arrayRemove(state.chainsures, (x) => x.id === state.chainsureId);

      return {
        ...state,
        chainsures: chainsures && chainsures.length > 0 ? chainsures : null,
        requestStatus: 'success',
      };
    }

    case 'OWNED_CHAINSURE_REQUEST_ACCEPT_ERROR': {
      const { error } = action.payload;
      return {
        ...state,
        requestError: error,
        requestStatus: 'error',
      };
    }

    case 'OWNED_CHAINSURE_REQUEST_DENY': {
      const { chainsureId, requestUid } = action.payload;

      if (chainsureId !== state.chainsureId || state.chainsure == null || state.chainsure.requestsDetails == null) {
        return state;
      }

      return {
        ...state,
        chainsure: {
          ...state.chainsure,
          requestsDetails: arrayReplaceAt(
            state.chainsure.requestsDetails,
            (x) => x.requestUid === requestUid,
            (x) => ({
              ...x,
              isRemoving: true,
              error: null,
            })
          ),
        },
      };
    }

    case 'OWNED_CHAINSURE_REQUEST_DENIED': {
      const { chainsureId, requestUid } = action.payload;

      if (chainsureId !== state.chainsureId || state.chainsure == null || state.chainsure.requestsDetails == null) {
        return state;
      }

      const chainsure: Chainsure = {
        ...state.chainsure,
        requestsDetails: arrayRemove(state.chainsure.requestsDetails, (a) => a.requestUid === requestUid) || undefined,
        requestedBy: arrayRemove(state.chainsure.requestedBy, (a) => a === requestUid) || undefined,
      };

      const chainsures = state.chainsures
        ? arrayReplaceAt(
            state.chainsures,
            (x) => x.id === chainsureId,
            () => ({
              ...chainsure,
            })
          )
        : state.chainsures;

      return {
        ...state,
        chainsure,
        chainsures,
      };
    }

    case 'OWNED_CHAINSURE_REQUEST_DENIED_ERROR': {
      const { chainsureId, requestUid, error } = action.payload;

      if (chainsureId !== state.chainsureId || state.chainsure == null || state.chainsure.requestsDetails == null) {
        return state;
      }

      return {
        ...state,
        chainsure: {
          ...state.chainsure,
          requestsDetails: arrayReplaceAt(
            state.chainsure.requestsDetails,
            (x) => x.requestUid === requestUid,
            (x) => ({
              ...x,
              isRemoving: false,
              error,
            })
          ),
        },
      };
    }

    case 'AUTH_STATE_CHANGED': {
      const { user } = action.payload;

      if (user) return state;

      return {
        ...INITIAL_STATE,
      };
    }

    default:
      return state;
  }
};

export default reducer;
