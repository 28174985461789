import React, { PureComponent } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import giftActions from '../../actions/gift.actions';
import Hud, { HudType } from '../../components/Hud/Hud';
import FontAwesomeIcon from '../../components/Icon/FontAwesomeIcon';
import ProductInfoBasic from '../../components/ProductInfo/Basic/ProductInfoBasic';
import ProductInfoPrice from '../../components/ProductInfo/Price/ProductInfoPrice';
import Quoteditable from '../../components/Quoteditable/Quoteditable';
import UserPhoto from '../../components/UserPhoto/UserPhoto';
import { ReduxState } from '../../reducers/types';
import './Gift.scss';

const mapStateToProps = ({ auth, gift }: ReduxState) => ({
  ...gift,
  isAuth: auth.isAuth,
  uid: auth.uid,
});

type Props = ReturnType<typeof mapStateToProps> & {
  getGift: typeof giftActions.getGift;
  acceptGift: typeof giftActions.acceptGift;
} & RouteComponentProps<{ chainsureId: string; giftCode: string }> &
  WithNamespaces;

class Gift extends PureComponent<Props> {
  componentDidMount() {
    const { match, getGift, chainsure, history, acceptGift } = this.props;
    const { chainsureId, giftCode } = match.params;

    if (!chainsure) {
      if (match.path.endsWith('/accept')) {
        history.replace(`/gift/c/${chainsureId}/g/${giftCode}`);
      }

      getGift(chainsureId, giftCode);
    } else if (match.path.endsWith('/accept')) {
      acceptGift(chainsureId, giftCode);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { lng, getGift, match } = this.props;
    const { chainsureId, giftCode } = match.params;

    if (lng !== prevProps.lng) {
      getGift(chainsureId, giftCode);
    }
  }

  onHudClose = () => {
    const { history, isSuccess, match } = this.props;

    if (isSuccess) {
      history.replace(`/inventories/c/${match.params.chainsureId}`);
    } else {
      history.replace('/');
    }
  };

  onAcceptGift = () => {
    const { isAuth, acceptGift, match, history, uid, chainsure } = this.props;
    const { chainsureId, giftCode } = match.params;

    if (isAuth && uid && chainsure && chainsureId && giftCode) {
      if (uid !== chainsure.uid) {
        acceptGift(chainsureId, giftCode);
      }
    } else {
      history.push(`/gift/c/${chainsureId}/g/${giftCode}/accept`);
    }
  };

  renderTop = () => {
    const { chainsure, t } = this.props;

    if (!chainsure) return null;

    const { user } = chainsure;

    return (
      <div className="w-100 justify-content-center align-items-center mb-2 d-flex flex-column">
        <div className="mb-3 mt-1">
          <FontAwesomeIcon icon="gift" size="3x" />
        </div>
        <div className="gift-sender-photo mb-3">
          <UserPhoto photoURL={user.photoURL} />
        </div>
        <div className="gift-sender-name mb-1">{user.displayName}</div>
        <div className="gift-msg mb-3">{t('GIFT_DESCRIPTION')}</div>
        {chainsure.giftComment && (
          <Quoteditable content={chainsure.giftComment} disabled className="w-100 gift-comment" />
        )}
      </div>
    );
  };

  renderProduct = () => {
    const { chainsure } = this.props;

    if (!chainsure) return null;

    const { product, amount, currency, startDate, endDate, isImmediate } = chainsure;

    return (
      <div className="w-100 gift-product-info modal-product-detail">
        <ProductInfoBasic
          {...{
            ...product,
            price: amount,
          }}
        />
        {product.type !== 'free' && (
          <ProductInfoPrice
            price={amount}
            currency={currency}
            titleKey="CHAINSURE_USE_ON"
            isImmediate={isImmediate}
            dateRange={{
              start: startDate,
              end: endDate,
            }}
          />
        )}
      </div>
    );
  };

  renderBottom = () => {
    const { chainsure, isAuth, t, uid } = this.props;

    if (!chainsure || uid === chainsure.uid) return null;

    const btnText = isAuth ? t('GIFT_ACCEPT') : t('GIFT_ACCEPT_LOGIN');

    return (
      <div className="w-100 d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between bottom">
        <Button type="button" color="primary" className="w-100" onClick={this.onAcceptGift}>
          {btnText}
        </Button>
      </div>
    );
  };

  render() {
    const { chainsure, error, isRequesting, isSuccess, t } = this.props;

    const isLoading = (chainsure == null && error == null) || isRequesting;
    const hubIsOpen = isRequesting || error != null || isSuccess || chainsure == null;

    let alertTitle: string | null = null;
    let alertDesc: string | null = null;
    let hudType: HudType | null = null;

    if (isLoading) {
      hudType = 'loading';
    } else if (isSuccess) {
      hudType = 'toast';
      alertDesc = t('GIFT_ACCEPT_SUCCESS');
    } else if (error) {
      hudType = 'alert';
      alertTitle = t('ALERT_ERROR');
      alertDesc = t('GIFT_ACCEPT_ERROR');
    }

    return (
      <>
        <Hud
          isOpen={hubIsOpen}
          type={hudType}
          alertBtnTitle={t('ALERT_OK')}
          alertTitle={alertTitle}
          alertDesc={alertDesc}
          toastMessage={alertDesc}
          toastTimeout={1000}
          toggle={this.onHudClose}
        />
        {chainsure != null && (
          <Container tag="main" role="main">
            <Row>
              <Col
                xs={12}
                sm={{
                  size: 8,
                  offset: 2,
                }}
                lg={{
                  size: 6,
                  offset: 3,
                }}
              >
                <div className="gift">
                  {this.renderTop()}
                  {this.renderBottom()}
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </>
    );
  }
}

export default withNamespaces()(
  connect(mapStateToProps, {
    getGift: giftActions.getGift,
    acceptGift: giftActions.acceptGift,
  })(Gift)
);
