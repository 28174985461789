import { DamagesState } from './types';
import { Action } from '../actions/types';

const INITIAL_STATE: DamagesState = {
  isLoading: false,
  canLoadMore: false,
};

const reducer = (state: DamagesState = INITIAL_STATE, action: Action): DamagesState => {
  switch (action.type) {
    case 'DAMAGES_LOADING': {
      const { silent } = action.payload;
      return {
        ...state,
        isLoading: silent ? state.isLoading : true,
      };
    }
    case 'DAMAGES_LOADED': {
      const { damages, canLoadMore, refresh } = action.payload;

      return {
        ...state,
        isLoading: false,
        damages: refresh ? damages : [...(state.damages || []), ...damages],
        canLoadMore,
      };
    }
    case 'DAMAGE_SELECTED': {
      const { damageId } = action.payload;

      return {
        ...state,
        damageId,
        damage: null,
      };
    }

    case 'DAMAGE_DETAIL_LOADED': {
      const { damage } = action.payload;

      if (state.damageId === damage.id) {
        return {
          ...state,
          damage,
        };
      }

      return state;
    }

    case 'DAMAGE_REPORT_SUCCESS': {
      return {
        ...INITIAL_STATE,
      };
    }

    case 'AUTH_STATE_CHANGED': {
      const { user } = action.payload;

      if (user) return state;

      return {
        ...INITIAL_STATE,
      };
    }

    default:
      return state;
  }
};

export default reducer;
