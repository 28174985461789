import React, { PureComponent } from 'react';
import { Product } from '../../../../types';
import datetimeFormat from '../../../../utils/datetime/datetimeFormat';
import ProductInfoListItem from '../../../../components/ProductInfo/ListItem/ProductInfoListItem';
import productTypeSelect from '../../../../utils/productTypeSelect';
import { i18nTranslator } from '../../../../utils/i18n';

type Props = {
  product: Product;
};

export default class ProductListItem extends PureComponent<Props> {
  render() {
    const { product } = this.props;

    const stateKey = productTypeSelect(product.type, {
      free: 'STATE_NEW_FREE',
      money: 'STATE_NEW_MONEY',
      good_mood: 'STATE_NEW_GOOD_MOOD',
      insurance: 'STATE_NEW_INSURANCE',
    });

    return (
      <ProductInfoListItem
        productOnly
        linkTo={`/offers/p/${product.id}`}
        {...product}
        renderTime={() => (
          <>
            <div className="font-weight-bold">{i18nTranslator('PRODUCT_DATE_AVAILABLE')}</div>
            <div>{datetimeFormat.parseToLocaleString(product.dateAvailable, 'date-trim-year')}</div>
          </>
        )}
        timeAndPriceContainerClassName="mt-sm-3"
        stateBottomKey={stateKey}
      />
    );
  }
}
