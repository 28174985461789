

const configs: {
  [id: string]: {
    date: string,
    datePattern: string[],
    dateTrimYearPattern: string[],
    time: string,
    timePattern: string[],
    full: string,
    fullPattern: string[],
    fullTrimYearPattern: string[],
    fullRegex: RegExp,
    fullRegexMap: ('month' | 'date' | 'year' | 'hour' | 'minute')[],
    maskInput: (string | RegExp)[],
    maskPlaceholder: string,
    months: string[],
    weekdays: string[],
    timeLabels: {
      hour: string,
      min: string
    }
  }
} = {
  en: {
    date: 'MM/dd/yyyy',
    datePattern: ['MM', '/', 'dd', '/', 'yyyy'],
    dateTrimYearPattern: ['MM', '/', 'dd'],
    time: 'HH:mm',
    timePattern: ['HH', ':', 'mm'],
    full: 'MM/dd/yyyy HH:mm',
    fullPattern: ['MM', '/', 'dd', '/', 'yyyy', ' ', 'HH', ':', 'mm'],
    fullTrimYearPattern: ['MM', '/', 'dd', ' ', 'HH', ':', 'mm'],
    fullRegex: /^(\d\d)\/(\d\d)\/(\d\d\d\d) (\d\d):(\d\d)$/,
    fullRegexMap: ['month', 'date', 'year', 'hour', 'minute'],
    maskPlaceholder: 'MM/dd/yyyy HH:mm',
    maskInput: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/],
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    timeLabels: {
      hour: 'Hour',
      min: 'Minute'
    }
  },
  de: {
    date: 'dd.MM.yyyy',
    datePattern: ['dd', '.', 'MM', '.', 'yyyy'],
    dateTrimYearPattern: ['dd', '.', 'MM'],
    time: 'HH:mm',
    timePattern: ['HH', ':', 'mm'],
    full: 'dd.MM.yyyy HH:mm',
    fullPattern: ['dd', '.', 'MM', '.', 'yyyy', ' ', 'HH', ':', 'mm'],
    fullTrimYearPattern: ['dd', '.', 'MM', ' ', 'HH', ':', 'mm'],
    fullRegex: /^(\d\d)\.(\d\d)\.(\d\d\d\d) (\d\d):(\d\d)$/,
    fullRegexMap: ['date', 'month', 'year', 'hour', 'minute'],
    maskPlaceholder: 'dd.MM.yyyy HH:mm',
    maskInput: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/],
    months: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember'
    ],
    weekdays: ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.'],
    timeLabels: {
      hour: 'Stunde',
      min: 'Minute'
    }
  }
}

export default configs
