import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LngDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next/dist/es/context';

function loadLocales(url: string, _, callback) {
  import(`../assets/locales/${url}.json`)
    .then((locale) => {
      callback(locale, {
        status: '200',
      });
    })
    .catch((error) => {
      console.log(error);
      callback(null, {
        status: '404',
      });
    });
}

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LngDetector)
  // pass the i18n instance to react-i18next.
  .use(reactI18nextModule)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      loadPath: '{{ns}}/{{lng}}',
      parse: (data) => data,
      ajax: loadLocales,
    },
    detection: {
      lookupQuerystring: 'lang',
    },
    whitelist: ['en', 'de'],
    load: 'languageOnly',
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // special options for react-i18next
    // learn more: https://react.i18next.com/components/i18next-instance
    react: {
      wait: true,
    },
  });

export const i18nTranslator = (key: string | void | null, options?: { [id: string]: string | number }): string =>
  (key && i18n.t(key, options)) || '';
export const currLanguage = () => i18n.language.substr(0, 2);

export default i18n;
