import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, ModalBody } from 'reactstrap';
import chainsureAction from '../../../../actions/chainsure.action';
import Hud, { HudType } from '../../../../components/Hud/Hud';
import ProductInfoActionContainer from '../../../../components/ProductInfo/ActionContainer/ProductInfoActionContainer';
import Quoteditable from '../../../../components/Quoteditable/Quoteditable';
import datetimeFormat from '../../../../utils/datetime/datetimeFormat';
import { i18nTranslator } from '../../../../utils/i18n';
import productTypeSelect from '../../../../utils/productTypeSelect';
import { ReduxState } from '../../../../reducers/types';

const mapStateToProps = ({ chainsures: { chainsureId, releaseStatus, action, chainsure } }: ReduxState) => ({
  chainsureId,
  releaseStatus,
  action,
  chainsure,
});

type Props = {
  releaseChainsure: typeof chainsureAction.releaseChainsure;
  toggleRelease: typeof chainsureAction.toggleRelease;
} & ReturnType<typeof mapStateToProps>;

type State = {
  comment: string;
};

class ChainsureRelease extends PureComponent<Props, State> {
  state: State = {
    comment: '',
  };

  quoteditableRef: Quoteditable | null = null;

  componentDidMount() {
    this.getReleaseNote();
  }

  componentDidUpdate(prevProps: Props) {
    const { chainsure } = this.props;

    if (chainsure && prevProps.chainsure !== chainsure) {
      setImmediate(() => {
        this.setState(
          {
            comment: '',
          },
          () => {
            this.getReleaseNote();
          }
        );
      });
    }
  }

  getReleaseNote = () => {
    const { comment } = this.state;
    const { chainsureId, chainsure } = this.props;

    if (comment || !chainsureId || !chainsure) return;

    const userState = chainsureAction.getUseStatus(chainsure);

    if (
      (userState === 'used' && chainsure.status !== 'released') ||
      (userState === 'using' && datetimeFormat.timestampToDate(chainsure.startDate) < new Date())
    ) {
      chainsureAction
        .getReleaseNote(chainsureId)
        .then((releaseNote) => {
          this.setState({
            comment: releaseNote,
          });
        })
        .catch(() => {});
    }
  };

  onReleaseClick = () => {
    const { chainsureId, releaseChainsure } = this.props;

    if (!chainsureId) {
      return;
    }

    const comment = this.quoteditableRef?.getEditingContent() || '';

    releaseChainsure(chainsureId, comment);
  };

  onClose = () => {
    const { toggleRelease } = this.props;

    toggleRelease();
  };

  hudOnClose = async () => {
    const { toggleRelease, releaseStatus } = this.props;

    if (releaseStatus === 'success') {
      if (this.quoteditableRef) {
        await this.quoteditableRef.discardDraft();
      }
    }

    toggleRelease();
  };

  render() {
    const { releaseStatus, action, chainsure, chainsureId } = this.props;
    const { comment } = this.state;

    if (action !== 'release' || !chainsure) return null;

    const {
      product: { type },
      isImmediate,
    } = chainsure;

    const title = i18nTranslator(
      productTypeSelect(
        type,
        {
          free: 'CHAINSURE_RELEASE_TITLE_FREE',
          money: 'CHAINSURE_RELEASE_TITLE_MONEY',
          money_immediate: 'CHAINSURE_RELEASE_TITLE_MONEY_IMMEDIATE',
          good_mood: 'CHAINSURE_RELEASE_TITLE_GOOD_MOOD',
          insurance: 'CHAINSURE_RELEASE_TITLE_INSURANCE',
        },
        isImmediate
      )
    );
    const description = i18nTranslator(
      productTypeSelect(
        type,
        {
          free: 'CHAINSURE_RELEASE_DESC_FREE',
          money: 'CHAINSURE_RELEASE_DESC_MONEY',
          money_immediate: 'CHAINSURE_RELEASE_DESC_MONEY_IMMEDIATE',
          good_mood: 'CHAINSURE_RELEASE_DESC_GOOD_MOOD',
          insurance: 'CHAINSURE_RELEASE_DESC_INSURANCE',
        },
        isImmediate
      )
    );

    let hudType: HudType | null = null;
    let alertDesc: string | null = null;
    let alertTitle: string | null = null;
    if (releaseStatus) {
      hudType = releaseStatus === 'requesting' ? 'loading' : 'alert';

      switch (releaseStatus) {
        case 'success':
          hudType = 'toast';
          alertDesc = i18nTranslator(
            productTypeSelect(
              type,
              {
                free: 'CHAINSURE_RELEASE_SUCCESS_FREE',
                money: 'CHAINSURE_RELEASE_SUCCESS_MONEY',
                money_immediate: 'CHAINSURE_RELEASE_SUCCESS_MONEY_IMMEDIATE',
                good_mood: 'CHAINSURE_RELEASE_SUCCESS_GOOD_MOOD',
                insurance: 'CHAINSURE_RELEASE_SUCCESS_INSURANCE',
              },
              isImmediate
            )
          );
          break;
        case 'error':
          alertTitle = i18nTranslator('ALERT_ERROR');
          alertDesc = i18nTranslator('CHAINSURE_RELEASE_ERROR');
          break;
        default:
          break;
      }
    }

    return (
      <ModalBody>
        <Hud
          type={hudType}
          isOpen={hudType != null}
          alertBtnTitle={i18nTranslator('ALERT_OK')}
          alertDesc={alertDesc}
          alertTitle={alertTitle}
          toastMessage={alertDesc}
          toastTimeout={1500}
          toggle={this.hudOnClose}
        />
        <ProductInfoActionContainer icon="clock" title={title} description={description} onClose={this.onClose}>
          <Quoteditable
            draftKey={`ChainsureRelease_cId_${chainsureId}`}
            content={comment}
            label={i18nTranslator('CHAINSURE_RELEASE_COMMENT')}
            onChange={(text) => {
              this.setState({
                comment: text,
              });
            }}
            ref={(ref) => {
              this.quoteditableRef = ref;
            }}
          />

          <div className="w-100 d-flex flex-column flex-sm-row justify-content-between">
            <div className="mr-0 mb-2 mb-sm-0  w-100">
              <Button type="button" color="primary" className="w-100" onClick={this.onReleaseClick}>
                {i18nTranslator('CHAINSURE_RELEASE_GOOD')}
              </Button>
            </div>
          </div>
        </ProductInfoActionContainer>
      </ModalBody>
    );
  }
}
export default connect(mapStateToProps, {
  releaseChainsure: chainsureAction.releaseChainsure,
  toggleRelease: chainsureAction.toggleRelease,
})(ChainsureRelease);
