import React from 'react';
import photoholder from '../../assets/photo-bolder.png';

const Photo = (props: any) => {
  const { photoURL, photoClassName, orgPhotoClassName, style, ...rest } = props;

  const propStyle = {
    ...style,
    position: 'relative',
  };

  return (
    <div {...rest} style={propStyle}>
      <img src={photoholder} alt="" width="100%" className={photoClassName} />
      <img
        src={photoURL}
        alt=""
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        }}
        className={`${photoClassName} ${orgPhotoClassName}`}
      />
    </div>
  );
};

export default Photo;
