import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { ReduxState } from '../../reducers/types';

const AnonymousRoute = ({ component: Component, isAuth, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuth ? <Redirect to="/dashboard" /> : <Component {...props} />
    }
  />
);

const mapStateToProps = (state: ReduxState) => ({
  isAuth: state.auth.isAuth,
});

export default connect(mapStateToProps)(AnonymousRoute);
