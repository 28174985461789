import { DashboardState } from './types';
import { Action } from '../actions/types';
import arrayRemove from '../utils/arrayRemove';
import arrayReplaceAt from '../utils/arrayReplaceAt';
import { Chainsure } from '../types';

const INITIAL_STATE: DashboardState = {
  stats: null,
  isLoadingProducts: false,
  productMode: 'top',
  topProducts: null,
  latestProducts: null,
  sentToChainsures: null,
  pendingRequestChainsures: null,
  usedChainsures: null,
  isLoadingChainsures: false,
  shouldRefreshChainsures: false,
};

const removeChainsureFromUsedChainsures = (state: DashboardState, chainsureId: string | undefined): DashboardState => {
  const { usedChainsures, isLoadingChainsures } = state;

  if (usedChainsures == null || usedChainsures.length === 0 || isLoadingChainsures) {
    return state;
  }

  const idx = usedChainsures.findIndex((x) => x.id === chainsureId);

  if (idx === -1) {
    return state;
  }

  let newUsedChainsures: Chainsure[] | null = [...usedChainsures];
  newUsedChainsures.splice(idx, 1);

  if (newUsedChainsures.length === 0) {
    newUsedChainsures = null;
  }

  return {
    ...state,
    usedChainsures: newUsedChainsures,
    shouldRefreshChainsures: true,
  };
};

const reducer = (state: DashboardState = INITIAL_STATE, action: Action): DashboardState => {
  switch (action.type) {
    case 'DASHBOARD_STATS_LOADED': {
      const { stats } = action.payload;

      return {
        ...state,
        stats,
      };
    }

    case 'DASHBOARD_PRODUCTS_SWITCH': {
      return {
        ...state,
        productMode: state.productMode === 'top' ? 'latest' : 'top',
      };
    }

    case 'DASHBOARD_PRODUCTS_LOADING': {
      const { notSwitch } = action.payload;

      if (notSwitch) {
        return state;
      }

      return {
        ...state,
        isLoadingProducts: true,
      };
    }

    case 'DASHBOARD_PRODUCTS_LOADED': {
      const { products, mode, notSwitch } = action.payload;

      if (notSwitch) {
        return {
          ...state,
          topProducts: mode === 'top' ? products : state.topProducts,
          latestProducts: mode === 'top' ? products : state.latestProducts,
        };
      }

      if (mode !== state.productMode) return state;

      if (mode === 'top') {
        return {
          ...state,
          isLoadingProducts: false,
          topProducts: products,
        };
      }

      return {
        ...state,
        isLoadingProducts: false,
        latestProducts: products,
      };
    }

    case 'DASHBOARD_CHAINSURES_LOADING': {
      return {
        ...state,
        isLoadingChainsures: true,
      };
    }

    case 'DASHBOARD_PENDING_REQUEST_CHAINSURES_LOADED': {
      const { chainsures } = action.payload;

      return {
        ...state,
        pendingRequestChainsures: chainsures,
      };
    }

    case 'DASHBOARD_SENT_TO_CHAINSURES_LOADED': {
      const { chainsures } = action.payload;

      return {
        ...state,
        sentToChainsures: chainsures,
      };
    }

    case 'DASHBOARD_CHAINSURES_LOADED': {
      const { chainsures } = action.payload;

      return {
        ...state,
        usedChainsures: chainsures,
        isLoadingChainsures: false,
        shouldRefreshChainsures: false,
      };
    }

    case 'DAMAGE_REPORT_SUCCESS': {
      const { chainsureId } = action.payload;

      return removeChainsureFromUsedChainsures(state, chainsureId);
    }

    case 'CHAINSURE_RELEASE_SUCCESS': {
      const { chainsure } = action.payload;

      return removeChainsureFromUsedChainsures(state, chainsure.id);
    }

    case 'GIFT_ERROR':
    case 'GIFT_SUCCESS': {
      const { chainsureId } = action.payload;

      if (!state.sentToChainsures) {
        return state;
      }

      const chainsure = state.sentToChainsures.find((x) => x.id === chainsureId);

      if (!chainsure) {
        return state;
      }

      const sentToChainsures = arrayRemove(state.sentToChainsures, (x) => x.id === chainsureId);

      if (!sentToChainsures || sentToChainsures.length === 0) {
        return {
          ...state,
          sentToChainsures: null,
        };
      }

      return {
        ...state,
        sentToChainsures,
      };
    }

    case 'OWNED_CHAINSURE_REQUEST_ACCEPT_ERROR':
    case 'OWNED_CHAINSURE_REQUEST_ACCEPT_SUCCESS': {
      const { chainsureId } = action.payload;

      if (!state.pendingRequestChainsures) {
        return state;
      }

      const chainsure = state.pendingRequestChainsures.find((x) => x.id === chainsureId);

      if (!chainsure || !chainsure.requestsDetails) {
        return state;
      }

      const pendingRequestChainsures = arrayRemove(state.pendingRequestChainsures, (x) => x.id === chainsureId);

      if (pendingRequestChainsures && pendingRequestChainsures.length === 0) {
        return {
          ...state,
          pendingRequestChainsures: null,
        };
      }

      return {
        ...state,
        pendingRequestChainsures,
      };
    }

    case 'OWNED_CHAINSURE_REQUEST_DENIED': {
      const { chainsureId, requestUid } = action.payload;

      if (!state.pendingRequestChainsures) {
        return state;
      }

      let chainsure = state.pendingRequestChainsures.find((x) => x.id === chainsureId);

      if (!chainsure || !chainsure.requestsDetails) {
        return state;
      }

      chainsure = {
        ...chainsure,
        requestsDetails: arrayRemove(chainsure.requestsDetails, (a) => a.requestUid === requestUid),
        requestedBy: arrayRemove(chainsure.requestedBy, (a) => a === requestUid),
      };

      if (!!chainsure.requestedBy && chainsure.requestedBy.length === 0) {
        const pendingRequestChainsures = arrayRemove(state.pendingRequestChainsures, (x) => x.id === chainsureId);

        if (!pendingRequestChainsures || pendingRequestChainsures.length === 0) {
          return {
            ...state,
            pendingRequestChainsures: null,
          };
        }

        return {
          ...state,
          pendingRequestChainsures,
        };
      }

      return {
        ...state,
        pendingRequestChainsures: arrayReplaceAt(
          state.pendingRequestChainsures,
          (x) => x.id === chainsureId,
          chainsure
        ),
      };
    }

    case 'AUTH_STATE_CHANGED': {
      const { user } = action.payload;

      if (user) return state;

      return {
        ...INITIAL_STATE,
      };
    }

    default:
      return state;
  }
};

export default reducer;
